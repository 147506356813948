.order {
	padding: 0;
	border: none;
	background-color: transparent;

	svg {
		cursor: pointer;
	}

	&__ascending {
		transform: rotate(180deg);
	}
}
