.roundedButton {
	display: inline-block;
	padding: 8px 24px;
	border-radius: 100px;
	background-color: #fff;
	border: 1px solid #cbc5c2;
	color: #404040;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	transition: all 0.2s linear;

	&:hover {
		background-color: #0c5850;
		color: #fff;
	}
}
