.container {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.dropdownButton {
		height: 36px;
	}

	.dropdownMenu {
		margin-left: -60px;
	}

	.bulkActionsButton {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 6px;
		border-radius: 4px;
		height: 32px;

		color: var(--Brand-Neutral-Grey-600-B, #4e5555);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */

		.title {
			display: flex;
			gap: 6px;
		}
	}
}
