.container {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding: 8px;
	gap: 4px;
	border: 1px solid #cbc5c2;
	border-radius: 4px;
	width: 166px;
	white-space: nowrap;
}

.title {
	color: #5a5a5a;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	user-select: none;
}

.count {
	display: flex;
	align-items: center;
	color: #383838;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
}
