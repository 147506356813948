.container {
	box-sizing: border-box;
	padding: 10px;
	position: relative;
}

.tooltip {
	background: #ffffff;
	border-radius: 4px;
	box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
	box-sizing: border-box;
	font-size: 14px;
	line-height: 22px;
	padding: 16px;
}

.arrow {
	--arrow-width: 8px;

	background: transparent;
	border-style: solid;
	height: 0;
	position: absolute;
	width: 0;

	&.left {
		right: calc(-1 * var(--arrow-width));
		left: auto !important;

		border-width: var(--arrow-width) 0 var(--arrow-width) var(--arrow-width);
		border-color: transparent transparent transparent #ffffff;
	}

	&.right {
		left: calc(-1 * var(--arrow-width)) !important;

		border-width: var(--arrow-width) var(--arrow-width) var(--arrow-width) 0;
		border-color: transparent #ffffff transparent transparent;
	}

	&.bottom {
		top: calc(-1 * var(--arrow-width)) !important;

		border-width: 0 var(--arrow-width) var(--arrow-width) var(--arrow-width);
		border-color: transparent transparent #ffffff transparent;
	}

	&.bottom-end {
		top: calc(-1 * var(--arrow-width)) !important;
		right: 0 !important;
		left: auto !important;

		border-width: 0 var(--arrow-width) var(--arrow-width) var(--arrow-width);
		border-color: transparent transparent #ffffff transparent;
	}

	&.top {
		bottom: calc(-1 * var(--arrow-width));
		top: auto !important;

		border-width: var(--arrow-width) var(--arrow-width) 0 var(--arrow-width);
		border-color: #ffffff transparent transparent transparent;
	}
}
