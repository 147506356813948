@import "styles/common.module.scss";

$breakPoint: 1240px;
$rightPanelBreakPoint: 920px;

.wrapper {
	width: 100%;
	margin-top: 30px;
}

.container {
	margin: 20px 0 0 0;
	display: flex;

	.leftPanel {
		flex: 1;
		flex-shrink: 0;
		display: flex;
		align-items: flex-start;
		justify-content: center;
	}

	.rightPanel {
		background: #ffffff;
		border-radius: 5px;
		width: 100%;
		margin-left: 8%;
		width: 386px;
		padding: 32px 30px;
		height: 100%;
		position: sticky;
		top: 90px;

		@media (max-width: #{$breakPoint}) {
			margin-left: 0;
			margin-bottom: 32px;
			display: flex;
			width: 100%;
			align-items: flex-start;
			justify-content: space-between;
			position: relative;
			top: 0;
		}

		@media (max-width: #{$rightPanelBreakPoint}) {
			display: block;
		}
	}

	@media (max-width: #{$breakPoint}) {
		flex-direction: column-reverse;
	}
}

.row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-right: calc(386px + 8%);
	flex: 1;

	@media (max-width: #{$breakPoint}) {
		margin-right: 0;
	}
}

.tabs {
	background-color: #fff;
	display: inline-block;
	border-radius: 5px;
	user-select: none;
}

.tabs > a {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 140px;
	height: 42px;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	border-radius: 5px;
}

.tabs > a:global(.active) {
	background-color: #e6efe9;
}

.button {
	display: inline-flex;
	background: #0c5850;
	user-select: none;
	border-radius: 5px !important;

	&.disabled {
		background: #b2c8cb;
	}

	&.createOrganisationButton {
		border: 1px solid #000000 !important;
		color: #000000 !important;
		background: #ffffff !important;
	}
}

.cancelButton {
	background: transparent;
	color: #c91515;
	border-color: transparent;

	&:hover {
		color: #fff;
		background: #c91515;
	}
}

.inviteButton {
	margin-top: 20px;
}

.createButton {
	margin-top: 20px;
	padding: 0 10px;
	margin-right: 16px;
}

.title {
	color: #000;
	margin: 0 0 34px 0;
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 27px;
	color: #000000;
	user-select: none;
}

.panelTitle {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 22px;
}

.description {
	font-size: 14px;
	line-height: 20px;
	margin: 0 0 22px 0;
	user-select: none;
}

.inviteUser {
	&__license {
		margin-top: 22px;
	}

	.emailInput {
		margin-top: 22px;
	}

	.action {
		background-color: #f9f9f9;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		box-sizing: border-box;
		padding: 7px 15px;
		border-radius: 4px;
		height: 34px;
		color: #0c5850;
		border: none;
		user-select: none;
		white-space: nowrap;
		width: 100%;

		svg {
			margin-right: 6px;
		}

		&:hover {
			background-color: #0c5850;
			color: #f9f9f9;

			svg path {
				stroke: #f9f9f9;
				margin-right: 6px;
			}
		}
	}

	@media (max-width: #{$breakPoint}) {
		width: 45%;
	}

	@media (max-width: #{$rightPanelBreakPoint}) {
		width: 100%;
	}
}

.createOrganisation {
	margin-top: 30px;

	@media (max-width: #{$breakPoint}) {
		width: 45%;
		margin-top: 0;
	}

	@media (max-width: #{$rightPanelBreakPoint}) {
		margin-top: 30px;
		width: 100%;
	}
}

.remove_all {
	margin: 0;
	color: #0c5850;
	cursor: pointer;
	padding: 7px 15px;
	border-radius: 4px;
	text-align: center;
	background-color: #f9f9f9;

	&:hover {
		color: #f9f9f9;
		background-color: #0c5850;
	}
}
