.plans {
	position: relative;
	user-select: none;

	&.disabled {
		pointer-events: none;
	}
}

.dropdown {
	border-radius: 24px;
	border: 1px solid #b5b1b0;
	padding: 4px 8px 4px 16px;
	width: 100%;
	max-width: 128px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 28px;
	box-sizing: border-box;
	cursor: pointer;

	.planText {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: var(--brand-neutral-grey-600-b, #4e5555);
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
	}

	&.upgraded {
		border-color: #0c5850;
		background: #edf2f2;
	}

	&.expanded {
		svg {
			transform: rotateX(180deg);
		}
	}
}
