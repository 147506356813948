.content {
	max-height: 80vh;
	overflow: auto;
}

.description {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	margin: 4px 8px 16px 0;
}

.descriptionWrapper {
	display: flex;
	align-items: flex-start;
	margin-bottom: 8px;
}

.button {
	flex-shrink: 0;
	color: #404040;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	background: #ffffff;
	border: 1px solid #cbc5c2;
	margin-left: auto;

	&:hover {
		color: #ffffff;

		svg path {
			fill: #ffffff;
		}
	}
}

.grids {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}

.industry {
	display: flex;
	flex-direction: column;
	max-width: calc(50% - 8px);
	min-width: calc(50% - 8px);
	border: 1px solid #f4f0ed;
	background: #f9f9f9;

	.industryName {
		color: #404040;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		border-bottom: 1px solid #f4f0ed;
		padding: 8px 16px;
		background: #fff;
	}

	.companies {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		padding: 8px;
	}

	.company {
		align-items: center;
		background: #ffffff;
		border: 1px solid #f4f0ed;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		flex-shrink: 0;
		height: 56px;
		padding: 19px 16px;
		width: 131px;
		position: relative;
		z-index: 1;

		&__skeleton {
			display: none;
		}

		.companyName {
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			color: #271b00;
			margin-left: 8px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.companyImage {
			width: 24px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			user-select: none;
			color: #404040;
			pointer-events: none;
		}
	}

	&.singleGrid {
		max-width: 100%;
		min-width: 100%;

		.company {
			width: 132px;
		}
	}
}

.company {
	&:hover {
		.company__skeleton {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: transparent;
		}
	}
}
