.sectionWrapper {
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	background-color: #fff;
	margin-bottom: 1em;

	h3,
	div,
	p {
		font-family: Inter, sans-serif !important;
	}

	.labelWrapper {
		border-bottom: 1px solid #0c5850;
		width: 100%;
		padding: 11.5px 24px;
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 28px; /* 155.556% */
	}

	.childrenWrapper {
		padding: 30px 40px;
		position: relative;

		@media (max-width: 1200px) {
			padding: 12px 20px;
		}
	}
}
