@import "styles/common.module.scss";

.container {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 16px;
	left: 0;
	position: relative;
}

.searchBarContainer {
	margin-top: 32px;
	border-radius: 4px;
	background: var(--system-bw-white, #fff);
	width: 1200px;

	/* Shadows On Light/L */
	box-shadow: 0px 17px 33px -2px rgba(79, 75, 74, 0.05);
	padding: 16px;
	position: relative;
	//width: 792px;
	display: flex;
	flex: 1;
	z-index: 1;

	@media (max-width: 1024px) {
		margin-top: 10px;
		align-items: center;
		justify-content: flex-end;
		flex-direction: column;
	}
}

.quickSearchButton {
	width: 160px;
	height: 50px;
	border-radius: 0 4px 4px 0;
	background: var(--brand-primary-600-b, #0c5850);
	display: flex;
	align-items: center;
	padding: 8px 16px;

	/* Shadows On Light/L */
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);

	color: var(--brand-primary-50, #edf2f2);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px; /* 150% */

	&:hover {
		cursor: pointer;
	}

	&.disabled {
		cursor: not-allowed;

		&:hover {
			opacity: 0.4;
		}
	}

	@media screen and (max-width: 767px) {
		margin-top: 15px;
		width: 100%;
	}
}

.resultsWrapper {
	display: flex;
	padding-bottom: 50px;
}
