@import "./common.module.scss";
@import "./variables.module.scss";

.buttonRow {
	display: flex;
	justify-content: flex-end;
	gap: 10px;
	margin-top: 47px;
}

.clearButton {
	composes: button;
	background: $secondaryTextColor;
}

.saveButton {
	composes: button;
	background: $themeColor;
	color: #ffffff;

	&:disabled {
		//background: ligh;
		cursor: not-allowed;
	}
}

.inputLabel {
	color: $blackOpacity;
	text-transform: uppercase;
	font-size: 10px;
	line-height: 20px;
	letter-spacing: 0.05em;
	font-weight: 400 !important;
}

.input {
	height: 40px;
	background: #ffffff;
	border: 1px solid $inputBorder;
	padding: 0 16px;

	@include placeholder {
		color: #999;
	}
}
