@import "styles/variables.module.scss";
@import "styles/custom/_mixins.scss";
@import "styles/form.module.scss";

.subTitle {
	font-size: 14px;
	line-height: 24px;
	color: $lightGray;
	text-align: center;
	margin-top: 24px;
}

.actions {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.loginInput {
	margin: 0;
}

.resetButton {
	margin-top: 20px;
	padding: 15px 30px;
	color: #fff !important;
	&:disabled {
		background: #dedede !important;
	}
}

.links {
	margin-top: 32px;
	display: flex;
	justify-content: flex-end;
}

.linkForgot {
	font-size: 14px;
	line-height: 24px;
	color: #999999;
	margin-left: 12px;
	position: relative;
	top: 1px;

	&:nth-of-type(even) {
		margin-left: auto;
	}
}

.error {
	color: #f00;
}

.success {
	color: $green;
	margin-top: 0;
}

.linkContainer {
	text-align: center;
	padding: 15px 0;
}

.inputContainer {
	margin: 5px 0;
}

.forgotPass {
	margin-top: 20px !important;
}

.resetForm {
	padding: 46px 90px !important;
}

.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

// @TODO: Better to wrap however adding a div to Register breaks the layout
:global(.group1) {
	display: flex;

	> div:not(:last-child) {
		margin-right: 16px;
	}
}

.emailSentMessage {
	display: flex;
	flex-direction: column;
	align-items: center;

	> p {
		width: 90%;

		> b {
			font-weight: 600;
		}
	}
}
