.container {
	overflow: hidden;
	border-radius: 4px;
	background: var(--system-bw-white, #fff);
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);

	h2 {
		font-size: 18px;
		font-weight: 500;
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		line-height: 28px;
		border-bottom: 1px solid #eeedec;
		padding: 16px 24px;
		margin: 0;
	}

	h3 {
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-size: 18px;
		font-weight: 500;
		line-height: 28px;
		margin-left: 20px;

		span {
			color: var(--Brand-Neutral-Grey-600-B, #4e5555);
			font-weight: 600;
		}
	}

	.chartBox {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		gap: 20px;
		padding: 24px;

		@media screen and (max-width: 1280px) {
			width: 96%;
		}

		@media screen and (max-width: 1279px) {
			flex-direction: column;
		}

		.titleSection {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #eeedec;
			padding: 16px 24px;

			@media screen and (max-width: 800px) {
				flex-direction: column;
				gap: 20px;
			}

			span {
				color: var(--Brand-Neutral-Grey-800, #2c3030);
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 28px;
			}
		}

		.genderDiversity {
			border-radius: 4px;
			border: 1px solid var(--System-Silver-Chalice-100, #eeedec);
			background: var(--system-bw-white, #fff);
			width: 50%;
			//padding: 10px 0;

			@media screen and (max-width: 1024px) {
				width: 65%;
				padding: 0;
			}
			@media screen and (max-width: 700px) {
				width: 370px;
				padding: 0;
			}
		}

		.diversePathways {
			border-radius: 4px;
			border: 1px solid var(--System-Silver-Chalice-100, #eeedec);
			background: var(--system-bw-white, #fff);
			width: 50%;
			//padding: 10px 0;

			@media screen and (max-width: 1024px) {
				width: 65%;
				padding: 0;
			}
			@media screen and (max-width: 700px) {
				width: 370px;
				padding: 0;
			}
		}
	}
}

.barChartTitle {
	color: var(--Brand-Neutral-Grey-600-B, #4e5555);
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	margin: 0;
}

.diversityContainer {
	overflow: hidden;
}

.average {
	color: var(--Brand-Neutral-Grey-400, #868d8d);
	text-align: right;
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	align-items: center;
	display: inline-flex;
	gap: 5px;

	span {
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 13px;
		font-style: normal;
		font-weight: 600;
		line-height: 18px;
	}
}

.description {
	color: var(--Brand-Neutral-Grey-600-B, #4e5555);
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	margin-left: 30px;
}

.chartRow {
	display: flex;
	align-items: center;
}

.chartLabel {
	width: 165px;
	white-space: nowrap;
	font-size: 14px;
	color: rgba(78, 85, 85, 1);
	text-align: right;
	font-weight: 100;
	line-height: 20px;
	padding-right: 20px;
	position: relative;
	border-right: 1px solid rgba(131, 165, 124, 1);
	height: 26px;
}

.chartLabel:after {
	content: "-";
	position: absolute;
	right: -1px;
	color: rgba(131, 165, 124, 1);
}

.chartBar {
	border-radius: 4px 4px 0 0;
	background: rgba(124, 163, 178, 1);
	height: 17px;
	position: relative;
	transition: width 1s ease-out;
	//padding-right: 2px;
}

.chartValue {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	position: absolute;
	display: flex;
	align-items: center;
	top: 50%;
	right: 3px;
	transform: translate(0, -50%);
	font-size: 11px;
	font-weight: bold;
	color: #fff;
}

.chartContainer {
	font-family: Arial, sans-serif;
	max-width: 600px;
	margin: 0 auto;
	position: relative;
	//padding: 20px 0;
}

.gridContainer {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 20px;
	display: flex;
	z-index: 0;
	left: 165px;
}

.gridLine {
	flex: 1;
	border-right: 1px dashed rgba(218, 216, 215, 1);
	position: relative;
}

.gridLine:last-child {
	border-right: none;
}

.milestoneLabel {
	position: absolute;
	bottom: 20px;
	font-size: 12px;
	color: #999;
	transform: translateX(-50%);
}

.barRow {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	position: relative;
	z-index: 1;
}

.label {
	flex: 2;
	font-size: 14px;
	color: #333;
	text-align: left;
}

.barWrapper {
	flex: 6;
	background-color: #f0f0f0; /* Background for empty part of bar */
	border-radius: 5px;
	overflow: hidden;
	position: relative;
	height: 20px;
}

.bar {
	height: 100%;
	background-color: #91c8e4;
	border-radius: 5px;
}

.percentage {
	flex: 1;
	font-size: 14px;
	text-align: right;
	color: #555;
}

.tabWrapper {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 16px 24px 0 24px;
}

.chartContainer {
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 16px 24px;
}

.tabContainer {
	overflow: hidden;

	.tabs {
		display: flex;
		flex-direction: row;

		.tabTitle {
			padding: 0 5px 5px;
			display: flex;
			text-align: center;
			align-items: center;
			color: var(--Brand-Neutral-Grey-300, #b0b5b4);
			font-size: 13px;
			font-style: normal;
			font-weight: 600;
			line-height: 18px;

			@media screen and (max-width: 1024px) {
				width: 100%;
				border-bottom: 1px solid #f1f1f1;
				padding: 4px 16px;
			}

			&:hover {
				cursor: pointer;
			}

			&.activeTab {
				color: var(--Brand-Primary-600-B, #0c5850);
				border-bottom: 2px solid var(--Brand-Primary-600-B, #0c5850);

				@media screen and (max-width: 1024px) {
					align-items: center;
				}
			}
		}
	}
}

.customWrapper {
	display: flex;

	> div:nth-child(2) {
		margin-top: 30px;
	}
}

.barChartWrapper {
	padding: 0 10px 0 0;
}

.dropdownButton {
	min-width: 140px;
	height: 30px;
}

.dropdownMenu {
	height: auto;
	overflow-y: auto;
	scrollbar-width: thin;
}

.minimizedMenu {
	height: 180px;
}

.dropdownPlaceholder {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 16px;

	color: var(--Brand-Neutral-Grey-900, #181b1a);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	flex: 1;

	span {
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
	}
}
