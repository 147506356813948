@import "styles/common.module.scss";

.badge {
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	/* identical to box height, or 200% */
	padding: 3px 6px;
	color: $badgeText;
	background: $badgeBackground;
	border-radius: 30px;
	top: -10px;
	left: 3px;
	position: relative;
}
