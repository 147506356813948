.projectCreationTypesWrapper {
	padding: 48px;
}

.projectCreationTypes {
	display: flex;
	flex-direction: row;
	//padding: 48px 48px 0 48px;
	align-items: flex-start;
	gap: 24px;
	align-self: stretch;

	.projectCreationItemCard {
		display: flex;
		padding: 48px 24px;
		flex-direction: column;
		align-items: center;
		gap: 16px;
		flex: 1 0 0;
		align-self: stretch;
		border-radius: 4px;
		border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
		background: var(--system-bw-white, #fff);

		&:hover {
			cursor: pointer;
		}

		.title {
			color: var(--Brand-Neutral-Grey-800, #2c3030);
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 28px; /* 155.556% */
		}

		.description {
			color: var(--Brand-Neutral-Grey-600-B, #4e5555);
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
		}
	}
}

.scratchCreateProjectLink {
	display: flex;
	padding: 24px 16px 0 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;

	color: var(--Brand-Primary-600-B, #0c5850);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	text-decoration-line: underline;
	text-underline-offset: 3px;

	span:hover {
		cursor: pointer;
	}
}
