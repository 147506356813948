.container {
	display: flex;
	align-items: center;
}

.button {
	appearance: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	gap: 8px;
	box-sizing: border-box;
	border: 1px solid #cbc5c2;
	border-radius: 4px;
	cursor: pointer;
	height: 32px;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	background: #fff;
	transition: all 0.2s linear;
}

.button.active {
	border-color: #0c5850;
	background: #0c5850;
	color: #fff;

	svg {
		path {
			color: #0c5850;
		}
		rect {
			fill: #fff;
		}
	}
}
