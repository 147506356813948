.container {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: flex;
	max-width: 100%;
	position: fixed;
	align-items: center;
	justify-content: center;
	background-color: rgba(20, 20, 20, 0.7);
}

.wrapper {
	max-width: 400px;
	width: 100%;
	background: #ffffff;
	border-radius: 4px;

	&__title {
		margin: 0;
		padding: 16px;
		border-bottom: 1px solid #d1cdcc;
	}

	&__form {
		padding: 16px;

		&_label {
			margin: 0 0 6px 0;
			font-weight: 600;
			font-size: 14px;
			line-height: 22px;
			color: #a2a2a2;
		}

		&_button {
			width: 100%;
			padding: 13px 0;
			margin-top: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #0c5850;
		}

		&_status {
			display: flex;
			flex-direction: column;
			gap: 6px;
			margin-top: 16px;

			&_title {
				margin: 0;
				color: #a2a2a2;
			}

			&__toggle {
				gap: 8px;
				display: flex;
				align-items: center;

				&_text {
					margin: 0;
					font-size: 14px;
					font-weight: 500;
					color: #271b00;
					line-height: 22px;
					font-family: "Inter";
				}
			}
		}
	}
}
