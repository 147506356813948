@import "styles/custom/mixins";

.container {
	display: flex;
	flex-direction: column;
	margin-top: 48px;

	.titleArea {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;

		.title {
			color: #868d8d;
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: 32px;
		}

		.viewAllProjectButton {
			background: transparent;
			display: flex;
			padding: 8px 16px;
			margin-bottom: 15px;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			border: 1px solid var(--Brand-Neutral-Grey-600-B, #4e5555);
			gap: 8px;
			color: var(--Brand-Neutral-Grey-600-B, #4e5555);
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px; /* 171.429% */

			&:hover {
				cursor: pointer;
			}
		}
	}

	.contentArea {
		//@include responsive-home-flex();
		@include responsive-home-grid();
	}

	.skeletonLoaders {
		//@include responsive-home-flex();
		@include responsive-home-grid();
	}

	.card {
		display: flex;
		padding: 24px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 16px;
		align-self: stretch;
		border-radius: 4px;
		background: var(--system-bw-white, #fff);
		width: 288px;
		height: 204px;

		/* Shadows On Light/L */
		box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);

		&.createProjectCard {
			border: 1px solid var(--Brand-Primary-600-B, #0c5850);

			&:hover {
				cursor: pointer;
			}

			span {
				width: 288px;
				text-align: center;
				color: var(--Brand-Neutral-Grey-800, #2c3030);
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 28px; /* 155.556% */
			}
		}

		&.projectCard {
			border-radius: 4px 4px 0 0;
			box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
			padding-bottom: 0;

			.projectTitle {
				min-height: 84px;
				align-self: stretch;
				color: var(--Brand-Neutral-Grey-800, #2c3030);
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 28px; /* 155.556% */

				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;

				&:hover {
					cursor: pointer;
				}
			}

			.targetListInfo {
				display: flex;
				justify-content: space-between;
				align-items: center;
				align-self: stretch;

				color: var(--Brand-Neutral-Grey-600-B, #4e5555);
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */
			}

			.diversityInfo {
				min-width: 284px;
				white-space: nowrap;
				display: flex;
				padding: 8px 24px;
				align-items: center;
				gap: 8px;
				justify-content: flex-start;
				border-radius: 0 0 4px 4px;
				border-top: 1px solid var(--System-Silver-Chalice-100, #eeedec);
				margin-left: -24px;
				margin-right: -24px;

				overflow: hidden;
				color: var(--Brand-Neutral-Grey-600-B, #4e5555);
				text-overflow: ellipsis;

				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px; /* 142.857% */

				span {
					color: var(--Brand-Neutral-Grey-800, #2c3030);
					text-overflow: ellipsis;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 20px;
				}

				.progress {
					width: 120px;
				}
			}
		}
	}
}
