@import "styles/common.module.scss";

.container {
	width: 100%;
	margin-top: 30px;
	margin-bottom: 30px;
}

.chart {
	border: 1.5px solid #eeedec;
	border-radius: 4px;
	position: relative;
	margin-bottom: 50px;
}

.optionsContainer {
	display: flex;
	gap: 32px;
	background: #fff;
	padding: 0 16px;
	border-bottom: 1px solid #d1cdcc;

	&_btn {
		padding: 0 8px;
		font-size: 18px;
		cursor: pointer;
		font-weight: 500;
		color: #cbc5c2;
		padding-bottom: 18px;
		min-width: fit-content;

		&__active {
			color: #000;
			padding-bottom: 15px;
			border-bottom: 3px solid #0c5850;
		}
	}
}

.selectedOption {
	padding: 24px;
	background: #f9f9f9;
}

.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.diversity_wrapper {
	gap: 24px;
	display: flex;

	@media (max-width: 767px) {
		flex-wrap: wrap;
	}
}

.hidden {
	display: none;
}
