.content {
	display: flex;
	flex-direction: column;
}

.button {
	margin-left: auto;
	height: 48px;
	background-color: #0c5850;
	margin-top: 16px;
	width: 200px;
}
