.companyItem {
	display: flex;
	flex-direction: row;
	gap: 8px;
	margin-bottom: 5px;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	height: 30px;
	align-items: center;
	padding-right: 5px;

	.logo {
		display: flex;
		height: 25px;
	}

	.text {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	&:hover {
		cursor: pointer;
	}
}
