@import "styles/variables.module.scss";

.diversityContainer {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	padding-right: 0;
	width: 95%;
	padding-top: 5px;
}

.rangeTitle {
	font-size: 14px;
	margin-bottom: 10px;
}

.rangeResult {
	font-size: 12px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.slide {
	border-radius: 0;

	&.slideContainer {
		height: 10px;
		margin-left: 12px;
		width: 95%;
		box-sizing: content-box;
		cursor: pointer;
		border-radius: 0;

		&:global(.rc-slider-disabled) {
			background: transparent;
			opacity: 0.8;
			pointer-events: none;
		}

		& :global(.rc-slider-mark) {
			top: 30px;
		}

		& :global(.rc-slider-mark-text) {
			white-space: nowrap;
			transform: none !important;
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			color: #5a5a5abf;
			font-family: "Inter", sans-serif;

			&:last-of-type {
				right: 0;
				left: auto !important;
			}
		}

		& :global(.rc-slider-handle) {
			background-color: $rangeSliderRemaining;
			width: 24px;
			height: 24px;
			border: 6px solid $rangeSliderBackground;
			opacity: 1;
			top: 2px;
			// transform: none !important;
		}

		&:global(.rc-slider-disabled) {
			& :global(.rc-slider-handle) {
				border: 6px solid #c2bebd !important;
			}
		}

		& :global(.rc-slider-track) {
			border-radius: 0;
			background-color: $rangeSliderBackground;
			height: 10px;
		}

		& :global(.rc-slider-rail) {
			width: auto;
			height: 10px;
			left: 0;
			right: 0;
			border-radius: 0;
		}

		& :global(.rc-slider-handle:active) {
			box-shadow: 0 0 5px $rangeSliderBackground;
			border: none;
		}

		& :global(.rc-slider-dot) {
			display: none;
		}
	}
}
