.actionWrapper {
	display: flex;
	align-items: center;
	justify-items: center;
	gap: 5px;
}

.actionButton {
	cursor: pointer;
	display: flex;
	height: 32px;
	width: 32px;
	padding: 4px 6px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	border-radius: 4px;
	border: 1px solid var(--System-Silver-Chalice-100, #eeedec);
}

.trigger {
	margin-left: auto;
}

.option {
	cursor: pointer;
	padding: 8px 10px;
	font-size: 14px;
	line-height: 20px;
	user-select: none;

	&:hover {
		background-color: #eeeeee;
	}

	> svg {
		margin-right: 6px;
	}
}
