.radioInputContainer {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	gap: 8px;
	flex: 1 0 0;

	.radioInput {
		display: none;
	}

	.label {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;

		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px; /* 142.857% */

		cursor: pointer;
		position: relative;
		margin-left: 30px;

		&::before,
		&::after {
			border-radius: 50%;
			position: absolute;
			top: 1.5px;
			left: -30px;
			transition: all 0.3s ease-out;
			z-index: 2;
		}

		&::before {
			content: "";
			border: 2px solid #d9d9d9;
			width: 20px;
			height: 20px;
		}

		&::after {
			content: "";
			background: #0c5850;
			border: 2px solid #0c5850;
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			opacity: 0;
			width: 12px;
			height: 12px;
			margin-left: 4px;
			margin-top: 4px;
			transform: scale(0);
		}

		&.feedback {
			&::before {
				content: "";
			}

			&::after {
				content: "\2713";
				width: 20px;
				height: 20px;
				margin-left: 0;
				margin-top: 0;
			}
		}
	}

	.radioInput:checked ~ .label::before {
		border: 2px solid #0c5850;
	}

	.radioInput:checked ~ .feedback::before {
		border: none;
	}

	.radioInput:checked ~ .label::after {
		opacity: 1;
		transform: scale(1);
	}

	.description {
		color: var(--Brand-Neutral-Grey-600-B, #4e5555);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
		text-decoration-line: none;
	}
}
