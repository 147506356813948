.wrapper {
	display: flex;
	margin-top: 32px;
	flex-direction: column;

	&__tabs {
		display: flex;
		align-items: center;

		&__active {
			gap: 24px;
			padding: 16px;
			display: flex;
			align-items: center;
			background-color: #ffffff;
			border-radius: 4px 4px 0 0;
			border-bottom: 1px solid #eeedec;

			&_text {
				font-family: Inter;
				font-size: 16px;
				margin: 0;
				font-weight: 500;
				line-height: 24px;
			}

			&_count {
				margin: 0;
				font-family: Inter;
				font-size: 12px;
				font-weight: 600;
				line-height: 20px;
				min-width: 20px;
				height: 20px;
				align-items: center;
				display: flex;
				justify-content: center;
				padding: 4px;
				border-radius: 4px;
				background-color: #eeedec;
			}
		}

		&__disabled {
			padding: 16px 20px;
			display: flex;
			align-items: center;
			gap: 24px;

			&_text {
				font-family: Inter;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px;
				color: #cbcdcd;
				margin: 0;
			}

			&_count {
				height: 20px;
				margin: 0;
				border-radius: 4px;
				padding: 4px;
				display: flex;
				color: #cbcdcd;
				background-color: #eeedec;
				justify-content: center;
				font-family: Inter;
				font-size: 12px;
				font-weight: 600;
				line-height: 20px;
				align-items: center;
				min-width: 24px;
			}
		}
	}

	&__page {
		padding: 24px;
		background-color: #fff;
		border-radius: 0 4px 4px 4px;

		&__diagram {
			gap: 24px;
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 24px;
			justify-content: space-between;
		}

		&_func_charts {
			gap: 24px;
			display: flex;
			max-width: 100%;
			flex-wrap: wrap;
			filter: blur(4px);
			margin-top: 24px;
			justify-content: space-between;

			&__wrapper {
				flex-wrap: nowrap;
				align-items: center;
			}
		}
	}
}

.charts {
	width: 100%;
	max-width: 564px;
	border-radius: 4px;
	border: 1px solid #eeedec;

	&__head {
		display: flex;
		padding-top: 16px;
		margin-bottom: 32px;
		justify-content: space-between;
		border-bottom: 2px solid #eeedec;

		&_title {
			margin-left: 24px;
			font-family: Inter;
			font-size: 18px;
			font-weight: 500;
			line-height: 28px;
		}

		&__tabs {
			margin-right: 20px;
			display: flex;
			align-items: center;
			gap: 8px;

			&_active {
				color: #0c5850;
				font-family: Inter;
				font-size: 13px;
				font-weight: 600;
				line-height: 18px;
				border-bottom: 1px solid #0c5850;
				padding-bottom: 6px;
			}

			&_un_active {
				color: #b0b5b4;
				font-family: Inter;
				font-size: 13px;
				padding-bottom: 6px;
				font-weight: 600;
				line-height: 18px;
			}
		}
	}

	&__diagram {
		&__text {
			filter: blur(4px);
			margin-left: 24px;
			margin-bottom: 32px;
		}

		&__img {
			margin: 32px 0 24px 24px;
		}
	}
}

.location {
	border: 1px solid #eeedec;
	border-radius: 4px;

	&__head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #eeedec;

		&__title {
			font-family: Inter;
			font-size: 18px;
			font-weight: 500;
			line-height: 28px;
			color: #2c3030;
			margin: 16px 24px;
		}

		&__arrow {
			width: 32px;
			height: 32px;
			margin-right: 24px;
		}
	}

	&__image {
		max-width: 100%;
	}
}

.upgrade {
	gap: 16px;
	z-index: 2;
	width: 100%;
	display: flex;
	margin: 0 auto;
	max-width: 347px;
	text-align: center;
	position: relative;
	bottom: 150px;
	flex-direction: column;
	justify-content: center;

	&__text {
		font-size: 24px;
		color: #2c3030;
		font-weight: 600;
		line-height: 32px;
		font-family: Inter;
	}

	&__button {
		display: flex;
		margin: 0 auto;
		font-size: 14px;
		font-weight: 600;
		color: #edf2f2;
		max-width: 200px;
		line-height: 20px;
		font-family: Inter;
		align-items: center;
		justify-content: center;
		padding: 0 16px;
		height: 48px;
		box-shadow: 0px 8px 8px 0px #e5f3f1;
	}

	&__leftIcon {
		margin-right: 8px;
	}

	&__plus {
		background-color: #edf2f2;
		padding: 0 2px;
		border-radius: 2px;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		color: #0c5850;
		height: 20px;
		margin-left: 6px;
	}
}
