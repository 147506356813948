.chartGrid {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	margin-top: 20px;
	border-top: 1px solid #eeedec;
	border-left: 1px solid #eeedec;

	@media (max-width: 1024px) {
		height: unset;
		display: flex;
		flex-wrap: wrap;

		a {
			width: 50%;
		}
	}

	@media (max-width: 767px) {
		//gap: 12px;
		height: unset;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: center;

		a {
			width: 100%;
		}
	}

	img {
		width: 50px;
		height: 50px;
		border-radius: 100px;
	}
}

.wrapper {
	position: relative;
	z-index: 0;
}

.sectionContainer {
	padding: 0 24px;
}

.title {
	color: #0c5850;
}

.expandedGrid {
	height: auto;
}

.buttonContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 10px;
	padding: 20px 0;
	margin-bottom: 12px;

	&.loading {
		opacity: 0.7;
		pointer-events: none;
	}

	button {
		padding: 10px 23px;
		border-radius: 100px;
		border: 1px solid #0c5850;
		color: #0c5850;
		background-color: transparent;
		cursor: pointer;

		div {
			gap: 10px;
			display: flex;
			flex-direction: row;
		}
	}
}

.border {
	display: flex;
	width: 100%;
	height: 16px;
	//background: linear-gradient(180deg, rgba(217, 217, 217, 0.28) 0%, rgba(217, 217, 217, 0) 100%);

	// img {
	// 	width: 50%;
	// 	position: relative;

	// 	&:first-child {
	// 		left: 1px;
	// 	}

	// 	&:last-child {
	// 		right: 1px;
	// 	}
	// }
}
