.container {
	width: 100%;
	display: flex;
	margin: 0 auto;
	max-width: 1248px;
	align-items: center;
	padding-bottom: 30px;
	flex-direction: column;
	height: calc(100vh - 72px);
	justify-content: space-between;

	&__search {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&__logo {
			display: block;
			margin: 16px auto 3vh;

			@media (max-width: 870px) {
				margin: 16px auto;
			}
		}

		&__wrapper {
			width: 80%;
			margin-top: 25px;
			position: relative;

			&__boarding {
				top: -32px;
				left: -32px;
				padding-bottom: 24px;
				width: calc(100% + 64px);
			}
		}

		&__empty {
			width: 100%;
			margin-top: 35px;
		}
	}
}

.search {
	display: flex;

	&__show {
		position: relative;
		z-index: 35;
	}

	&__content {
		gap: 8px;
		margin: 0;
		width: 100%;
		cursor: text;
		display: flex;
		padding: 8px 16px;
		background: #fff;
		align-items: center;
		border: 1px solid #dad8d7;
		border-radius: 4px 0px 0px 4px;
	}

	&__button {
		height: 41px;
		padding: 16px;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		border-radius: 0px 4px 4px 0px;

		&__icon {
			stroke: white;
		}
	}
}
