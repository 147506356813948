@import "styles/common.module.scss";

.container {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 16px;
	left: 0;
	position: relative;
}
