.checkboxList {
	display: inline-flex;
	flex-direction: column;
	padding-right: 10px;
	width: 100%;
	max-height: 280px;
	overflow: hidden scroll;
}

.checkboxContainer {
	margin-bottom: 5px;
}

.logicWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-top: 16px;

	@media screen and (max-width: 767px) {
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

	.logicLabel {
		color: var(--brand-neutral-grey-800, #2c3030);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
	}
}
