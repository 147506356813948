.suggestionItem {
	display: flex;
	flex-direction: column;
	justify-content: center;
	//padding: 15px 0;

	.suggestionTopPart {
		display: flex;
		justify-content: space-between;

		.topPart {
			display: flex;
			align-items: center;

			h4 {
				margin: 0;
				font-size: 14px;
			}

			img {
				width: 30px;
				height: 30px;
				border-radius: 100px;
				margin-right: 8px;
				border: 1px solid #d1cdcc;
			}

			span {
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
			}
		}
	}
}

.jobTitle {
	margin-top: 5px;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
}

.companyName {
	font-size: 12px;
	margin: 0;
	display: flex;
	align-items: center;
	text-align: right;
}

.suggestionList {
	font-family: "Inter", sans-serif;
	background-color: #fff;
	margin-top: 0px;
	z-index: 200;
	overflow-y: auto;
	position: absolute;
	width: 100%;
	margin-left: 0px;
	display: flex;
	flex-direction: column !important;
	padding: 16px;
}

.suggestionContainer {
	font-family: "Inter", sans-serif;
	display: flex;
	align-items: flex-start;
	width: 100%;
	overflow-y: scroll;
	height: 250px;
	position: relative;
	margin-bottom: 5px;
	box-shadow: 0px 0px 31px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	top: -10px;
}

.resetFilter {
	font-size: 12px;
	line-height: 20px;
	color: #0c5850;
	cursor: pointer;
}

.loaderWrapper {
	text-align: center;
}
