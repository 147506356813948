.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.header {
	font-family: "Inter", sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 30px;
	color: #808080;
	margin: 40px 0 32px 0;
}

.tableContainer {
	display: flex;
	flex-direction: column;
	background: #ffffff;
	box-shadow: 0 16px 40px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	min-height: 500px;
	width: 100%;
	padding: 24px;

	.tableTitleContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: -24px;
		border-bottom: 1px solid #e1e1e1;
	}

	.title {
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		padding: 24px;
	}

	.searchInputWrapper {
		padding-right: 24px;
	}

	.tableWrapper {
		margin: 48px 0;
		//max-height: 550px;
		//overflow-y: auto;
	}
}

.table {
	border: 1px solid #e3e3e3 !important;
	border-radius: 4px !important;

	th,
	td {
		padding: 8px 16px !important;
	}

	.header {
		background: #e3e3e3;
		border: 1px solid #e3e3e3 !important;
		border-radius: 4px 4px 0 0;
	}

	.tableRow {
		border: none !important;
		border-bottom: 1px solid #e3e3e3 !important;
		background: linear-gradient(0deg, #fafafa, #fafafa), #ffffff;

		&:nth-child(odd) {
			background: #ffffff;
		}
	}

	.tableCell {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: #5a5a5a;
	}

	tr td {
		&:last-child {
			border-left: 1px solid #e3e3e3;
		}
	}

	.status {
		min-width: 64px;
		max-width: 95px;
		border-radius: 16px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		padding: 0 8px;
		gap: 8px;

		&.open {
			color: #a39c9a;
			background: #f4f4f4;
			max-width: 70px;

			.point {
				background: #a39c9a;
			}
		}

		&.updated {
			color: #0c5850;
			background: #e6f4ea;

			.point {
				background: #0c5850;
			}
		}

		&.resolved {
			color: #404040;
			background: #f4f4f4;

			.point {
				background: #404040;
			}
		}

		.point {
			width: 4px;
			height: 4px;
			border-radius: 50%;
		}
	}
}

.loaderCell {
	border: 1px solid #e3e3e3 !important;
	height: 40px;
}
