@import "styles/common.module.scss";

// .candidateWithRoundButton {
//  padding-bottom: 65px !important;
// }

.candidate {
	display: flex;
	flex-direction: column;
	background: #ffffff;
	padding: 24px 30px;
	margin-bottom: 1rem;
	min-height: 255px;
	border: 1px solid #d1cdcc;
	justify-content: space-between;
	position: relative;

	@media #{$viewportMobileS} {
		padding: 0.6em;
	}
	@media #{$viewportMobile} {
		padding: 0.6em;
	}
	@media #{$viewportMobileX} {
		padding: 0.8em;
	}
	@media #{$viewportSmall} {
		padding: 0.9em;
	}
	@media #{$viewportMedium} {
		padding: 1em;
	}
	@media #{$viewportTablet} {
		padding: 1em;
	}
	@media #{$viewportMinLarge} {
		padding: 24px;
	}

	&.recommended {
		border: 1px solid var(--Brand-Tertiary-800, #d58a31);
	}

	.infoBlock {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		min-height: 95px;

		@media (max-width: 767px) {
			flex-direction: column;
			align-items: flex-start;
			gap: 20px;
		}
	}

	.buttonsBlock {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: 5px;
	}

	.actionBlockForQuickViewProject {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 10px;
		margin-top: 10px;

		.generateContactDetails {
			border-radius: 4px;
			border: 1px solid #dad8d7 !important;
			display: flex;
			padding: 4px 11px;
			justify-content: center;
			align-items: center;
			background: #fff;
			height: 32px;
			gap: 5px;
			color: #181b1a;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;

			&:hover {
				background: transparent;
			}

			&.locked {
				color: #c3c3c3;
			}

			@media screen and (max-width: 767px) {
				font-size: 12px;
				line-height: 14px;
				padding: 4px 6px;
			}
		}
	}

	.contactDetails {
		margin: 15px 0;
		display: flex;
		justify-content: flex-start;
		gap: 10px;
		max-width: 800px;
		flex-wrap: wrap;
		font-size: 14px;
	}

	.extraMargin {
		margin-bottom: 30px;
	}
}

.saveButton,
.rejectButton {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 4px;
	border-radius: 4px;
	height: 32px;
}

//.saveButton {
//	border: 1px solid rgba(12, 88, 80, 1);
//}

.rejectButton {
	border: 1px solid rgba(201, 21, 21, 1);
}

.leftContainer {
	max-width: 85%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (max-width: 767px) {
		max-width: 100%;
	}
}

.rightContainer {
	width: 150px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

// Stat Section

.experienceContainer {
	border: 1px solid #d1cdcc;
	border-radius: 5px;
	padding: 7px;
	flex-direction: column;
	justify-content: space-between;
}

.experienceTitle {
	display: flex;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #271b00;
	margin-bottom: 7px;
}

.rightWrapper {
	display: flex;
	gap: 8px;

	@media (max-width: 767px) {
		position: absolute;
		right: 10px;
	}
}

.refreshIcon {
	&:hover {
		cursor: pointer;
	}
}

.experience {
	display: flex;
	justify-content: center;
	color: rgba(0, 0, 0, 1);
	background: #f9f9f9;
	border-radius: 2px;
	padding: 2px 4px;
	margin-bottom: 6px;

	&:last-child {
		margin-bottom: 0;
	}
}

.experienceName {
	display: flex;
	border-right: 0.5px solid #c8c8c8;
	padding-right: 4px;
	justify-content: flex-start;
	align-self: center;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
}

.experienceDuration {
	display: flex;
	padding-left: 4px;
	justify-content: center;
	align-self: center;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
}

.experienceName,
.experienceDuration {
	max-width: 50%;
	min-width: 50%;
}

.progressBar {
	margin-top: 20px;
}

.indexStat {
	margin-bottom: 1rem;
}

.indexIcon,
.employeeIcon {
	margin-right: 1rem;
}

.indexTitle {
	font-weight: 600;
	font-size: 0.95rem;
	color: $secondaryTextColor;
}

.employeeStat {
	margin-bottom: 1rem;
}

.employeeTitle {
	font-size: 0.85rem;
	line-height: 20px;
}

.button {
	background: #3c6662;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);

	display: flex;
	flex-direction: column;
	color: #fff;
	border: none;
	border-radius: 100px;
	height: 64px;
	width: 64px;
	cursor: pointer;
	font-size: 12px;
	padding: 0;

	@media print {
		display: none !important;
	}

	@media (max-width: 1024px) {
		display: none;
	}

	.plus {
		font-size: 18px;
	}

	top: -10px;
	position: absolute;
	right: -70px;

	&.disabled {
		pointer-events: none;
	}

	&.printRequested {
		display: none;
	}
}

.upgradeView {
	//background: #ffffff;
	//border: 1px solid #D1CDCC;
	pointer-events: none;
	position: relative;

	> * {
		opacity: 0.32;
		filter: blur(6px);
	}
}

.recommendedIcon {
	position: absolute;
	z-index: 1;
	top: 35px;
}

.buttonsWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	@media screen and (max-width: 767px) {
		justify-content: center;
	}

	.showMoreButton {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		border: 1px solid #dad8d7;
		padding: 4px 12px;
		background: #fff;
		color: #4e5555;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		//height: 42px;
		height: 40px;

		svg {
			transform: rotateZ(180deg);
			margin-right: 0;
		}

		&:hover {
			background: transparent;
			opacity: 0.8;
		}

		@media screen and (max-width: 767px) {
			padding: 4px 7px;
			font-size: 12px;
			line-height: 10px;
			min-width: fit-content;
			margin-right: 4px;
		}
	}

	.reverse {
		background: var(--brand-neutral-grey-50, #f7f8f8);

		svg {
			transform: rotateZ(0deg);
		}
	}

	.moreLikeThisButton {
		height: 40px;

		@media screen and (max-width: 767px) {
			padding: 4px 2px;
			font-size: 11px;
			line-height: 13px;
		}
	}
}
