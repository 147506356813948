.dropdown {
	position: relative;
	display: flex;
	flex-direction: column;
	//z-index: 3;
	width: auto;
}

.dropdownToggle {
	cursor: pointer;
	background: transparent;

	display: flex;

	height: 40px;
	padding: 8px 8px 8px 16px;
	align-items: center;
	gap: 16px;

	color: var(--Brand-Neutral-Grey-600-B, #4e5555);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */

	border-radius: 4px;
	border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
}

button[aria-label="open"] [id="arrow-down-icon"] {
	transform: rotate(180deg);
	transition: transform 0.3s ease;
}

/* Rotated state when aria-label is "close" */
button[aria-label="closed"] [id="arrow-down-icon"] {
	transform: rotate(0deg);
	transition: transform 0.3s ease;
}

.dropdownMenu {
	position: absolute;
	list-style-type: none;
	padding: 0;
	margin: 0;
	background-color: white;
	//min-width: 180px;
	border-radius: 4px;
	background: var(--system-bw-white, #fff);
	box-shadow: 0 25px 60px -10px rgba(79, 75, 74, 0.12);
	right: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	z-index: 1;

	transition: opacity 0.3s ease, transform 0.3s ease;
	opacity: 0;
	visibility: hidden;
	transform: translateY(-10px);

	&.displayScroll {
		max-height: 300px;
		overflow-y: auto;
	}

	&.open {
		opacity: 1;
		transform: translateY(0);
		visibility: visible;
	}

	&::-webkit-scrollbar {
		width: 4px;
	}
}

.listItem {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: 6px;
	flex-direction: column;
	width: 100%;
}

.dropdownItem {
	padding: 8px 16px;
	cursor: pointer;
	width: 100%;

	color: var(--Brand-Neutral-Grey-600-B, #4e5555);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */

	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 6px;
	flex-direction: row;

	position: relative;
}

.dropdownItem:hover {
	background-color: #f1f1f1;
	opacity: 0.7;

	&:has(.childrenList) {
		opacity: 1;
	}
}

.selectedItem {
	font-weight: 600;
	background-color: #f1f1f1;
}

.endContent {
	position: relative;
	padding: 10px 15px;
}

.endContent::before {
	content: "";
	display: block;
	width: 200px;
	height: 1px;
	background-color: #f8f7f7;
	position: absolute;
	top: 0;
	left: 0;
	margin: 0 15px;
}

.endContentWrapper {
	display: flex;
	align-items: center;
}

.separator {
	border-bottom: 1px solid #f8f7f7;
	margin: 0 0 8px;
	display: flex;
	width: 100%;
}

.hasChildrenIcon {
	display: flex;
	position: absolute;
	z-index: 4;
	right: 10px;
}

.childrenList {
	position: absolute;
	top: 0;
	left: 100.5%;
	width: 100%;
	background-color: white;
	border-radius: 4px;
	box-shadow: 0 25px 60px -10px rgba(79, 75, 74, 0.12);
	padding: 0;
	//display: none;
	opacity: 0;
	transform: translateX(-10px); // Slightly offset at the start
	transition: opacity 0.3s ease, transform 0.3s ease; // Transition properties
	margin: 0;
	z-index: 10;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	max-height: 300px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 4px;
	}

	.childEndContent {
		position: relative;
		padding: 0 0 10px 0;
	}

	.childEndContent::before {
		content: "";
		display: block;
		width: 200px;
		height: 1px;
		background-color: #f8f7f7;
		margin: 0 15px;
	}
}

.listItem:hover .childrenList {
	opacity: 1; // Fade in
	transform: translateX(0); // Slide into position
}
