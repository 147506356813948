.candidateSearchHeaderSection {
	display: flex;
	justify-content: space-between;
	padding: 16px 16px 0 0;
	gap: 8px;
	align-items: center;
	align-self: stretch;
	flex-direction: row;
	background-color: #fff;
	border-bottom: 1px solid var(--System-Silver-Chalice-100, #eeedec);
	background: var(--system-bw-white, #fff);

	position: fixed;
	z-index: 2;
	width: calc(100% - 72px);
	left: 72px;
	top: 72px;

	@media screen and (max-width: 1024px) {
		position: relative;
		top: -30px;
		left: 0px;
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		padding-right: 0;
	}

	.tabs {
		display: flex;
		flex-direction: row;

		@media screen and (max-width: 1024px) {
			flex-direction: column;
			align-items: center;
			width: 100%;
		}

		.tabTitle {
			padding: 0 16px 16px;
			display: flex;
			color: var(--Brand-Neutral-Grey-400, #868d8d);
			text-align: center;
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 28px; /* 155.556% */
			align-items: center;

			@media screen and (max-width: 1024px) {
				width: 100%;
				border-bottom: 1px solid #f1f1f1;
				padding: 4px 16px;
			}

			&:hover {
				cursor: pointer;
			}

			&.activeTab {
				color: var(--Brand-Neutral-Grey-800, #2c3030);
				border-bottom: 1px solid #2c3030;

				@media screen and (max-width: 1024px) {
					align-items: center;
				}
			}
		}
	}

	.buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;
		padding-bottom: 12px;

		@media screen and (max-width: 1024px) {
			padding: 4px 16px 12px 16px;
		}

		.howToUse,
		.downloadButton,
		.enhanceButton {
			background: #fff;
			display: flex;
			padding: 6px 8px;
			justify-content: center;
			align-items: center;
			gap: 6px;
			border-radius: 4px;
			border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
			color: var(--Brand-Neutral-Grey-600-B, #4e5555);
			font-size: 13px;
			font-style: normal;
			font-weight: 600;
			line-height: 18px; /* 138.462% */
			height: 32px;

			&:hover {
				cursor: pointer;
			}
		}

		.enhanceButton {
			border: 1px solid var(--Brand-Primary-600-B, #0c5850);
			color: var(--Brand-Primary-600-B, #0c5850);
		}
	}
}

.tabContent {
	display: flex;
	margin-top: 66px;

	@media screen and (max-width: 1024px) {
		margin-top: -40px;
	}
}

.count {
	background: #f1f1f1;
	border-radius: 3px;
	padding: 3px 6px;
	display: inline-block;
	color: #404040;
	font-weight: 500;
	font-size: 13px;
	margin-left: 10px;
}
