.header {
	display: flex;
	align-items: center;
	gap: 16px;
	align-self: stretch;

	.info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;
		flex: 1 0 0;

		.title {
			color: var(--Brand-Neutral-Grey-800, #2c3030);
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 28px; /* 155.556% */
		}

		.description {
			color: var(--Brand-Neutral-Grey-600-B, #4e5555);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
		}
	}
}
