.container {
	min-height: 350px;
	padding: 24px 12px;
	//max-height: 330px;
	border-radius: 4px;
	background-color: #fff;
	border: 1px solid #eeedec;
	width: 50%;

	@media screen and (max-width: 1210px) {
		max-height: fit-content;
		min-height: fit-content;
		width: 100%;
	}

	&__header {
		display: flex;
		padding: 0 12px;
		align-items: center;
		margin-bottom: 12px;
		justify-content: space-between;

		@media screen and (max-width: 1024px) {
			flex-direction: column;
			align-items: flex-start;
			gap: 10px;
		}

		&_title {
			margin: 0;
			font-size: 18px;
			font-weight: 500;
			color: #2c3030;
			line-height: 28px;
		}

		&_select {
			margin: 0;
			display: flex;

			&_item {
				margin: 0;
				padding: 4px;
				cursor: pointer;
				color: #cbcdcd;
				border-bottom: 1px solid #cbcdcd;
				font-weight: 600;
				font-size: 13px;
				line-height: 18px;

				&_active {
					color: #0c5850;
					border-bottom: 2px solid #0c5850;
				}
			}
		}
	}

	&_description {
		margin: 0 0 24px;
		padding: 0 12px;
		font-size: 13px;
		font-weight: 400;
		color: #4e5555;
		line-height: 18px;
	}
}
