.wrapper {
	position: relative;
	min-width: 300px;
	margin-right: auto;
	margin-left: 16px;
	display: none;

	@media screen and (min-width: 1025px) {
		display: flex;
	}

	&__container {
		cursor: text;
		display: flex;
		padding: 12px 16px;
		background: #fff;
		height: 40px;
		border-radius: 4px;
		align-items: center;
		border: 1px solid #e9e9e9;
		min-width: 290px;

		&__active {
			border-radius: 4px 4px 0px 0px;
			box-shadow: 0px 9px 31px rgba(0, 0, 0, 0.04);
		}

		&__icon {
			margin-right: 6px;
		}

		&__inp {
			padding: 0;
			width: 100%;
			border: none;
			outline: none;
			margin-top: 1px;
			font-size: 14px;
			font-weight: 500;
			line-height: 24px;
			font-family: "Inter", sans-serif;
			background: transparent;
		}

		&__inp::placeholder {
			color: #b0b5b4;
		}
	}
}

.list {
	width: 100%;
	min-width: 380px;
	top: 48px;
	z-index: 3;
	padding: 16px;
	height: 50vh;
	position: absolute;
	background: #fff;
	border: 1px solid #eeedec;
	border-top: 0;
	border-radius: 0px 0px 4px 4px;
	box-shadow: 0px 9px 31px rgba(0, 0, 0, 0.04);

	&__data {
		overflow-y: auto;
		height: calc(100% - 46px);
	}

	&__header {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		border-bottom: 1px solid #f4f0ed;
		font-size: 14px;

		&__category {
			display: flex;
			color: #868d8d;
			align-items: center;
			padding: 0 13px 9px !important;
			font-weight: 600;

			&_active {
				color: #271b00;
				padding-bottom: 7px !important;
				border-bottom: 2px #271b00 solid !important;
			}

			&__skeleton,
			&__badge {
				display: flex;
				height: 20px;
				min-width: 24px;
				overflow: hidden;
				margin-left: 8px;
				border-radius: 4px;
			}

			&__badge {
				padding: 4px;
				font-size: 12px;
				font-weight: 600;
				line-height: 20px;
				text-align: center;
				border-radius: 4px;
				align-items: center;
				background: #f4f0ed;
				justify-content: center;
			}
		}
	}
}

.companies {
	&__border {
		margin-bottom: 16px;
		padding-bottom: 16px;
		border-bottom: 1px solid #f4f0ed;
	}

	&__title {
		margin: 0 0 8px;
		font-size: 14px;
		color: #b0b5b4;
		font-weight: 600;
		line-height: 22px;
		font-family: "Inter";
	}

	&__container {
		gap: 8px;
		display: flex;
		flex-direction: column;

		&__item {
			display: flex;
			border-radius: 4px;
			align-items: center;
			padding: 4px 8px !important;

			&_img {
				width: 32px;
				height: 32px;
				min-width: 32px;
				min-height: 32px;
				margin-right: 8px;
				border-radius: 2px;
				border: 1px solid #eeedec;
			}

			&_name {
				margin: 0;
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				font-family: "Inter";
			}

			&__texts {
				text-align: left;

				&_name {
					margin: 0;
					font-weight: 500;
					font-size: 14px;
					line-height: 22px;
					font-family: "Inter";
				}

				&_text {
					margin: 0;
					font-size: 12px;
					color: #808080;
					font-weight: 400;
					line-height: 18px;
					font-family: "Inter";
				}
			}
		}

		&__item:hover {
			background: #eeedec;
		}

		&__show {
			font-size: 12px;
			color: #0c5850;
			text-align: left;
			font-weight: 600;
			line-height: 20px;
			font-family: "Inter";
		}
	}
}
