@import "styles/common.module.scss";

.specialityButton {
	display: flex;
	flex-direction: row;
	padding: 4px 8px;
	align-items: flex-start;
	gap: 8px;
	border-radius: 3px;
	text-align: center;
	border: $infoButtonBorder;
	height: $infoButtonHeight;
	margin: 0 0.4em 0.4em 0;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	background: var(--System-Silver-Chalice-50, #f8f7f7);
	color: var(--Brand-Neutral-Grey-800, #2c3030);

	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 133.333% */
}

.specialitySelected {
	background: #0c5850 !important;
	color: #fff !important;
	border: $infoButtonBorder;
	height: $infoButtonHeight;
	padding: 5px;
	//margin: 0 0.4em 0.4em 0;
	//cursor: pointer;
	//font-size: 12px;
	//white-space: nowrap;
	//overflow: hidden;
	//text-overflow: ellipsis;
	max-width: 100%;
}

.secondText {
	color: #0c5850;
	//margin-left: 5px;
	//font-weight: 500;
}

.activeSecond {
	color: #fff !important;
}

.disabled {
	cursor: default !important;
}
