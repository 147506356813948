.wrapper {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	display: flex;
	position: fixed;
	min-height: 100vh;
	align-items: center;
	justify-content: center;

	&__bg {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		position: absolute;
		background-color: rgba(20, 20, 20, 0.7);
	}
}

.container {
	z-index: 2;
	width: 100%;
	max-width: 73%;
	min-width: 600px;
	background: #fff;
	border-radius: 4px;
	max-height: calc(100vh - 90px);

	&__head {
		padding: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&_title {
			font-weight: 500;
			font-size: 18px;
			line-height: 26px;
			color: #2c3030;
			margin: 0;
		}

		&_close {
			width: 20px;
			height: 20px;
			cursor: pointer;
		}
	}

	&__hr {
		height: 1px;
		margin-bottom: 23px;
		border: 0.5px solid #eeedec;
	}

	&__body {
		gap: 24px;
		padding: 24px;
		display: flex;
		padding-top: 0;
		overflow-y: auto;
		max-height: 63vh;
		justify-content: space-between;

		&__left {
			width: 100%;
			max-width: 59%;
			padding: 32px;
			height: 100%;
			background-size: cover;
			background-image: url(../../../assets/images/isFirstModalBgimage.png);

			&_title {
				font-weight: 600;
				font-size: 36px;
				margin: 0;
				margin-bottom: 16px;
				line-height: 44px;
				color: #2c3030;
			}

			&_description {
				font-family: "Inter";
				font-weight: 400;
				font-size: 20px;
				line-height: 28px;
				margin: 0;
				margin-bottom: 24px;
				color: #2c3030;
			}

			&_content {
				font-family: "Inter";
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				color: #4e5555;
			}
		}

		&__right {
			width: 100%;
			max-width: 40%;

			&_img {
				height: 218px;
				cursor: pointer;
				max-width: 360px;
				margin-bottom: 24px;
				background-size: cover;
				background-image: url(../../../assets/images/firstVideModal.png);
			}

			&_title {
				font-weight: 600;
				font-size: 24px;
				line-height: 32px;
				margin: 0;
				margin-bottom: 8px;
				color: #181b1a;
			}

			&_description {
				font-family: "Inter";
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				color: #4e5555;
				display: block;
				margin-bottom: 16px;
			}

			&__watch {
				gap: 8px;
				padding: 8px 16px;
				display: flex;
				color: #eeecf5;
				border-radius: 4px;
				align-items: center;
				background: #493c70;
				justify-content: center;

				&_icon {
					height: 22px;
					display: flex;
					align-items: center;
					justify-content: center;

					svg {
						path {
							fill: #eeecf5;
						}
					}
				}
			}
		}
	}

	&__video {
		position: relative;
		padding: 0 50px 56.25% 50px;

		&_iframe {
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			position: absolute;
		}
	}
}
