@import "styles/common.module.scss";

.resultCount {
	font-weight: 600;
	font-size: 1.125rem;
}

.resultsWrapper {
	width: 100%;

	&__content_container {
		width: 100%;
	}
}

.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.headerSectionWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	padding: 15px;
	background: #fff;
	//border-radius: 4px;
	border-bottom: 1px solid #eeedec;

	.actionButtonsRight,
	.actionButtonsLeft {
		display: flex;
		flex-direction: row;
		gap: 8px;
	}

	.resetFilterButton {
		display: flex;
		align-items: center;
		cursor: pointer;
		font-size: 12px;
		font-weight: 500;
		gap: 5px;
		border-radius: 4px;
		padding: 6px 8px;
		background: transparent;
		color: var(--brand-neutral-grey-600-b, #4e5555);
		border: 1px solid var(--system-silver-chalice-200, #dad8d7);
	}

	.toggleExpandButton {
		display: flex;
		align-items: center;
		gap: 5px;
		border-radius: 4px;
		padding: 6px 8px;
		background: transparent;
		color: var(--brand-neutral-grey-600-b, #4e5555);
		height: 36px;
		border-radius: 4px;
		border: 1px solid var(--system-silver-chalice-200, #dad8d7);

		&.reverse {
			background: var(--brand-neutral-grey-50, #f7f8f8);

			svg {
				transform: rotate(180deg);
			}
		}
	}

	.count {
		display: flex;
		align-items: center;
		font-size: 14px;
		line-height: 22px;
		color: var(--brand-neutral-grey-600-b, #4e5555);
		font-weight: 600;
		background: #f1f1f1;
		border-radius: 3px;
		padding: 3px 6px;
		border: 1px solid var(--system-silver-chalice-200, #dad8d7);
	}
}
