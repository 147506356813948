.accordion {
	height: 54px;

	&:not(:last-child) {
		border-bottom: 0.5px solid #d1cdcc;
	}

	&.expanded {
		height: auto;
	}

	::-webkit-scrollbar {
		width: 4px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		border-radius: 10px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #3c6662;
		border-radius: 10px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #0c5850;
	}
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;

	.left {
		display: flex;
		align-items: center;

		.title {
			margin-left: 5px;
			color: #312200;
			font-size: 14px;
			font-weight: 600;
			user-select: none;
		}

		.count {
			margin-left: 8px;
			color: #404040;
			font-weight: 600;
			font-size: 12px;
			user-select: none;

			padding: 4px;
			min-width: 24px;
			height: 24px;
			background: #f4f0ed;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.right {
		& > svg {
			transition: transform 0.2s linear;

			&.rotated {
				transform: rotateX(180deg);
			}
		}
	}
}

.children {
	display: none;

	&.expanded {
		display: block;
		animation: fadeIn 1s;
	}
}

.resetFilterContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-bottom: 16px;
	padding-top: 6px;

	.resetArea {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		//gap: 19px;
	}
}

.checkboxContainer {
	margin-bottom: 12px;
}

.selectAll {
	user-select: none;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	color: #5a5a5a;

	&:hover {
		cursor: pointer;
	}
}

.line {
	border-bottom: 1px solid #d1cdcc;
	width: 100%;
	height: 1px;
	margin: 16px 0;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.targetListButton {
	background: #ffffff;
	color: #0c5850;
	font-weight: 500;
	border: 1.1px solid #0c5850;
	border-radius: 20px;
	height: 32px;
	padding: 1px 6px;

	&:hover {
		background-color: #f1f1f1;
	}
}
