@import "styles/common.module.scss";

.projectTitle {
	padding: 3px 106px 3px 16px;
	background: white;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	position: fixed;
	left: 70px;
	top: 72px;
	height: fit-content;
	z-index: 3;
	width: 100%;
	margin: 0 0 20px 0;

	@media (max-width: 1024px) {
		gap: 10px;
		position: relative;
		top: 0;
		left: 0;
		padding: 3px 16px;
	}

	@media (max-width: 768px) {
		flex-direction: column;
	}

	.titleContainer {
		display: flex;
		flex-direction: row;
		width: 50%;
		margin-right: 6px;
		align-items: center;
		height: 40px;

		&__text {
			font-family: "Inter", sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 22px;
			line-height: 30px;
			color: #000000;

			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 100%;
		}
	}

	.buttonContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		gap: 8px;

		@media (max-width: 1024px) {
			justify-content: flex-start;
			gap: 10px;
		}

		.syncInfo {
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 20px;
			color: rgba(0, 0, 0, 0.5);
			margin-right: 20px;
		}

		.downloadButton,
		.shareButton {
			font-family: "Inter", sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
			text-align: center;
			color: #2c3030;

			cursor: pointer;

			border: 1px solid #eeedec;
			border-radius: 4px;
			max-height: 32px;
			//min-width: 138px;
			padding: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;

			@media (max-width: 1124px) {
				min-width: max-content;
			}
		}
	}
}

.editProject {
	display: flex;
	margin-left: 15px;
	width: auto;

	height: 32px;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	gap: 4px;

	color: var(--Brand-Neutral-Grey-800, #2c3030);
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px; /* 138.462% */

	border-radius: 4px;
	border: 1px solid var(--System-Silver-Chalice-100, #eeedec);

	&:hover {
		cursor: pointer;
	}
}
