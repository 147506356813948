@import "styles/variables.module.scss";
@import "styles/common.module.scss";

.starter {
	cursor: default;
	color: #a39c9a;

	svg {
		path {
			fill: #a39c9a;
		}
	}
}

.user_img {
	border-radius: 50px;
	border: 1px solid grey;
	width: 24px;
	height: 24px;
	object-fit: contain;
	padding: 4px;
}

@media print {
	.header {
		display: block !important;
		position: absolute;
		top: 0;
		left: 65px;
		overflow: hidden;
	}

	@page :first {
		.header {
			display: none !important;
		}
	}
}

.progress {
	left: 1px;
	bottom: 1px;
	position: absolute;

	&__mini {
		border-radius: 0 !important;
	}
}

.settingsGear {
	display: none;

	@media screen and (max-width: 1024px) {
		display: flex;
		justify-content: flex-end;
		position: absolute;
		right: 10px;
		top: 10px;
		z-index: 30;
	}
}

.shrinkNav {
	width: calc(100% - 230px) !important;
	margin-left: 230px !important;
}

.navbar {
	z-index: 4;
	width: calc(100% - 50px);
	margin-left: 50px;
	height: 72px;
	padding: 20px;
	display: flex;
	position: fixed;
	align-items: center;
	flex-direction: row;
	background-color: #fff;
	transition-duration: 0.3s;
	justify-content: space-between;
	border-bottom: 1px solid #e9e9e9;

	@media print {
		display: none !important;
	}

	@media screen and (max-width: 1024px) {
		visibility: hidden !important;
	}

	&.expanded {
		@media screen and (max-width: 1024px) {
			visibility: visible !important;
			display: flex;
			position: absolute;
			z-index: 10;
			transition: height 1s linear;
			padding: 15px;
			width: 270px;
			border-radius: 5px;
			justify-content: flex-end;
			top: 40px;
			right: 13px;
			height: 225px;
			box-shadow: -2px 4px 11px 0px #f1f1f1;
		}
	}

	&__is {
		&_show {
			padding: 16px 20px;
			align-items: flex-start;

			@media (max-width: 950px) {
				height: unset;
			}
		}

		&_z_index {
			z-index: 2;

			&_max {
				z-index: 30;
			}
		}
	}

	@media (max-width: 1024px) {
		height: unset;
		padding: 16px 24px;
		align-items: flex-start;
	}

	&__logo {
		border: 0px;
		cursor: pointer;
		font-size: 14px;
		height: 40px;
		font-weight: 500;
		line-height: 17px;
		flex-direction: row;
		background: transparent;
		display: none;

		@media screen and (min-width: 1025px) {
			display: flex;
		}
	}
}

.list__width {
	width: 292px;
	max-width: unset !important;

	@media (max-width: 767px) {
		width: fit-content;
	}
}

.project__width {
	width: 100%;
	min-width: 280px !important;
	max-width: 280px !important;
	justify-content: space-between;

	@media (max-width: 1024px) {
		padding: 8px 1px;
		min-width: unset !important;
		max-width: unset !important;
	}

	div {
		h3 {
			overflow: hidden;
			max-width: 215px;
			white-space: nowrap;
			text-overflow: ellipsis;
			padding-left: 10px;

			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: #a39c9a;

			@media (max-width: 1024px) {
				max-width: 185px;
			}
		}
	}
}

.right_block {
	margin: 0;
	display: flex;
	align-items: center;
	// composes: containerMain;
	justify-content: space-between;

	&__is_view {
		@media (max-width: 1024px) {
			gap: 8px;
			padding: 0;
			align-items: flex-end;
			flex-direction: column-reverse;
		}
	}

	@media (max-width: 1024px) {
		gap: 8px;
		padding: 0;
		align-items: flex-end;
		flex-direction: column-reverse;
	}

	&__left_part {
		display: flex;
		font-size: 16px;
		font-weight: 400;
		color: #404040;
		max-height: 40px;
		line-height: 24px;
		margin-right: 16px;
		align-items: center;

		&__is_show {
			@media (max-width: 1024px) {
				margin-right: 0;
			}
		}

		@media (max-width: 1024px) {
			margin-right: 0;
			flex-direction: column;
			align-items: flex-end;
			gap: 8px;
		}

		&__exit {
			border: 1px solid #e9e9e9;
			display: flex;
			align-tems: center;
			border-width: 1px 1px 1px 0;
			padding: 8px 10px;
			cursor: pointer;
		}

		&__noProject {
			height: 42px;
			cursor: default;
		}

		&__hasProject {
			height: 42px;
			cursor: pointer;
		}

		&__view {
			height: 42px;
			display: none;
			font-size: 16px;
			font-weight: 400;
			color: #404040;
			line-height: 24px;
			align-items: center;
			font-family: "Inter", sans-serif;
			padding: 8px 16px !important;
			border: 1px solid #e9e9e9 !important;
			border-width: 1px 0px 1px 1px !important;
			width: 52px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 100%;

			@media screen and (min-width: 1025px) {
				display: flex;
			}

			&__icon {
				svg {
					width: 24px;
					height: 24px;
					display: flex;
					margin-right: 8px;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}

	&__notification {
		margin: 0 16px 0 0;
		border-radius: 4px;
		padding: 8px !important;
		border: 1px solid #e9e9e9 !important;
	}
}

.targetList {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	padding-right: 10px;
}

.projectList {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	margin-left: 20px;
}

.link {
	cursor: pointer;
	color: #3c3c3c;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.4px;
	font-weight: 500;
	margin: 0 13px;
	opacity: 1;
}

.projectCard {
	position: absolute;
	width: 200px;
	height: 54px;
	top: 48px;
	right: 60px;
	padding: 13px 15px 10px 25px;
	font-size: 0.875rem;
	font-weight: bold;
	background: #ffffff;
	border: 1px solid rgba(163, 156, 154, 0.5);
}

.toggle {
	transition: all 0.85s ease;
}

.toggle[aria-expanded="true"] {
	transform: rotate(180deg);
}

.toastBlock {
	display: flex;
	flex-direction: column;
	padding: 16px;

	.toastTitle {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		color: $mainThemeColor;
	}

	.toastDescription {
		margin-top: 3px;
		font-weight: 400;
		font-size: 13px !important;
		line-height: 20px;
	}

	.toastLink {
		color: $mainThemeColor;
		font-weight: 400;

		&:hover {
			cursor: pointer;
		}
	}
}

.btn_style {
	p {
		margin: 0;
	}
}

.container {
	min-width: 248px;
	max-width: 248px;
	margin-top: -320px;
	margin-right: -230px;
}

.user_icon {
	svg {
		border: 1px solid #cbc5c2;
		padding: 2px;
		border-radius: 50%;
	}
}

.user_three_dots {
	margin-left: 5px;
	padding-right: 2px;
}

.creditInfo {
	color: var(--brand-neutral-grey-800, #2c3030);

	/* Paragraph L/Semi Bold */
	font-family: "Inter", sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px; /* 155.556% */
}

.startNewSearch {
	position: relative;

	&__button {
		position: relative;

		&__btn {
			min-width: 175px;
			display: flex;
			height: 40px;
			padding: 8px 16px;
			align-items: center;
			gap: 8px;
			border-radius: 4px;
			border: #0c5850;
			background: #0c5850;
			cursor: default;
			color: #edf2f2;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px;

			@media screen and (min-width: 1025px) {
				margin-right: 10px;
			}

			&:hover {
				cursor: pointer;
			}

			&_z_index {
				z-index: 33;
				position: relative;
			}
		}

		&__boarding {
			top: 55px;
			left: -160px;
		}
	}

	&__onboarding {
		top: 0;
		left: -130px;
		width: 336px;
	}
}

.previewIconToolTip {
	padding: 2px 8px;
}
