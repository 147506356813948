.card {
	padding: 20px 25px;
	background-color: #ffffff;
	border: 1px solid #d1cdcc;
	box-shadow: 0px 20px 60px rgba(241, 244, 248, 0.5);
	border-radius: 4px;
	margin-bottom: 20px;
}

.cardTitle {
	margin-bottom: 20px;
	font-weight: 600;
	font-size: 18px;
	color: #271b00;
	display: flex;
	justify-content: space-between;

	&.nonExecutive {
		margin-top: 35px;
	}
}

.center {
	text-align: center;
}

.showMore {
	user-select: none;
	color: #0c5850;
	font-size: 14px;
	cursor: pointer;
	display: inline-block;
	margin-bottom: 10px;

	&:hover {
		text-decoration: underline;
	}

	@media print {
		display: none !important;
	}
}

.expandButton {
	color: #404040;
	width: 140px;
	padding: 7px 0;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border: 1px solid #0c5850;
	border-radius: 4px;
	cursor: pointer;

	span {
		margin-left: 5px;
	}

	@media print {
		display: none !important;
	}
}

.experienceList {
	background-color: #ffffff;
}
