.wrapper {
	position: relative;
	gap: 24px;
	display: flex;
	flex-direction: column;
}

.header {
	z-index: 3;
	position: fixed;
	top: 65px;
	left: 70px;
	width: calc(100% - 70px);
	background-color: white;
	display: flex;
	padding: 16px 16px 12px 16px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	border-bottom: 1px solid var(--System-Silver-Chalice-100, #eeedec);

	h1 {
		overflow: hidden;
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		text-overflow: ellipsis;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
	}

	.editButton {
		display: flex;
		padding: 6px 8px;
		justify-content: center;
		align-items: center;
		gap: 6px;
		color: var(--Brand-Neutral-Grey-600-B, #4e5555);
		font-size: 13px;
		font-style: normal;
		font-weight: 600;
		line-height: 18px;
		border-radius: 4px;
		border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
		background: transparent;
		white-space: nowrap;
		width: fit-content;
	}
}

.footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 32px 24px;
	background: #ffffff;
	border-top: 1px solid #eeedec;
	margin-top: -24px;

	.estimatedCount {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 4px;
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 28px; /* 155.556% */

		.count {
			color: var(--Brand-Neutral-Grey-600-B, #4e5555);
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 28px;
		}
	}
}

.addFromTLButton {
	margin-top: 16px;
	display: flex;
	padding: 6px 8px;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 4px;
	flex: 1 0 0;
	background: #ffffff;
	border-radius: 100px;
	border: 1px solid var(--Brand-Primary-600-B, #0c5850);
	color: #0b5850;
}
