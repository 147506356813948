.tag {
	border-radius: 3px;
	width: auto;
	height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
	transition: opacity 0.5s;
	opacity: 1;
	gap: 5px;
	cursor: pointer;

	background: #ffffff;
	border: 1px solid #f1f1f1;

	.infoBlock {
		display: flex;
		flex-direction: column;
		justify-content: center;
		//height: 30px;
		align-items: flex-start;
	}

	.avatar {
		height: 30px;
		border-radius: 30px;
	}
}

.tagText {
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: #404040;
}
