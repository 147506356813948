.skeletonContainer {
	&[data-tooltip]:before {
		/* needed - do not touch */
		content: attr(data-tooltip);
		position: absolute;
		opacity: 0;

		/* customizable */
		transition: all 0.15s ease;
		padding: 10px;
		color: #333;
		border-radius: 3px;
		box-shadow: 2px 2px 1px #f1f1f1;
	}

	&[data-tooltip]:hover:before {
		/* needed - do not touch */
		opacity: 1;
		background: #fff;
		border: 1px solid #f3f3f3;
		margin-top: -50px;
		margin-left: 20px;
		z-index: 2;
	}

	&__card {
		background: #fff;
		border: 1px solid rgba(209, 205, 204, 1);
		margin-bottom: 20px;
		padding: 20px;

		&.linkedIn {
			border: 1px solid #0b6cac;
			position: relative;

			.linkedInIcon {
				position: absolute;
				top: -4px;
				left: -4px;
			}
		}
	}
}
