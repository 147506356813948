@import "styles/common.module.scss";

.specialities {
	display: flex;
	flex-direction: column;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}

	//@media screen and (max-width: 1024px) {
	//	margin-bottom: 40px;
	//}
}

.title {
	color: var(--Brand-Neutral-Grey-800, #2c3030);
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 133.333% */
}

.specList {
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
}
