.filterBy {
	z-index: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
	margin-top: 16px;
	margin-bottom: -2px;
	border-radius: 4px 4px 0 0;
	border: 1px solid #dad8d7;
	border-bottom: none;
	width: max-content;

	.filterType {
		height: 36px;
		//border: 1px solid #cbc5c2;
		padding: 4px 15px;
		display: flex;
		align-items: center;
		border-bottom: none;
		cursor: pointer;
		background: #dad8d7;
		color: #868d8d;

		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px; /* 142.857% */

		&:first-child {
			border-right: none;
			padding: 4px 12px;
		}

		.filterLabel {
			span {
				background: #fff;
				padding: 4px 6px;
				border-radius: 4px;
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px;
			}
		}

		&.active {
			color: #2c3030;
			background: #ffffff;
			//border-bottom: 1px solid rgba(163, 156, 154, 0.5);

			.filterLabel {
				span {
					background: #dad8d7;
					color: #868d8d;
				}
			}
		}

		span {
			pointer-events: none;
		}
	}

	.filters {
		display: flex;
		flex-direction: row;
	}
}

.filtersContent {
	display: flex;
	padding: 8px 8px 16px 8px;
	flex-direction: column;
	justify-content: center;
	gap: 16px;

	border-radius: 0px 4px 4px 4px;
	border: 1px solid var(--system-silver-chalice-200, #dad8d7);
}
