.reportContainer {
	line-height: 1;
	overflow: hidden;

	h3 {
		font-size: 17px;
		font-weight: 500;
	}

	p {
		font-size: 13px;
	}

	hr {
		width: 520px;
		margin-left: -20px;
		border: 0.8px solid #eeeeee;
	}

	.reportBox {
		border: 1px solid #dedede;
		border-radius: 6px;
		padding: 0 10px;
		overflow: hidden auto;
		height: 350px;
		scrollbar-width: thin;

		.reportItems {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.header {
		display: flex;
		gap: 5px;
		align-items: center;
	}

	.successCount {
		margin-left: 3px;
		line-height: 1.3;
	}

	.errorCount {
		margin-left: 3px;
		line-height: 1.3;
	}

	.statuses {
		display: flex;
		justify-content: flex-start;
		font-size: 13px;
	}

	.status {
		margin-left: 5px;
	}

	.warn {
		font-size: 18px;
		text-align: center;
	}
}

.toolTip {
	line-height: 1;
	background-color: white;
	padding: 1px 15px;
	color: #404040;
	border-radius: 6px;
	border: 1px solid #d9d9d9;
}

.progress_style {
	height: 3px;
	margin: 0 8px;
	background-color: #f4f0ed;
	appearance: none;
	border: none;
}

.downloadReport {
	margin: 15px 15px 5px 10px;
	color: #4f4f4f;
	font-size: 13px;
	font-weight: 600;
	cursor: pointer;
	display: flex;
	justify-content: space-between;

	span {
		margin-left: 5px;
		margin-top: 2px;
	}
}

.downloadAllReport {
	display: flex;
	justify-content: center;
	align-content: center;

	span {
		margin-left: 5px;
		margin-top: -1px;
	}
}

.disabled {
	color: #bdbdbd;
	cursor: not-allowed;
}

.addButton {
	margin-top: 15px;
}

.refresh {
	cursor: pointer;
	animation: spin 2s infinite linear;
	pointer-events: none;
}

@keyframes spin {
	100% {
		transform: rotate(0deg);
	}

	0% {
		transform: rotate(360deg);
	}
}
