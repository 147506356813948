@import "styles/common.module.scss";

.buttonDesign {
	background: $toggleButtonBackground;
	border-radius: 3px;
	border: none;
	color: $toggleButtonText;
	padding: 0.5rem 0.7rem 0.5rem 0.7rem;
	margin-right: 0.4rem;
	margin-top: 0.6rem;
	cursor: pointer;
	transition: opacity 0.3s;
	opacity: 1;
	font-weight: 400;
	font-size: $toggleButtonFontSize;
	line-height: 16px;
	font-family: "Inter", sans-serif;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		margin-right: 5px;

		path {
			stroke: #0c5850;
		}
	}

	@media print {
		display: none !important;
	}
}

.hide {
	opacity: 0;
	pointer-events: none;
}
