.content {
	padding: 16px;
}

.description {
	margin: 16px 0;
}

.button {
	width: 100%;
	height: 48px;
	background-color: #0c5850;
	margin-top: 16px;
}

.row {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.line {
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #f4f0ed;
	margin: 16px 0;
}

.global {
	color: #271b00;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	margin-left: 8px;
	user-select: none;
}

.select {
	margin-left: 8px;
	width: 120px;
}

.label {
	margin-bottom: 6px;
	color: #a1a1a1;
	text-transform: capitalize;
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	user-select: none;
	display: block;
}

.message {
	margin: 16px 0 0 0;
}

.companies {
	.title {
		display: flex;
		align-items: center;
		margin-top: 16px;
	}
	.removeAll {
		display: flex;
		align-items: center;
		margin-left: auto;
		color: #4e5555;
		font-weight: 500;
		font-size: 12px;
		cursor: pointer;

		svg path {
			fill: #4e5555;
		}
	}
	.count {
		margin-left: 8px;
		padding: 4px;
		// width: 20px;
		height: 20px;
		background: #f4f0ed;
		border-radius: 4px;
		font-weight: 600;
		font-size: 12px;
		line-height: 20px;
		color: #404040;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.empty,
.list {
	max-height: 200px;
	min-height: 200px;
	overflow: auto;
}

.empty {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 300;
	font-size: 13px;
	line-height: 22px;
	color: #a1a1a1;
	user-select: none;
}

.company {
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: 16px;
	}

	.logo {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		user-select: none;
		pointer-events: none;
		margin-right: 8px;
	}

	.name {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: #404040;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.delete {
		margin: 0 0 0 auto;
		cursor: pointer;
	}
}
