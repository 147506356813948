@import "styles/common.module.scss";

.statisticsWrapper {
	display: flex;
	flex-direction: column;
	min-width: 95px;
	align-items: flex-start;
	flex-wrap: nowrap;
}

.indexIcon,
.employeeIcon {
	margin-right: 1rem;

	@media screen and (max-width: 1024px) {
		margin-right: 0.1rem;
	}
}

.indexTitle {
	color: $secondaryTextColor;
}

.indexStat,
.employeeStat {
	margin-bottom: 1rem;
	display: flex;
	gap: 8px;
}

.indexTitle,
.employeeTitle {
	font-size: 0.95rem;
	line-height: 20px;

	@media screen and (max-width: 1024px) {
		font-size: 13px;
	}
}
