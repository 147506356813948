.suggestionItem {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 8px 5px;
	cursor: pointer;

	&:hover {
		background-color: #fafbfb;
	}
}

.personContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	.image {
		display: flex;
		align-items: center;

		img {
			width: 35px;
			//height: 32px;
			border-radius: 100px;
			border: 1px solid #d1cdcc;
		}
	}

	.info {
		white-space: break-spaces;
		display: flex;
		flex-direction: column;

		h4 {
			margin: 0;
			font-size: 14px;
			line-height: 18px;
			font-weight: 500;
		}

		span {
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			color: #808080;
		}

		.companyName {
			font-weight: 500;
			color: #5a5a5a;
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
}
