@import "styles/variables.module.scss";

.filter_container {
	@media (max-width: 1025px) {
		max-width: 347px;
	}

	@media (max-width: 950px) {
		max-width: 300px;
	}

	@media (max-width: 850px) {
		max-width: 250px;
	}
}

.filterBlocksWrapper {
	display: flex;
	gap: 7px;
	flex-direction: column;
	width: 384px;
	min-height: 500px;
	flex-shrink: 0;
	position: sticky;
	top: 150px;

	@media screen and (max-width: 1024px) {
		width: 100%;
		max-width: 100%;
	}

	@media screen and (max-width: 767px) {
		min-height: auto;
	}

	.additionalProfilesFilter,
	.rejectedProfilesFilter {
		@media screen and (max-width: 767px) {
			display: none;
		}
	}
}

.filterBlock {
	display: flex;
	flex-direction: column;
	//border-radius: 5px;
	background: #fff;
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
	//width: 320px;
}

//.headerSection {
//	position: relative;
//	padding: 13px 17px;
//	display: flex;
//	justify-items: center;
//	justify-content: space-between;
//	gap: 8px;
//
//	.filterTitle {
//		flex-grow: 10;
//		//color: #181B1A;
//		display: flex;
//		align-items: center;
//		gap: 12px;
//	}
//
//	&.opened {
//		background: var(--System-Silver-Chalice-100, #eeedec);
//	}
//
//	.titleLabel {
//		gap: 8px;
//		display: flex;
//		flex-direction: row;
//		align-items: center;
//		justify-content: flex-start;
//		flex-basis: max-content;
//	}
//}

.searchInputWrapper {
	display: flex;
	flex-direction: row;
	margin: 16px 0;
	gap: 8px;

	.marketMap {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		min-width: 122px;
		height: 38px;
		background: #ffffff;
		padding: 0;

		color: #0c5850;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
	}

	.searchBox {
		border: 1px solid rgba(163, 156, 154, 0.5);
		box-sizing: border-box;
		border-radius: 3px;
		padding-right: 10px !important;
	}
}

.aiCreditInformation {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 16px;
	gap: 8px;
}

.creditsInformation {
	background: #e5e6e6;
	border-radius: 4px;
	padding: 2px 6px;
	font-weight: 600;
	font-size: 12px;
	line-height: 20px;
}

.resetFilterContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-bottom: 16px;
	padding-top: 6px;

	.resetArea {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		//gap: 19px;
	}
}

.selectAll {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	color: #5a5a5a;

	&:hover {
		cursor: pointer;
	}
}

.sortable {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #0c5850;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
}

.buttonBlock {
	display: flex;
	flex-direction: column;
	padding: 13px 17px;
	width: 100%;

	.infoText {
		font-weight: 400;
		font-size: 13px;
		line-height: 20px;
		//margin-left: 7px;

		span {
			font-weight: 500;
		}
	}

	.actionButton {
		margin-top: 12px;
		width: 100%;
		display: flex;
		//align-items: center;
		//justify-content: center;
		flex-direction: column;
	}

	.lunchButton {
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 600;
		font-size: 14px;
		line-height: 22px;
		color: #ffffff;
		width: 100%;

		&__out_of_credit {
			background: #a39c9a !important;
			border: 1px solid #a39c9a !important;
		}
	}
}

.checkboxList {
	display: inline-flex;
	flex-direction: column;
	padding-right: 0;
	width: 100%;
	max-height: 280px;
	overflow: auto;
}

.personContainer {
	min-height: auto;
	padding-left: 25px;
	margin: 8px 0;

	&:first-child {
		margin-top: 0;
	}
}

.tooltip {
	background-color: #ffffff;
	box-shadow: 0 0 31px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	opacity: 1;
	width: 280px;
	padding: 16px;
	z-index: 1000;

	@media (max-width: 753px) {
		left: 40% !important;
	}
}

.checkboxContainer {
	margin-bottom: 12px;

	.labelContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex: 1;
		align-items: center;

		.labelTitle {
			max-width: 254px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 8px;

			.image {
				img {
					height: 20px;
					width: 20px;
					border-radius: 2px;
				}
			}
		}

		.labelIconContainer {
			font-size: 14px;
			line-height: 22px;
			color: #808080;
			gap: 6px;
		}
	}
}

.container {
	width: 384px;

	@media screen and (max-width: 1024px) {
		width: 100%;
		max-width: 100%;
	}

	@media screen and (max-width: 767px) {
		min-height: auto;
	}
}

.aiWebSearch {
	display: flex;
	padding: 16px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;

	border-radius: 4px 4px 0px 0px;
	background: var(--system-bw-white, #fff);
	border-bottom: 1px solid var(--System-Silver-Chalice-100, #eeedec);

	.title {
		display: flex;
		align-items: center;
		color: var(--Brand-Primary-600-B, #0c5850);
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 26px; /* 144.444% */
		gap: 8px;

		svg {
			fill: #0c5850;
			color: #0c5850;
		}
	}
}
