.container {
	gap: 24px;
	width: 100%;
	display: flex;
	max-width: 420px;
	flex-direction: column;

	&__message {
		max-width: 100%;
		margin-bottom: 0 !important;
	}

	&__wrapper {
		position: relative;
	}

	&__button {
		width: 100%;
		z-index: 2;
		border: none;
		display: block;
		font-size: 18px;
		font-weight: 600;
		color: #ffffff;
		padding: 8px 24px;
		line-height: 26px;
		position: relative;
		text-align: center;
		border-radius: 4px;
		margin: 32px auto 0;
		background: #0c5850;
		font-family: "Poppins";
		box-shadow: 0px 8px 8px #e5f3f1;
	}

	&__error {
		display: block;
		font-size: 14px;
		color: #d94222;
		font-weight: 600;
		line-height: 24px;
		font-family: "Inter";
	}

	&__backend_err {
		display: block;
		font-size: 16px;
		color: #d94222;
		font-weight: 600;
		line-height: 24px;
		font-family: "Inter";
	}
}

.input {
	background-color: white;
}
