body.react-confirm-alert-body-element {
	overflow: hidden;
}

.react-confirm-alert-blur {
	filter: blur(2px);
	-webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background: rgba(255, 255, 255, 0.9);
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
	opacity: 0;
	-webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	-moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	-o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
	animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
	font-family: "Inter", sans-serif;
	width: 400px;
	padding: 30px;
	text-align: left;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 30px;
	color: #4e5555;
}

.react-confirm-alert-svg {
	position: absolute;
	top: 0;
	left: 0;
}

.react-confirm-alert-icon {
	margin-bottom: 14px;
}

.react-confirm-alert-body > h1 {
	margin-top: 0;
}

.react-confirm-alert-body > h3 {
	margin: 0;
	font-size: 16px;
}

.react-confirm-alert-button-group {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;
}

.react-confirm-alert-button-group > button {
	outline: none;
	background: #fff;
	border: 1px solid #dedede;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 48px;
	min-width: 160px;
	border-radius: 4px;

	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	color: #ffffff !important;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-moz-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-o-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.react-confirm-alert-overlay {
	z-index: 101;
	border-radius: 0;

	.react-confirm-alert-body {
		border-radius: 0;

		h1 {
			font-size: 16px;
			line-height: 24px;
			font-weight: 500;
			margin-bottom: 16px;
			text-align: center;
		}

		p {
			font-weight: 500;
			font-size: 20px;
			line-height: 28px;
			color: #2c3030;
			margin: 0;
		}

		.react-confirm-alert-button-group {
			display: flex;
			gap: 16px;

			button {
				background: #808080;

				&.btn-red {
					border: 1px solid #c91515 !important;
					background: #c91515 !important;
				}

				svg {
					margin-right: 8px;
				}
			}
		}
	}
}

.btn-green {
	background: #0c5850 !important;
	border: 1px solid #0c5850 !important;
}
