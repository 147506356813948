@import "styles/common.module.scss";

.moreButton {
	background: $infoButtonBackground;
	height: $infoButtonHeight;
	font-size: $infoButtonFontSize;
	border-radius: 3px;
	border: 0px;
	color: #0c5850;
	padding: 0.5rem 0.7rem;
	cursor: pointer;
	transition: opacity 0.3s;
	opacity: 1;
	margin: 0 0.4em 0.4em 0;
	padding: 5px;
}

.hide {
	opacity: 0;
	pointer-events: none;
}
