.arrowWrapper {
	width: 16px;
	height: 16px;

	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
}

.arrow {
	cursor: pointer;
	margin-left: 0;
	background: #eeedec;
	border-radius: 3px;

	&:has(.rotated) {
		background: #cbc5c2;
	}

	& > svg {
		transition: transform 0.2s linear;

		&.rotated {
			transform: rotateX(180deg);
		}
	}
}
