.watch {
	gap: 8px;
	padding: 8px;
	display: flex;
	border: none;
	outline: none;
	cursor: pointer;
	color: transparent;
	border-radius: 4px;
	align-items: center;
	background: #493c70;
	justify-content: center;

	&__white {
		background: #eeecf5;
		color: #493c70;

		svg {
			path {
				fill: #493c70 !important;
			}
		}
	}

	&__icon {
		height: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	svg {
		path {
			fill: #eeecf5;
		}
	}

	&__text {
		margin: 0;
		font-size: 14px;
		font-weight: 600;
		color: #eeecf5;
		line-height: 22px;
		animation: fadeIn 0.4s;
		min-width: max-content;
	}

	&__hovered {
		padding: 8px 27px 8px 10.5px;
	}
}
