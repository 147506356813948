.container {
	display: flex;
	padding: 48px;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	align-self: stretch;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
}

.inputWrapper {
	width: 492px;
}

.inputContainer {
	border-radius: 4px;
	border: 1px solid var(--Brand-Primary-600-B, #dad8d7);
	background: var(--system-bw-white, #fff);

	display: flex;
	padding: 16px;
	align-items: center;
	gap: 16px;
	align-self: stretch;

	&.selected {
		border: 1px solid var(--Brand-Primary-600-B, #0c5850);
	}

	.labelClass {
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 28px; /* 155.556% */

		&:before,
		&:after {
			top: 4px;
		}
	}
}

.dropdownItem {
	display: flex;
	flex-direction: row;
	gap: 8px;

	img {
		height: 24px;
		border-radius: 50px;
	}

	span {
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
	}
}
