$texboxHeight: 40px;
$actionHeight: 50px;

.container {
	position: relative;
	height: $texboxHeight;
	z-index: 1;
	flex-shrink: 0;
	width: 200px;
}

.select {
	position: relative;
	user-select: none;
	border: 1px solid #cbc5c2;
	background-color: #fff;
	border-radius: 3px;
	height: $texboxHeight;
	display: flex;
	align-items: center;
	flex-direction: column;
	// left: 0;
	// right: 0;

	&.expanded {
		border-color: #0c5850;
		height: auto;
		position: absolute;
		left: 0;
		right: 0;
		top: -1px;
	}
}

.textbox {
	align-items: center;
	background-color: transparent;
	border: none;
	color: #404040;
	cursor: pointer;
	display: flex;
	font-size: 14px;
	font-weight: 500;
	height: $texboxHeight;
	justify-content: space-between;
	line-height: 22px;
	opacity: 0.5;
	outline: none;
	padding: 0 10px;
	resize: none;
	width: 100%;

	.count {
		align-items: center;
		background: #f4f0ed;
		border-radius: 4px;
		display: flex;
		font-size: 12px;
		font-weight: 600;
		height: 24px;
		justify-content: center;
		margin-left: 8px;
		margin-right: auto;
		width: 24px;
	}

	&.expanded {
		svg {
			transform: rotateX(180deg);
		}
	}

	&.data {
		color: #404040;
		opacity: 1;
	}

	&.editable {
		user-select: text;
	}
}

.dropdown {
	display: none;
	max-height: 252px;
	width: 100%;

	&.withAction {
		margin-bottom: $actionHeight;
	}

	&.expanded {
		display: block;
	}
}

.option {
	cursor: pointer;
	padding: 8px 10px;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: #404040;
	display: flex;
	align-items: center;

	&:hover {
		background-color: #eeeeee;
	}

	.checkbox {
		pointer-events: none;
	}
}

.action {
	padding: 8px 10px;
	height: $actionHeight;
	box-sizing: border-box;
	background-color: #fff;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.group {
	display: flex;
	flex-direction: column-reverse;
}
