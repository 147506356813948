.instructionContainer {
	background: #fff;
	padding: 32px;
	filter: drop-shadow(0px 25px 40px rgba(79, 75, 74, 0.08));
	flex: 1;
}

.instructionWrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.instructionTitle {
	color: var(--brand-neutral-grey-800, #2c3030);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px; /* 155.556% */
	display: flex;
	align-items: center;
	gap: 8px;
}

.instructionDesc {
	color: var(--brand-neutral-grey-600-b, #4e5555);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
}

.requiredInstruction {
	color: var(--brand-neutral-grey-400, #868d8d);
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 16px; /* 133.333% */
}

.learnButton {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	height: 40px;
	padding: 0 16px;
	border-radius: 4px;
	background: #fff;
	border: 1px solid var(--system-silver-chalice-200, #dad8d7);
	color: var(--brand-neutral-grey-600-b, #4e5555);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px; /* 157.143% */
	max-width: 140px;

	&:hover {
		cursor: pointer;
	}
}
