.table {
	margin: 0;
	table-layout: auto;
	border-collapse: collapse;
	width: 100%;

	padding: 0 10px;
	// height: 100%;
	background: #ffffff;
	border-radius: 5px;
}

.table tr,
.table td,
.table th {
	padding: 15px;
}

.table th {
	user-select: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&.sortable {
		cursor: pointer;
	}
}

.table tr {
	border: 2px solid #bebebe;
}

.table thead tr th:nth-child(3) {
	@media (max-width: 1800px) {
		width: 120px;
	}
}

.table tr td {
	font-size: 14px;
	line-height: 20px;

	&:nth-child(2) {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
	}

	&:first-child {
		font-weight: 500;
	}

	.cell {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}

.arrow {
	visibility: hidden;

	&.desc {
		visibility: visible;
		transform: rotateX(180deg);
	}

	&.asc {
		visibility: visible;
	}
}
