.container {
	user-select: none;
	margin-bottom: 10px;
	color: #0c5850;
	display: flex;
	align-items: center;
}

.resetFilters {
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 12px;
	font-weight: 500;
	gap: 5px;
}
