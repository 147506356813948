.animation {
	top: 44%;
	left: 32%;
	z-index: 32;
	display: flex;
	height: 270px;
	position: fixed;
	width: fit-content;
	align-items: center;
	justify-content: center;

	&__bg {
		top: 0;
		left: 0;
		right: 0;
		z-index: 32;
		width: 100%;
		height: 100%;
		position: fixed;
		background: white;
	}

	&__block {
		z-index: 38;
		width: 200px;
		height: 100px;
		position: absolute;
		margin-top: -200px;
		margin-left: -140px;
		background-color: #fff;
	}

	&__map {
		animation: slide-right 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
		animation-delay: 0.6s;
		margin-top: -200px;
		transform: translateX(-55px);
		position: relative;
		z-index: 37;
	}

	&__logo_x {
		animation: slide-top 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
		position: relative;
		z-index: 37;
	}

	&__logo_savannah {
		position: absolute;
		right: -155px;
		top: 0;
		animation: slide-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
		animation-delay: 1.2s;
		z-index: 34;
	}

	&__savannah_block {
		width: 175px;
		height: 80px;
		background-color: #fff;
		position: absolute;
		right: -155px;
		top: 0;
		z-index: 36;
	}
}

@keyframes slide-top {
	0% {
		transform: translateX(0) translateY(0);
		opacity: 0;
	}
	50% {
		transform: translateY(-100px) translateX(0);
		opacity: 1;
	}
	100% {
		transform: translateX(150px) translateY(-100px);
	}
}

@keyframes slide-right {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(155px);
	}
}

@keyframes slide-bottom {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(80px);
	}
}
