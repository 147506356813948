.cover {
	width: 100%;
	flex-grow: 1;
	min-width: 800px;
	max-width: 828px;
	top: 0;
	flex-direction: column;
	height: 100%;
	border-radius: 8px;
	background: linear-gradient(180deg, #f9efe3 52.47%, #fcf7f0 78.71%);
	padding: 2rem 5rem;
	display: none;
	position: relative;

	@media only screen and (min-width: 992px) {
		display: flex;
	}

	.topHeader {
		position: absolute;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		margin-left: -80px;
		//position: relative;

		.logo {
			position: relative;
			top: 0px;
			left: 80px;
		}

		.headerShapes {
			position: relative;
			right: 0;
			top: -2.2rem;
		}
	}

	.contentArea {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		align-content: center;
		width: 100%;
		margin-top: 3rem;
		margin-bottom: 7rem;
		height: 100vh;

		.title {
			color: var(--brand-neutral-grey-800, #2c3030);
			text-align: center;

			/* H2/Semi Bold */
			font-family: Inter, sans-serif;
			font-size: 50px;
			font-style: normal;
			font-weight: 600;
			line-height: 72px; /* 120% */
			//margin-top: 2.2rem;

			& span {
				color: var(--brand-primary-600-b, #0c5850);
			}
		}

		.description {
			color: var(--brand-neutral-grey-800, #2c3030);

			/* H6/Medium */
			font-family: Inter, sans-serif;
			font-size: 24px;
			font-style: normal;
			font-weight: 500;
			line-height: 32px; /* 133.333% */

			margin-top: 1.8rem;
			margin-bottom: 1.5rem;
		}

		.cards {
			display: flex;
			flex-direction: column;
			gap: 20px;
			width: 100%;
			flex-grow: 1;

			.card {
				display: flex;
				padding: 16px;
				align-items: center;
				gap: 24px;
				align-self: stretch;
				border-radius: 8px;
				background: var(--system-bw-white, #fff);
				width: 100%;

				.cardIcon {
				}

				.texts {
					font-family: Inter, sans-serif;
					font-style: normal;

					.iconTitle {
						color: var(--brand-neutral-grey-800, #2c3030);

						/* H6/Semi Bold */
						font-size: 24px;
						font-weight: 600;
						line-height: 32px;
					}

					.iconDescription {
						color: var(--brand-neutral-grey-600-b, #4e5555);
						font-size: 18px;
						font-weight: 500;
						line-height: 28px;
					}
				}
			}
		}
	}

	.footer {
		position: absolute;
		bottom: 0;
		right: 0;
		//margin-right: -5rem;
		margin-left: -5rem;
		width: 100%;

		.footerShape {
			margin-bottom: -5px;
		}

		.clients {
			position: relative;
			//top: -10px;
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: center;
			align-items: center;
			gap: 64px;
			background: #ffffff;
			padding-bottom: 20px;
		}
	}
}
