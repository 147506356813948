.checkboxContainer {
	margin-bottom: 12px;
}

.title {
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 8px;
	color: #868d8d;
}

.checkboxList {
	display: inline-flex;
	flex-direction: column;
	padding-right: 10px;
	width: 100%;
	max-height: 150px;
	overflow: hidden auto;
}

.searchInputWrapper {
	position: relative;

	.floatAddedIcon {
		position: absolute;
		right: 9px;
		top: 8px;
		cursor: pointer;
		z-index: 2;
	}
}

.selectedJobTitles {
	margin-top: 20px;
	margin-bottom: 16px;
	display: flex;
	flex-direction: row;
	gap: 8px;
	width: auto;
	flex-wrap: wrap;
}

.loader {
	display: flex;
	flex-direction: column;
	padding: 50px 113px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	background: rgba(255, 255, 255, 0.8);
	position: absolute;
	margin: -16px;
	top: 40px;
	left: 9px;

	span {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4px;
		flex-shrink: 0;

		color: var(--Brand-Neutral-Grey-600-B, #4e5555);
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px; /* 142.857% */
		min-width: 150px;
	}
}
