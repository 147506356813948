.form {
	display: flex;

	input {
		width: 100%;
		font-size: 12px;
	}
}

.line {
	display: flex;
	align-items: center;
	margin: 0 16px;
}

.submit {
	position: absolute;
	visibility: hidden;
	pointer-events: none;
	z-index: -1;
}
