@import "styles/custom/_mixins.scss";

$texboxHeight: 40px;
$actionHeight: 50px;

.container {
	position: relative;
	height: $texboxHeight;

	&.expanded {
		z-index: 1;
	}
}

.select {
	width: 100%;
	position: relative;
	user-select: none;
	border: 1px solid #dadada;
	background-color: #fff;
	border-radius: 3px;
	overflow: hidden;

	&.expanded {
		border-color: #0c5850;
		position: absolute;
		left: 0;
		right: 0;
	}
}

.textbox {
	background-color: transparent;
	resize: none;
	outline: none;
	cursor: pointer;
	border: none;
	padding: 0 10px;
	height: $texboxHeight;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #404040;
	opacity: 0.5;
	font-size: 14px;
	line-height: 20px;

	svg {
		transform: rotateX(180deg);
		transition: transform 0.2s linear;
	}

	&.expanded {
		svg {
			transform: rotateX(0deg);
		}
	}

	&.data {
		color: #404040;
		opacity: 1;
	}

	&.editable {
		user-select: text;
	}

	> .text {
		max-width: 90%;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		color: var(--Brand-Neutral-Grey-300, #b0b5b4);

		&.selectedText {
			color: var(--Brand-Neutral-Grey-800, #2c3030);
		}

		@include ellipsis();
	}
}

.dropdown {
	display: none;
	overflow-y: scroll;

	&.withAction {
		margin-bottom: $actionHeight;
	}

	&.expanded {
		display: block;
	}
}

.option {
	cursor: pointer;
	padding: 8px 10px;
	font-size: 14px;
	line-height: 20px;

	@include ellipsis();

	&:hover {
		background-color: #eeeeee;
	}
}

.action {
	padding: 8px 10px;
	height: $actionHeight;
	box-sizing: border-box;
	background-color: #fff;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.group {
	display: flex;
	flex-direction: column-reverse;
}
