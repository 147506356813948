.container {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: flex;
	max-width: 100%;
	position: fixed;
	align-items: center;
	justify-content: center;
	background-color: rgba(20, 20, 20, 0.7);
}

.wrapper {
	width: 100%;
	max-width: 400px;
	border-radius: 4px;
	background-color: #fff;

	&__title {
		margin: 0;
		display: flex;
		padding: 16px;
		border-bottom: 1px solid #d1cdcc;
	}

	&__content {
		padding: 16px;

		&_item {
			gap: 6px;
			display: flex;
			margin-bottom: 16px;
			flex-direction: column;

			&_title {
				margin: 0;
				font-size: 14px;
				color: #a2a2a2;
				font-weight: 600;
				line-height: 22px;
			}

			&_upload {
				display: flex;
				position: relative;

				&_input {
					width: 100%;
				}

				&_button {
					right: 0;
					width: 100%;
					height: 100%;
					color: #fff;
					cursor: pointer;
					max-width: 109px;
					position: absolute;
					background-color: #a39c9a;
					border-radius: 0px 4px 4px 0px;

					& input {
						display: none;
					}

					&_content {
						gap: 8px;
						height: 100%;
						display: flex;
						padding: 8px 20px;
						align-items: center;

						& span {
							font-size: 14px;
							font-weight: 600;
							line-height: 24px;
						}
					}
				}
			}
		}

		&_button {
			width: 100%;
			padding: 13px 0;
			font-size: 14px;
			margin-top: 16px;
			font-weight: 600;
			line-height: 22px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
.remove_all {
	margin: 0;
	cursor: pointer;
	color: #0c5850;
	padding: 7px 15px;
	text-align: center;
	border-radius: 4px;
	background-color: #f9f9f9;

	&:hover {
		color: #f9f9f9;
		background-color: #0c5850;
	}
}

.trial {
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: #383838;
	width: 80%;
	outline: none;
	border: none;

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}
}
