.seniorityTooltip {
	width: 383px;
	max-height: 318px;
	padding: 0 18px;
	margin-left: 20px;
	color: #232323;
	background-color: #fff;
	box-shadow: 0 4px 32px rgba(0, 0, 0, 0.18) !important;
	border-radius: 4px;
	z-index: 10;
	line-height: 0.9;

	p {
		font-size: 13px;

		strong {
			display: inline-block;
			//width: 70px;
			text-align: right;
		}

		span {
			display: inline-block;
		}
	}
}

.jobTitleTooltip {
	width: 383px;
	font-weight: 300 !important;
	font-size: 14px !important;
	line-height: 22px !important;
	margin-left: 18px;
	color: #4e5555 !important;
	opacity: 1 !important;
	box-shadow: 0 4px 32px rgba(0, 0, 0, 0.18) !important;
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border-radius: 4px !important;
	padding: 16px;
	z-index: 5;

	b {
		font-weight: 500 !important;
	}
}
