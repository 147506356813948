.default {
	padding: 0;
	border: none;
	cursor: pointer;
	background-color: transparent;
}

.container {
	width: 100%;
	border: none;
	color: #fff;
	cursor: pointer;
	padding: 13px 0;
	position: relative;
	border-radius: 4px;
	background-color: #0c5850;
	height: 40px;
	box-sizing: border-box;
	user-select: none;
	font-size: 14px;
	font-weight: 600;

	&__disabled {
		cursor: not-allowed !important;
		background: #bababa !important;
	}

	&__loader {
		display: flex;
		margin: 0 auto;
		position: absolute;
		justify-content: center;
		width: calc(100% - 48px);

		> div {
			right: unset !important;
		}
	}
}
