.container {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.dropdownItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	img {
		height: 24px;
		border-radius: 2px;
		border: 1px solid var(--System-Silver-Chalice-50, #f8f7f7);
		background: transparent lightgray 50% / cover no-repeat;
	}

	span {
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 14px;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
	}
}

.inputContainer {
	display: flex;
	flex-direction: column;
	position: relative;

	.addButtonWrapper {
		position: absolute;
		display: flex;
		justify-content: flex-end;
		bottom: 3px;
		right: 4px;
		width: max-content;
	}
}

.addButton {
	display: flex;
	height: 32px;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 4px;
	background: var(--Brand-Primary-600-B, #0c5850);
	color: var(--Brand-Tertiary-50, #fcf7f0);
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 18px; /* 138.462% */
	min-width: auto;
}

.list {
	display: flex;
	flex-direction: column;
	gap: 22px;
}

.userItem {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	.infoBlock {
		display: flex;
		flex-direction: row;
		gap: 8px;
	}

	.img img {
		width: 32px;
		height: 32px;
		border-radius: 32px;
		border: 1px solid #d9d9d9;
	}

	.info {
		display: flex;
		flex-direction: column;

		.name {
			color: var(--Brand-Neutral-Grey-800, #2c3030);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
		}

		.email {
			color: var(--Brand-Neutral-Grey-600-B, #4e5555);
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
		}
	}

	.action {
		color: var(--Brand-Neutral-Grey-600-B, #4e5555);
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px; /* 133.333% */
		width: 77px;
		text-align: right;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}
