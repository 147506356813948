@import "styles/common.module.scss";

.pageTitle {
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: #404040;
	margin-bottom: 32px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media print {
		padding-top: 0;
		margin-top: -100px;
	}
}

.pageTitle > svg {
	margin: 0 4px;
}

.cardTitle {
	color: $mainTextColor;
	font-size: 1.125rem;
	font-weight: 600;
	cursor: pointer;
}

.previousButton {
	cursor: pointer;
}

.breadcrumb {
	display: flex;
	gap: 4px;
	align-items: center;
}

.printButton {
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	cursor: pointer;

	&.printRequested {
		display: none;
	}

	@media print {
		display: none !important;
	}
}
