.moreLikeThis {
	border-radius: 4px;
	border: 1px solid #dad8d7 !important;
	display: flex;
	padding: 4px 11px;
	justify-content: center;
	align-items: center;
	background: #fff;
	height: 32px;

	color: #181b1a;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;

	//&:hover {
	//	background: transparent;
	//}

	&.locked {
		color: #c3c3c3;
	}

	@media screen and (max-width: 767px) {
		font-size: 12px;
		line-height: 14px;
		padding: 4px 6px;
	}
}
