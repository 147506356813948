.tag {
	background: rgba(163, 156, 154, 0.1);
	border-radius: 3px;
	padding: 0.4rem 0.8rem 0.3rem 0.6rem;
	margin-right: 0.5rem;
	margin-top: 0.87em;
	color: rgba(0, 0, 0, 0.5);
	font-size: 0.8em;
	width: auto;
	border: 0;
	display: inline-block;
	flex-direction: row;
	align-items: center;
	transition: opacity 0.5s;
	opacity: 1;
}

.hide {
	opacity: 0;
	pointer-events: none;
}

.point {
	border-radius: 3px;
	margin-right: 0.3rem;
	color: #000;
	position: relative;
	font-weight: 600;
	transition-duration: 0.2s;
	transition-property: transform;
	cursor: pointer;

	&:hover {
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
	}
}

.tagText {
	align-self: center;
	display: inline-block;
}
