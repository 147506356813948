.container {
	background: #fff;

	&__wrapper {
		display: flex;
		flex-direction: row;
		gap: 16px;
		//width: 100%;
		//max-width: 100%;
		margin-left: 25px;
		margin-right: 25px;

		@media only screen and (max-width: 1024px) {
			justify-content: flex-start;
			gap: 5px;
			//margin: 0;
		}

		&__items {
			font-size: 18px;
			font-weight: 500;
			color: #cbc5c2;
			line-height: 22px;
			padding: 16px 15px;
			font-family: "Inter", sans-serif;

			@media only screen and (max-width: 1024px) {
				line-height: 12px;
				padding: 10px 14px;
			}

			@media only screen and (max-width: 767px) {
				font-size: 13px;
				line-height: 13px;
			}

			&__active {
				color: #271b00;
				padding: 16px 15px 14px;
				//padding-bottom: 10px;
				border-bottom: 2px solid #0c5850;

				@media only screen and (max-width: 1024px) {
					padding: 10px 14px 11px;
				}
			}
		}
	}

	&__border {
		height: 1px;
		width: 100%;
		border: 1px solid #eeedec;
	}

	&__content {
		padding: 24px;
		background: #f8f7f7;
	}
}
