.logo {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid #d1cdcc;
	user-select: none;
	pointer-events: none;
}

.right {
	margin-left: 8px;
}

.left {
	cursor: pointer;
}

.rightest {
	align-self: flex-start;
	margin-left: auto;

	.remove {
		cursor: pointer;

		svg path {
			transition: fill 0.2s linear;
			fill: #0c5850;
		}
	}

	.add {
		cursor: pointer;

		svg path {
			transition: fill 0.2s linear;
			fill: #cbc5c2;
		}

		&:hover {
			svg path {
				fill: #0c5850;
			}
		}
	}
}

.tooltipContent {
	max-width: 250px;

	.companyMainDetails {
		display: flex;
		align-items: center;
		color: #271b00;
		margin-bottom: 6px;

		.name {
			margin-bottom: 0 !important;
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;
		}

		.industry {
			font-weight: 400;
			font-size: 12px;
			line-height: 20px;
		}
	}

	.description {
		color: #404040;
		font-size: 12px;
		margin: 12px 0 16px 0;
	}

	.specialitiesButtons {
		.titleClass {
			font-size: 12px;
			font-weight: 500;
		}

		button {
			font-size: 12px;
			font-weight: 500;
			cursor: default;
		}
	}

	.footer {
		display: flex;
		align-items: center;
		margin-top: 12px;

		.stat {
			color: #404040;
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			margin-right: 16px;
			display: flex;
			align-items: center;

			svg {
				margin-right: 4px;
			}
		}
	}
}
