.container {
	display: flex;
	flex-direction: row;
	gap: 24px;
	align-items: flex-start;
	justify-content: space-between;
	flex-grow: 1;
	position: relative;

	@media screen and (max-width: 1024px) {
		flex-direction: column;
	}

	&__wrapper {
		position: relative;

		&__fourth_boarding {
			top: 30px;
			left: -32px;
			border-radius: 6px;
		}
	}
}

.contentWrapper {
	display: flex;
	width: 100%;

	::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}

	/* Track */
	::-webkit-scrollbar-track:horizontal {
		border-radius: 10px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb:horizontal {
		background: #3c6662;
		border-radius: 10px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #0c5850;
	}
}

.tabListClass {
	display: flex !important;
	padding: 0 !important;
	background: none !important;
	box-shadow: none !important;
	max-width: 795px;
	overflow-x: auto;
}

.tab {
	position: relative;
	width: 100%;

	display: flex;
	flex-direction: column;
	//gap: 12px;
}

.tabTitle {
	display: flex !important;
	padding: 10px !important;
	align-items: center !important;
	justify-content: space-between !important;
	border-radius: 4px 4px 0 0;
	background-color: #6161611c;

	span {
		color: var(--brand-neutral-grey-800, #2c3030) !important;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px; /* 150% */
		border-bottom: none !important;
		padding: 0 !important;
	}
}

.tabContainer {
	display: flex;
	flex-direction: row;
	padding-bottom: 50px;
	margin-top: 24px;
}

.instructionDesc {
	color: var(--brand-neutral-grey-600-b, #4e5555);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
}
