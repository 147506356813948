.tabs {
	z-index: 1;
	position: relative;

	@media (max-width: 820px) {
		padding-top: 0;
	}

	&__is {
		//@media (max-width: 820px) {
		//	padding-top: 41px;
		//}
	}

	.tabList {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		justify-content: left;
		//margin-bottom: 32px;
		gap: 5px;
		padding: 10px 10px 0;
		background: #ffffff;
		margin: 0;
		box-shadow: 0 9px 31px rgba(0, 0, 0, 0.04);
		border-radius: 0 0 4px 4px;

		@media (max-width: 767px) {
			gap: 12px;
			padding: 10px;
			flex-direction: column;
		}

		li {
			min-width: max-content;

			@media (max-width: 767px) {
				border: 1px solid #ececec;
				padding-top: 5px;
				border-radius: 4px;
			}
		}
	}
}
