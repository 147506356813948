@import "styles/common.module.scss";

.checkbox {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	//height: 40px;
	margin-bottom: 10px;

	&:not(:first-of-type) {
		margin-left: 0;
	}
}

.prefix {
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	color: #404040;
}

.checkboxLabel {
	display: inline-flex;
	color: #271b00;
	align-items: center;
	font-size: 14px;
	line-height: 20px;
	// padding: 0 7px;
	white-space: nowrap;
	text-transform: capitalize;

	cursor: pointer;
	//position: relative;

	//&:hover {
	//	&:after {
	//		position: absolute;
	//		content: attr(data-tooltip);
	//		//bottom: -5px;
	//		top: -20px;
	//		left: 0;
	//		background-color: rgba(43, 43, 43, 0.8);
	//		color: #ffffff;
	//		padding: 5px 10px;
	//		font-size: 13px;
	//		z-index: 999;
	//	}
	//}
}

/* Customize the label (the container) */
.container {
	display: block;
	position: relative;
	padding-left: 25px;
	margin-bottom: 14px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 15px;
	width: 15px;
	background-color: #fff;
	border-radius: 2px;
	border: 1px solid #808080;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
	background-color: $checkboxBackground;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
	background: $checkboxSelectedBackground;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after,
.partiallySelected:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after,
.container .partiallySelected:after {
	display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
	left: 4px;
	top: 0;
	width: 6px;
	height: 10px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.container .partiallySelected:after {
	left: 1px;
	top: -1.6px;
	width: 9px;
	height: 7px;
	border: solid $checkboxSelectedBackground;
	border-width: 0 2px 0 0;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}
