// body {
// 	background: transparent !important;
// }

.grids {
	margin-top: 32px;
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	background: transparent;
}

.industry {
	display: flex;
	flex-direction: column;
	max-width: calc(50% - 16px);
	min-width: calc(50% - 16px);
	border: 1px solid #f4f0ed;
	background: #f9f9f9;

	.industryName {
		color: #404040;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		border-bottom: 1px solid #f4f0ed;
		padding: 8px 16px;
		background: #fff;
	}

	.companies {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		padding: 8px;
	}

	.company {
		box-sizing: border-box;
		width: 127px;
		height: 56px;
		border: 1px solid #f4f0ed;
		background: #ffffff;
		padding: 19px 16px;
		flex-shrink: 0;
		display: flex;
		align-items: center;

		.companyName {
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			color: #271b00;
			margin-left: 8px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.companyImage {
			width: 24px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			user-select: none;
			color: #404040;
			pointer-events: none;
		}
	}

	&.singleGrid {
		max-width: 100%;
		min-width: 100%;

		.company {
			width: 132px;
		}
	}
}
