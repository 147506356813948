@import "styles/common.module.scss";

.wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: relative;
	// Wrapper should not set as 100%, if you need to set it for a
	// specific page use className prop to pass a new class.
	// height: 100%;

	&__content {
		width: 100%;
		display: flex;
		max-width: 1368px;
		margin-top: 74px;
		margin-left: 74px;
		margin-right: 0;
		//margin-bottom: 0;
		flex-direction: column;
		justify-content: space-between;
		//padding: 0 84px;

		//&__is_show {
		//	@media (max-width: 950px) {
		//		padding: 53px 32px 32px 68px !important;
		//	}
		//}

		@media (max-width: 2500px) {
			padding: 0 84px;
		}

		@media (max-width: 1340px) {
			padding: 80px;
			max-width: 100%;
			margin: 0 auto;
		}

		@media (min-width: 768px) and (max-width: 1024px) {
			padding: 40px;
			max-width: 100%;
			margin: 0 auto;
		}

		@media (max-width: 767px) {
			padding: 50px 20px;
		}

		//@media (max-width: 870px) {
		//	padding: 15px 32px 32px 68px;
		//}

		//@media screen and (max-width: 768px) {
		//  margin: 0 auto !important;
		//}

		// @media #{$viewportLarge} {
		// 	flex-direction: row;
		// }
	}
}

@media print {
	.wrapper {
		page-break-inside: avoid;
		position: relative;
		top: 0px;
		left: 0;
		right: 0;
		width: 100%;
	}
}
