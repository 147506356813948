.experience {
	margin-bottom: 20px;
	display: flex;

	@media (max-width: 1024px) {
		flex-direction: column;
	}

	.experienceList {
		display: flex;
		flex-direction: column;

		.jobInfo {
			display: flex;
			align-items: center;

			@media (max-width: 1024px) {
				gap: 5px;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
			}
		}
	}

	&.hidden {
		display: none;
	}
}

@media print {
	.experience {
		page-break-inside: avoid;
	}

	.companyName {
		min-width: 250px !important;

		> img {
			border: 1px solid #f8f7f7 !important;
		}
	}
}

.companyName {
	min-width: 15vw;
	color: #404040;
	font-size: 14px;
	width: 15vw;
	display: flex;

	@media (max-width: 1024px) {
		min-width: 100%;
		align-items: center;
	}

	> img {
		width: 52px;
		height: 52px;
		object-fit: cover;
		background-color: transparent;
		margin-right: 16px;
		user-select: none;
		cursor: pointer;
	}

	> span {
		cursor: pointer;
		font-size: 16px;
	}

	&.printRequested {
		display: block;
		margin-top: 1rem;
		page-break-before: auto;
	}
}

.detail {
	margin-bottom: 8px;

	&.hidden {
		display: none;
	}

	&.printRequested {
		display: block;
		margin-top: 1rem;
		page-break-before: auto;
	}
}

.jobTitle {
	font-weight: 500;
	font-size: 14px;
	margin: 7px 0 5px 0;

	.location {
		font-size: 14px;
		color: #404040;
		margin-left: 10px;
	}
}

.description {
	width: 80%;
	display: inline-block;
}

.dates {
	font-size: 14px;
	color: #271b00;
	width: 150px;

	.duration {
		font-size: 12px;
		margin-left: 10px;
	}
}

.description {
	color: #404040;
	font-size: 14px;
	white-space: pre-wrap;
	line-height: 20px;

	.more {
		cursor: pointer;
		color: #3c6662;
		margin-left: 5px;

		&:hover {
			text-decoration: underline;
		}
	}
}

.showMore {
	user-select: none;
	color: #0c5850;
	font-size: 14px;
	cursor: pointer;
	margin-top: -6px;
	display: flex;

	&:hover {
		text-decoration: underline;
	}
}
