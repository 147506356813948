.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;

	&__icon {
		margin-bottom: 32px;
	}

	&__title {
		color: #383838;
		font-size: 32px;
		font-weight: 600;
		line-height: 40px;
		text-align: center;
	}

	&__description {
		color: #5a5a5a;
		font-size: 16px;
		line-height: 24px;
		margin: 16px 0 32px 0;
		max-width: 500px;
		text-align: center;
	}

	&__button {
		display: flex;
		border: none;
		font-size: 14px;
		font-weight: 600;
		color: #ffffff;
		line-height: 24px;
		margin-left: 16px;
		padding: 8px 12px;
		text-align: center;
		border-radius: 4px;
		max-width: max-content;
		user-select: none;

		@media (max-width: 870px) {
			padding: 6px 16px;
		}

		&__icon {
			margin-right: 8px;

			svg path {
				fill: #ffffff;
			}
		}
	}
}
