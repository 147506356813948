.wrapper {
	// height: 100%;
}

.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.grids {
	flex-grow: 1;
	// overflow: auto;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	// grid-auto-rows: 100%;
	gap: 24px;
	// height: 100%;

	@media (max-width: 980px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 690px) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.loaderContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 50%;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.information {
	position: relative;
	margin: 32px 0;
	text-align: center;
	user-select: none;

	@media (max-width: 860px) {
		text-align: left;
	}
	@media (max-width: 600px) {
		text-align: center;
	}

	h1 {
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		margin: 0 0 8px 0;
	}

	p {
		margin: 0;
		font-size: 14px;
		line-height: 22px;

		&:not(:last-child) {
			margin-bottom: 3px;
		}

		b {
			color: #404040;
			font-weight: 600;
		}
	}
}

.empty {
	width: 166px;

	@media (max-width: 860px) {
		display: none;
	}
}

.completed {
	@media (max-width: 600px) {
		display: none;
	}
}
