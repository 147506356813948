.wrapper {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: flex;
	max-width: 100%;
	position: fixed;
	align-items: center;
	justify-content: center;
	background-color: rgba(20, 20, 20, 0.7);

	&__container {
		width: 100%;
		margin: auto;
		max-width: 600px;
		background-color: white;
	}
}
