@import "styles/common.module.scss";

.project {
	font-family: "Inter", sans-serif;
	position: relative;
	background: #ffffff;
	display: flex;
	justify-content: space-between;
	margin: 10px 0;
	border-radius: 4px;
	width: 100%;
}

.projectContent {
	font-family: "Inter", sans-serif;
	position: relative;
	z-index: 0;
	background: #ffffff;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding: 24px;
	border-radius: 4px;

	@media screen and (max-width: 767px) {
		flex-direction: column;
	}
}

.projectMenu {
	font-family: "Inter", sans-serif;
	position: absolute;
	z-index: 999;
	top: -18px;
	right: 20px;
	width: 165px;
	background: #ffffff;
	border: 1px solid rgba(163, 156, 154, 0.5);
	padding: 7px 0 7px 15px;

	.menuItem {
		font-family: "Inter", sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.75);
		padding: 7px 15px 7px 7px;
		cursor: pointer;
	}
}

.logoContainer {
	width: 75%;
	display: flex;
	justify-content: flex-start;

	@media screen and (max-width: 767px) {
		width: 100%;
		height: 80px;
	}
}

.textContainer {
	font-family: "Inter", sans-serif;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media screen and (max-width: 767px) {
		width: 100%;
		gap: 10px;
	}
}

.logo {
	position: absolute;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin-top: -5px;
	border: 1px solid #d1cdcc;
	object-fit: cover;

	&.icon {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.dateInfo,
.locationInfo {
	font-family: "Inter", sans-serif;
	font-size: 14px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.75);
	font-weight: 300;
}

.openProjectButton {
	border: 1px solid #0c5850;
	background-color: transparent;
	border-radius: 4px;
	padding: 8px 8px 8px 16px;
	font-size: 14px;
	color: #0c5850;
	font-weight: 600;
	margin-top: -10px;

	span {
		margin-right: 5px;
	}
}

.projectInfo {
	width: 100%;

	h2 {
		font-size: 18px;
		cursor: pointer;
		margin-top: -2px;
		font-weight: 600;
		width: fit-content;
	}
}

.projectStatsContainer {
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: 767px) {
		flex-direction: column;
	}

	.projectStats {
		margin-top: 30px;
		display: grid;
		gap: 25px;
		margin-right: -50px;
		grid-template-columns: auto auto auto;

		@media screen and (max-width: 767px) {
			grid-template-columns: auto auto;
		}

		.statItem {
			font-family: "Inter", sans-serif;
			font-size: 14px;
			color: rgba(0, 0, 0, 0.75);
		}

		.statItemWithBorderRight {
			font-family: "Inter", sans-serif;
			font-size: 14px;
			color: rgba(0, 0, 0, 0.75);
			border-right: 1px solid #eeedec;
			padding: 0 20px 0 0;
			height: 25px;
		}

		.companyCount {
			margin-left: 80px;
			margin-top: 1px;

			@media screen and (max-width: 767px) {
				margin-top: -78px;
			}
		}
	}
}

.diversity_stat {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 5px;

	.progressBar {
		width: 10vw;
		height: 3px;
		background-color: #cbcbcb;
		border-radius: 5px;
		overflow: hidden;

		.progress {
			height: 100%;
			background-color: #0c5850;
			transition: width 0.3s ease;
		}
	}
}

.menuDots {
	border: 1px solid #eeedec;
	border-radius: 4px;
	position: relative;
	right: 10px;
	width: 35px;
	height: 35px;
	cursor: pointer;
	color: #868d8d;
	padding: 5px;

	&:hover {
		background-color: rgba(200, 200, 200, 0.15);
		border-radius: 50%;
		border: 2px solid rgba(200, 200, 200, 0.15);
	}

	&:focus {
		background-color: rgba(200, 200, 200, 0.15);
		border-radius: 50%;
	}
}

.active {
	display: block;
	position: relative;
}

.hidden {
	display: none;
}
