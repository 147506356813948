// mapx light theme
$fontFamily: Inter, sans-serif;
$mainThemeColor: #0c5850;
$mainTextColor: #000;
$secondaryTextColor: #404040;
$loaderDotColor: #0c5850;

$badgeBackground: #0c5850;
$badgeText: #ffffff;

$enhancedButtonBackground: #507a76;
$enhancedButtonText: #fff;
$enhancedButtonFontSize: 14px;

$generalButtonActive: #0c5850;
$generalButtonPassive: #3c6662;

$generalButtonBackground: #0c5850;
$generalButtonText: #fff;

$toggleButtonBackground: #fff;
$toggleButtonText: #404040;
$toggleButtonFontSize: 14px;
$toggleButtonBorder: 1px solid rgba(0, 0, 0, 1);

$rangeSliderBackground: #0c5850;
$rangeSliderRemaining: #eae9f0;
$rangeSliderHandle: #0c5850;

$candidateIndustryButtonBackground: #f9f9f9;
$candidateIndustryButtonBorder: 0px;
$candidateIndustryButtonFontSize: 14px;
$candidateIndustryButtonHeight: 27px;

$infoButtonBackground: #f9f9f9;
$infoButtonBorder: 0px;
$infoButtonFontSize: 14px;
$infoButtonHeight: 27px;

$checkboxBackground: #ccc;
$checkboxSelectedBackground: #0c5850;

$filterScrollbarColor: #0c5850;

:export {
	fontFamily: $fontFamily;
	mainThemeColor: $mainThemeColor;
	mainTextColor: $mainTextColor;
	secondaryTextColor: $secondaryTextColor;
	loaderDotColor: $loaderDotColor;

	badgeBackground: $badgeBackground;
	badgeText: $badgeText;

	enhancedButtonBackground: $enhancedButtonBackground;
	enhancedButtonText: $enhancedButtonText;
	enhancedButtonFontSize: $enhancedButtonFontSize;

	generalButtonBackground: $generalButtonBackground;
	generalButtonText: $generalButtonText;

	toggleButtonBackground: $toggleButtonBackground;
	toggleButtonText: $toggleButtonText;
	toggleButtonFontSize: $toggleButtonFontSize;
	toggleButtonBorder: $toggleButtonBorder;

	rangeSliderBackground: $rangeSliderBackground;
	rangeSliderRemaining: $rangeSliderRemaining;
	rangeSliderHandle: $rangeSliderHandle;

	candidateIndustryButtonBackground: $candidateIndustryButtonBackground;
	candidateIndustryButtonBorder: $candidateIndustryButtonBorder;
	candidateIndustryButtonFontSize: $candidateIndustryButtonFontSize;
	candidateIndustryButtonHeight: $candidateIndustryButtonHeight;

	infoButtonBackground: $infoButtonBackground;
	infoButtonBorder: $infoButtonBorder;
	infoButtonFontSize: $infoButtonFontSize;
	infoButtonHeight: $infoButtonHeight;

	checkboxBackground: $checkboxBackground;
	checkboxSelectedBackground: $checkboxSelectedBackground;

	filterScrollbarColor: $filterScrollbarColor;
}
