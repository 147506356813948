$texboxHeight: 40px;
$actionHeight: 50px;

.container {
	position: relative;
	height: $texboxHeight;
	z-index: 1;
	flex-shrink: 0;
}

.select {
	position: relative;
	user-select: none;
	border: 1px solid #dadada;
	background-color: #fff;
	border-radius: 3px;
	overflow: hidden;
	height: 40px;
	box-sizing: border-box;
	display: flex;
	align-items: center;

	&.expanded {
		border-color: #0c5850;
	}
}

.textbox {
	width: 100%;
	align-items: center;
	background-color: transparent;
	border: none;
	color: #404040;
	cursor: pointer;
	display: flex;
	font-weight: 500;
	font-size: 14px;
	height: $texboxHeight;
	line-height: 20px;
	outline: none;
	padding: 8px 16px;
	resize: none;

	> svg {
		margin-right: 8px;
		flex-shrink: 0;
	}
}

.dropdown {
	display: none;
	max-height: 252px;
	overflow-y: scroll;

	&.withAction {
		margin-bottom: $actionHeight;
	}

	&.expanded {
		display: block;
	}
}

.option {
	cursor: pointer;
	padding: 8px 10px;
	font-size: 14px;
	line-height: 20px;
	user-select: none;

	&:hover {
		background-color: #eeeeee;
	}

	> svg {
		margin-right: 8px;
		flex-shrink: 0;
	}
}

.action {
	padding: 8px 10px;
	height: $actionHeight;
	box-sizing: border-box;
	background-color: #fff;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.group {
	display: flex;
	flex-direction: column-reverse;
}

.chevronContainer {
	margin-left: auto;
}

.chevron {
	transition: transform 0.2s linear;
	transform: rotateX(180deg);

	&.rotated {
		transform: rotateX(0deg);
	}
}
