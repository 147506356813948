.select {
	padding: 5px 10px;
	color: #271b00;
	font-weight: 500;
	font-size: 14px;
	border-color: #a39c9a;
	border-radius: 4px;
	user-select: none;
}

.positionContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	margin-top: 10px;

	.positionLabel {
		font-size: 14px;
		color: #271b00;
		user-select: none;
	}
}
