@import "styles/common.module.scss";

.generalButton {
	user-select: none;
	position: relative;
	background: $generalButtonPassive;
	border-radius: 3px;
	text-align: center;
	font-size: 13px;
	font-weight: 500;
	color: $generalButtonText;
	padding: 0 16px;
	min-width: 116px;
	border: 1px solid $generalButtonPassive;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;

	> svg {
		margin-right: 6px;
	}

	&.disabled {
		//cursor: unset;
		//pointer-events: none;
		background: #b5b1b0;
		border: 1px solid #b5b1b0;
	}

	&:hover {
		cursor: pointer;
		opacity: 0.9;
	}
}

.generalButton,
.removeButton {
	// svg {
	// 	margin: 0 4px 2px 0;
	// }
}

.removeButton {
	user-select: none;
	cursor: pointer;
	border: 1px solid #c91515;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 500;
	display: flex;
	flex-direction: row;
	gap: 6px;
	align-items: center;
	padding: 6px 12px;
	background: transparent;
}

.clickedButton {
	background: $generalButtonActive;
	border: 1px solid $generalButtonActive;
}

.checkIcon {
	left: 17px;
	position: absolute;
	margin-right: 5px;
	padding-bottom: 2px;
}

.iconContainer {
	margin-right: 10px;
}

.expandable {
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	margin-right: -16px;
	width: 40px;
	height: 40px;
	border-left: 1px solid #ffffff33;
}

.chevron {
	transition: transform 0.2s linear;
	transform: rotateX(180deg);

	&.expanded {
		transform: rotateX(0deg);
	}
}

.option {
	cursor: pointer;
	//padding: 8px 10px;
	font-size: 14px;
	line-height: 20px;
	user-select: none;

	&:hover {
		//background-color: #eeeeee;
		opacity: 0.7;
	}
}
