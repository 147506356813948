.container {
	display: flex;
	flex-direction: row;
	padding: 16px;
	align-items: center;
	gap: 16px;
	align-self: stretch;
	border-bottom: 1px solid var(--System-Silver-Chalice-100, #eeedec);
	background: #ffffff;
	position: relative;

	&.recommended {
		border: 1px solid var(--Brand-Tertiary-800, #d58a31);
	}

	&.isNextCandidateRecommended {
		border-bottom: none;
	}
}

.basicInfo {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 16px;
	color: var(--Brand-Neutral-Grey-800, #2c3030);
	font-weight: 500;

	.name {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 12px;
		font-size: 14px;
		font-style: normal;
		line-height: 20px; /* 150% */
		width: 252px;
		position: relative;
	}

	.role,
	.previousRole {
		display: flex;
		align-items: center;
		width: 190px;

		font-size: 14px;
		line-height: 20px; /* 142.857% */
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.previousRole {
		width: 148px;
	}

	.company {
		display: flex;
		align-items: center;
		width: 190px;

		.companyName {
			display: flex;
			font-size: 14px;
			line-height: 20px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}

	.startDate {
		font-size: 14px;
		line-height: 20px;
		width: 80px;
	}
}

.recommendedIcon {
	position: absolute;
	z-index: 1;
	top: -5px;
	left: 32px;
}

.logo {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: 1px solid #d1cdcc;
	object-fit: cover;
	cursor: pointer;
	position: relative;
}

.iconLogo {
	min-width: 32px;
	min-height: 32px;
	border-radius: 50%;
	padding: 6px;
	border: 1px solid #d1cdcc;
	object-fit: cover;
	cursor: pointer;
}

.companyLogo {
	width: 24px;
	height: 24px;
	margin-right: 5px;
	border-radius: 2px;
	border: 1px solid #eeedec;
	object-fit: cover;
}

.scoringSection {
	display: flex;
	align-items: center;
	width: 120px;
}

.score {
	display: flex;
	flex-direction: row;
	gap: 6px;
	padding: 8px;
	align-items: center;
	border-radius: 4px;
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
	width: 120px;
	justify-content: center;

	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px; /* 138.462% */

	&.lowScoreMatch {
		background: var(--System-Warning-100, #e5e6e6);
		color: var(--System-Warning-800, #4e5555);
	}

	&.midScoreMatch {
		background: var(--System-Warning-100, #fec);
		color: var(--System-Warning-800, #960);
	}

	&.highScoreMatch {
		background: var(--System-Warning-100, #d9f2e5);
		color: var(--System-Warning-800, #26734c);
	}
}

.progressStatusSection {
	width: 220px;
	position: relative;
}

.projectMenu {
	font-family: "Inter", sans-serif;
	position: absolute;
	z-index: 999 !important;
	top: 35px;
	right: 10px;
	width: 200px;
	background: #ffffff;
	border: 1px solid rgba(163, 156, 154, 0.5);
	//padding: 7px 0 7px 15px;

	.menuItem {
		font-family: "Inter", sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.75);
		padding: 8px 12px;
		cursor: pointer;

		&:hover {
			background-color: rgba(200, 200, 200, 0.15);
			opacity: 0.86;
		}
	}
}

.menuDots {
	border: 1px solid #eeedec;
	border-radius: 4px;
	position: relative;
	right: 10px;
	width: 35px;
	height: 35px;
	cursor: pointer;
	color: #868d8d;
	padding: 5px;

	&:hover {
		background-color: rgba(200, 200, 200, 0.15);
		border-radius: 50%;
		border: 2px solid rgba(200, 200, 200, 0.15);
	}

	&:focus {
		background-color: rgba(200, 200, 200, 0.15);
		border-radius: 50%;
	}
}

.removeCandidateFromList {
	width: 32px;
	display: flex;
	height: 32px;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	gap: 4px;

	border-radius: 4px;
	border: 1px solid var(--System-Silver-Chalice-100, #eeedec);
}

.activeMenu {
	display: block;
	position: relative;
}

.hiddenMenu {
	display: none;
}

.rotatingWrapper {
	position: relative;
	display: inline-block;
	width: 32px;
	height: 32px;
}

.rotatingWrapper::after {
	content: "";
	position: absolute;
	top: -3px;
	left: -3px;
	right: -3px;
	bottom: -3px;
	border-radius: 50%;
	background: conic-gradient(rgba(134, 141, 141, 1) 0% 75%, transparent 75% 100%);
	animation: rotate 4s linear infinite;
	-webkit-mask: radial-gradient(circle, transparent 57%, rgba(134, 141, 141, 1) 73%);
	mask: radial-gradient(circle, transparent 57%, rgba(134, 141, 141, 1) 73%);
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.tableHeaderText {
	color: var(--Brand-Neutral-Grey-600-B, #4e5555);
	font-size: 12px !important;
	font-style: normal;
	font-weight: 600;
	line-height: 16px; /* 133.333% */

	.leaver {
		color: var(--System-Error-700, #c20a0a);
	}

	.promotion {
		color: var(--System-Warning-700, #c80);
	}

	.newHires {
		color: var(--System-Success-700, #309161);
	}

	.noChange {
		color: var(--Brand-Neutral-Grey-400, #868d8d);
	}
}
