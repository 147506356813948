.scoringContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 8px;

	.noScore {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		gap: 4px;

		.title {
			display: flex;
			overflow: hidden;
			color: var(--Brand-Neutral-Grey-300, #b0b5b4);
			text-overflow: ellipsis;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;
		}

		.description {
			color: var(--Brand-Neutral-Grey-300, #b0b5b4);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
		}
	}
}

.editIcon {
	padding: 2px 8px 2px 4px;
	border-radius: 37px;
	cursor: pointer;
}

.fitToBriefScore {
	display: flex;
	height: 32px;
	padding: 6px 8px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	border-radius: 4px;
	width: 100%;

	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */

	.circle {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 2px solid;
		background-color: transparent;
		opacity: 0.3;
	}

	&.lowScoreMatch {
		background: var(--System-Warning-100, #e5e6e6);
		color: var(--System-Warning-800, #4e5555);
	}

	&.midScoreMatch {
		background: var(--System-Warning-100, #fec);
		color: var(--System-Warning-800, #960);
	}

	&.highScoreMatch {
		background: var(--System-Warning-100, #d9f2e5);
		color: var(--System-Warning-800, #26734c);
	}

	.score {
		display: flex;
		flex-direction: row;
		gap: 6px;
	}
}

.criteriaResults {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	//gap: -1px;
	align-self: stretch;
	width: 100%;

	.status {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-content: center;
		gap: 6px;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */

		&.yes {
			color: var(--System-Success-800, #26734c);
		}

		&.no {
			color: var(--System-Error-700, #c20a0a);
		}

		&.maybe {
			color: var(--System-Warning-600-B, #f5a300);
		}
	}
}
