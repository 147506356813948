.searchBar {
	display: flex;
	align-self: stretch;
	position: relative;
	width: 100%;

	.inputContainer {
		display: flex;
		align-self: stretch;
		border-radius: 5px;
		transition: height 0.2s ease;
		background: #ffffff;
		min-height: 40px;

		&:focus {
			border-color: transparent;
		}

		@media screen and (max-width: 767px) {
			width: 100%;
		}

		@media screen and (max-width: 1024px) {
			width: 550px;
		}
	}

	.suggestionsContainer {
		position: absolute;
		left: 0;
		background-color: #fff;
		display: flex;
		width: 100%;
		padding: 16px;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 8px;

		border-radius: 4px;
		border: 1px solid var(--system-silver-chalice-200, #dad8d7);
		background: var(--system-bw-white, #fff);
		/* Shadows On Light/XXL */
		box-shadow: 0 25px 60px -10px rgba(79, 75, 74, 0.12);
		z-index: 3;

		@media screen and (max-width: 767px) {
			top: 49px;
			width: 100%;
			z-index: 2;
		}

		@media screen and (min-width: 768px) and (max-width: 1024px) {
			width: 550px;
		}
	}

	.exampleTitle {
		color: var(--brand-neutral-grey-600-b, #4e5555);
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px; /* 142.857% */
	}

	.suggestionsList {
		padding: 0;
		margin: 0;

		.suggestion {
			margin-left: 24px;
			color: var(--brand-neutral-grey-600-b, #4e5555);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
		}
	}

	.creditInfo {
		margin-top: 8px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		color: var(--brand-primary-600-b, #0c5850);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
		flex: 1;
		width: 100%;
		padding: 5px 12px;
		border-radius: 4px;
		border: 1px solid var(--brand-primary-600-b, #0c5850);

		svg {
			color: var(--brand-primary-600-b, #0c5850);
		}
	}
}

.searchIcon {
	position: absolute;
	left: 10px;
	top: 13px;
	line-height: 0.8;
}

.instructionWrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.instructionTitle {
	color: var(--brand-neutral-grey-800, #2c3030);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px; /* 155.556% */
	display: flex;
	align-items: center;
	gap: 8px;
}

.instructionDesc {
	color: var(--brand-neutral-grey-600-b, #4e5555);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
}
