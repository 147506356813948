@import "styles/common.module.scss";

.ethnicityChart {
	margin-left: 1em;

	@media (max-width: 968px) {
		margin-left: 0;
	}
}

.chartWrapper {
	padding-right: 15%;
}
