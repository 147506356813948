.container {
	border-radius: 4px;
	background: var(--system-bw-white, #fff);
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);

	.listContainer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		padding: 24px 20px;

		.companiesContainer {
			width: 50%;
			display: flex;
			//justify-content: flex-start;
			gap: 20px;
			align-items: center;

			@media screen and (max-width: 1200px) {
				flex-direction: column;
			}
		}

		.peopleContainer {
			width: 50%;
			display: flex;
			justify-content: flex-start;
			gap: 20px;
			align-items: center;

			@media screen and (max-width: 1200px) {
				flex-direction: column;
			}
		}

		@media screen and (max-width: 1200px) {
			width: 720px;
		}

		@media screen and (max-width: 800px) {
			width: 340px;
		}
	}

	.viewButtonSection {
		display: flex;
		flex-direction: column;
		cursor: pointer;

		span {
			color: var(--Brand-Neutral-Grey-800, #2c3030);
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 28px;
		}

		small {
			color: var(--Brand-Primary-600-B, #0c5850);
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;
		}
	}

	.logoContainer {
		width: 176px;
		display: inline-flex;
		position: relative;
		height: 52px;
		//top: -25px;
		//justify-content: flex-start;

		@media screen and (max-width: 767px) {
			width: 100%;
		}
	}

	.logo {
		position: absolute;
		width: 48px;
		height: 48px;
		border-radius: 50%;
		object-fit: cover;
		border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
		z-index: 1 !important;

		&.icon {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.headerSection {
		display: flex;
		justify-content: space-between;
		align-items: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		border-bottom: 1px solid #eeedec;

		@media screen and (max-width: 1200px) {
			width: 720px;
		}

		@media screen and (max-width: 800px) {
			width: 340px;
		}

		h2 {
			font-size: 18px;
			font-weight: 500;
			color: var(--Brand-Neutral-Grey-800, #2c3030);
			line-height: 28px;
			margin-left: 20px;
			width: fit-content;
		}

		.addAllButton {
			display: inline-flex;
			padding: 9px 16px;
			justify-content: center;
			align-items: center;
			gap: 8px;
			border-radius: 4px;
			border: 1px solid var(--Brand-Primary-600-B, #0c5850);
			background: var(--Brand-Primary-600-B, #0c5850);
			width: fit-content;

			// @media screen and (max-width: 800px) {
			// 	margin-left: auto;
			// }
		}
	}
}
