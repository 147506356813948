.wrapper {
	width: 100%;
	padding: 32px;
	min-height: 472px;
	border-radius: 8px;
	background: #ffffff;
	max-width: 400px !important;

	&__title {
		font-family: "Inter";
		font-weight: 500;
		font-size: 22px;
		line-height: 30px;
		text-align: center;
		color: #312200;
	}

	&__subtitle {
		margin: 16px 0;
		font-family: "Inter";
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		color: #312200;
	}

	&__changed {
		min-height: 300px;

		&_icon {
			display: block;
			margin: 0 auto 8px;
		}

		&_subtitle {
			font-size: 16px;
			font-weight: 400;
			color: #312200;
			line-height: 24px;
			text-align: center;
			font-family: "Inter";
		}
	}

	&__form {
		margin: 0 auto;
		gap: 16px !important;

		&__btn {
			margin-top: 0 !important;
		}
	}

	&__finish {
		border-radius: 4px;
		border: none;
	}
}
