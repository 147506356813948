.filterSectionWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 8px;

	flex: none;
	align-self: stretch;
	flex-grow: 0;

	.left {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 16px;
	}

	.right {
		display: flex;
		flex-direction: row;
		gap: 16px;
	}
}

.filterButton {
	height: 40px;
	min-width: 142px;
	padding: 8px 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;
	border-radius: 4px;
	border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
	box-shadow: 0px 17px 33px -2px rgba(79, 75, 74, 0.05);
	background: transparent;
	position: relative;

	&.hasFilter {
		min-width: 190px;
	}

	.filterButtonTitle {
		color: var(--Brand-Neutral-Grey-900, #181b1a);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
	}
}

.dropdownPlaceholder {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 16px;

	color: var(--Brand-Neutral-Grey-900, #181b1a);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;

	span {
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
	}
}
