@import "styles/variables.module.scss";

.container {
	background: #ffffff;
	display: flex;
	flex-direction: column;
	padding-bottom: 32px;

	&__loader {
		position: relative;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 0 24px;

		@media (max-width: 950px) {
			flex-direction: column;
			align-items: flex-start;
		}

		&__title {
			display: flex;
			align-items: center;
			cursor: pointer;

			h3 {
				font-weight: 500;
				color: rgba(44, 48, 48, 1);
			}

			@media (max-width: 805px) {
				flex-direction: column;
				align-items: flex-start;
			}

			.expandText {
				margin-right: 20px;
				color: #0c5850;
				min-width: max-content;

				@media (max-width: 805px) {
					margin-right: 0;
				}
			}
		}

		&__expand {
			display: flex;
			align-items: center;
			cursor: pointer;

			@media (max-width: 805px) {
				margin-top: 8px;
			}

			&__text {
				margin-right: 20px;
				color: #0c5850;
				min-width: max-content;
			}
		}
	}

	&__candidates {
		&__heading {
			font-size: 18px;
			line-height: 22px;
			text-align: center;
			font-weight: 500;

			&__green {
				color: #0c5850;
				font-weight: 700;
			}
		}
	}
}
