.tabs {
	display: flex;
	word-break: keep-all;
	border-radius: 2px;
	color: #a39c9a;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	user-select: none;

	&__tab {
		box-sizing: border-box;
		padding: 4px 8px;
		border: 1px solid #dad8d7;
		cursor: pointer;

		&__selected {
			color: #271b00;
			border-color: #271b00;
		}

		&:first-child {
			border-radius: 4px 0 0 4px;
		}

		&:last-child {
			border-radius: 0 4px 4px 0;

			//&:not(.tabs__tab__selected) {
			//  border-right-color: transparent;
			//}
		}
	}
}

.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 16px 0 0 0;

	&__label {
		font-size: 14px;
		color: #271b00;
		user-select: none;
		font-weight: 500;
	}
}
