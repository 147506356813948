@import "styles/common.module.scss";

.label {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	//display: flex;
	flex: 1;

	&.recommended {
		color: $mainThemeColor;
	}
}

/* Customize the label (the container) */
.container {
	display: inline-flex;
	max-width: 100%;
	align-items: center;
	position: relative;
	min-height: 15px;
	min-width: 15px;
	cursor: pointer;
	font-size: 14px;
	color: #271b00;
	padding-left: 21px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.container .checkbox {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	left: 0;
	//top: 5px;
	height: 15px;
	width: 15px;
	background-color: #fff;
	border-radius: 2px;
	border: 1px solid #808080;
}

/* On mouse-over, add a grey background color */
.container:hover .checkbox ~ .checkmark {
	background-color: $checkboxBackground;
}

/* When the checkbox is checked, add a blue background */
.container .checkbox:checked ~ .checkmark {
	background: $checkboxSelectedBackground;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after,
.partiallySelected:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container .checkbox:checked ~ .checkmark:after,
.container .partiallySelected:after {
	display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
	left: 4px;
	top: 0;
	width: 6px;
	height: 10px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.container .partiallySelected:after {
	left: 1px;
	top: -1.6px;
	width: 9px;
	height: 7px;
	border: solid $checkboxSelectedBackground;
	border-width: 0 2px 0 0;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.disabledCheckbox {
	.disabled {
		opacity: 0.8;
		border-color: #b0b5b4 !important;
		background-color: #b0b5b4 !important;
	}

	.disabled,
	.label,
	.checkbox {
		cursor: not-allowed !important;
		color: #b0b5b4;
	}
}

.container .checkbox:checked ~ .checkmark {
	&.disabled {
		background: rgba(19, 89, 82, 0.65) !important;
	}
}

.lockOption {
	position: relative;
	left: 4px;
}
