.wrapper {
	display: flex;
	overflow: hidden;
	flex-direction: column;

	&__banner {
		overflow: auto;
	}
}

.video {
	&_wrapper {
		top: 0;
		left: 49px;
		right: 0;
		bottom: 0;
		z-index: 2;
		display: flex;
		position: fixed;
		min-height: 100vh;
		align-items: center;
		justify-content: center;

		&__bg {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 2;
			position: absolute;
			background-color: rgba(20, 20, 20, 0.7);
		}
	}

	&_container {
		z-index: 2;
		width: 100%;
		max-width: 73%;
		min-width: 600px;
		background: #fff;
		border-radius: 4px;
		max-height: calc(100vh - 90px);

		&__video {
			position: relative;
			height: 350px;
			z-index: 2;
			width: 80%;

			&_iframe {
				top: 0;
				left: 0;
				width: 100%;
				height: auto;
				position: absolute;
			}
		}
	}
}

.content {
	width: 100%;
	display: flex;
	margin: 0 auto;
	max-width: 1248px;
	align-items: center;
	flex-direction: column;
	height: calc(100vh - 74px);
	//padding: 71px 0 32px 48px;
	justify-content: space-between;
	padding-bottom: 30px;

	//@media (max-width: 1280px) {
	//	padding: 125px 60px 32px;
	//}

	@media (max-width: 1200px) {
		max-width: 1000px;
	}

	@media only screen and (max-width: 767px) {
		overflow: auto;
		position: absolute;
		left: 0;
		padding: 0 20px;
	}

	//@media (max-width: 1024px) {
	//	padding: 125px 24px 32px 68px;
	//}

	&__first {
		padding-top: 0;
		height: calc(100vh - 112px);
	}

	&__header {
		width: 100%;
		padding: 16px;
		display: flex;
		margin-top: 32px;
		flex-wrap: wrap;
		border-radius: 4px;
		align-items: center;
		background: #ffffff;
		justify-content: space-between;
		box-shadow: 0px 9px 31px rgba(0, 0, 0, 0.04);

		@media (max-width: 1024px) {
			flex-direction: column;
			margin-top: 0;
		}

		@media (max-width: 870px) {
			margin-top: 32px;
		}

		&__first {
			margin-top: 32px;
		}

		&__is_header {
			margin-top: 32px;
		}

		&__left {
			display: flex;
			align-items: center;

			@media (max-width: 1024px) {
				margin-bottom: 8px;
			}

			&_img {
				width: 100%;
				height: 64px;
				max-width: 64px;
				min-height: 64px;
				margin-right: 16px;
				border-radius: 50%;
			}

			&__texts {
				&__name {
					margin-top: 0;
					font-size: 24px;
					font-weight: 500;
					color: #383838;
					line-height: 32px;
					margin-bottom: 6px;
					font-family: "Inter", sans-serif;

					@media (max-width: 870px) {
						font-size: 20px;
						line-height: 28px;
					}

					&_greetings {
						font-size: 24px;
						font-weight: 400;
						color: #383838;
						margin-right: 5px;
						line-height: 32px;
						font-family: "Inter", sans-serif;
					}
				}

				&__description {
					margin: 0;
					display: flex;
					font-size: 14px;
					font-weight: 500;
					color: #5a5a5a;
					line-height: 22px;
					align-items: center;
					font-family: "Inter", sans-serif;

					&_info {
						cursor: pointer;
						outline: none;
						margin-left: 8px;
					}
				}
			}
		}

		&__right {
			gap: 16px;
			display: flex;
			align-items: center;

			&__center {
				&_item {
					padding: 0 16px;
					border: solid #e9e9e9;
					border-width: 0 1px;
				}

				&_last {
					padding: 0 16px;
					border: solid #e9e9e9;
					border-width: 0 0 0 1px;
				}
			}

			&__item {
				&__title {
					gap: 4px;
					margin-top: 0;
					display: flex;
					font-size: 14px;
					color: #5a5a5a;
					font-weight: 500;
					line-height: 22px;
					margin-bottom: 6px;
					align-items: center;
					font-family: "Inter", sans-serif;

					@media (max-width: 1024px) {
						font-size: 12px;
						line-height: 20px;
						min-width: max-content;
					}

					&_icon {
						cursor: pointer;
					}
				}

				&__info {
					display: flex;
					align-items: center;

					svg {
						width: 32px;
						height: 32px;
						margin-right: 8px;
					}

					&_count {
						font-size: 32px;
						color: #383838;
						font-weight: 600;
						line-height: 38px;
						font-family: "Inter", sans-serif;
						min-width: max-content;

						@media (max-width: 1024px) {
							font-size: 24px;
							line-height: 32px;
						}

						&_slash {
							font-size: 16px;
							color: #808080;
							font-weight: 600;
							line-height: 32px;
						}
					}
				}
			}
		}
	}

	&__logo {
		display: block;
		margin: 16px auto 3vh;

		@media (max-width: 870px) {
			margin: 16px auto;
		}
	}

	&__bottom {
		width: 100%;
		display: flex;
		margin-top: 5vh;
		flex-direction: column;
		justify-content: space-between;

		&__skeletons {
			display: flex;
			height: 270px;
			align-items: center;
		}

		&__container {
			gap: 24px;
			display: flex;
			align-items: center;

			@media (max-width: 1280px) {
				justify-content: space-between;
			}

			@media screen and (max-width: 767px) {
				flex-direction: column;
				align-items: center;
				width: 100%;
			}

			&__empty {
				width: 384px;
				min-height: 206px;
				border-radius: 4px;
				background: #f9f9f9;
				border: 1px dashed #c8c8c8;
			}
		}

		&__info {
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 2.5vh;
			justify-content: space-between;

			@media only screen and (max-width: 767px) {
				flex-direction: column;
				align-items: flex-start;
				gap: 15px;
			}

			&_recent {
				margin: 0;
				font-size: 22px;
				color: #a4a4a4;
				font-weight: 600;
				line-height: 30px;
				font-family: "Inter", sans-serif;
				letter-spacing: -0.01em;

				@media (max-width: 870px) {
					font-size: 20px;
					line-height: 28px;
				}
			}

			&__buttons {
				display: flex;
				align-items: center;

				@media only screen and (max-width: 767px) {
					flex-direction: column;
					align-items: flex-start;
					width: 100%;
					gap: 15px;
				}

				&_view {
					font-size: 14px;
					font-weight: 600;
					color: #808080;
					line-height: 24px;
					padding: 8px 12px;
					text-align: center;
					border-radius: 4px;
					min-width: max-content;
					font-family: "Poppins";
					border: 1px solid #808080;

					@media (max-width: 870px) {
						padding: 5px 16px;
					}
				}

				&_create {
					display: flex;
					border: none;
					font-size: 14px;
					font-weight: 600;
					color: #ffffff;
					line-height: 24px;
					margin-left: 16px;
					padding: 8px 12px;
					text-align: center;
					border-radius: 4px;
					max-width: max-content;
					font-family: "Poppins";

					@media (max-width: 870px) {
						padding: 6px 16px;
					}

					@media only screen and (max-width: 767px) {
						margin-left: 0;
						margin-bottom: 20px;
					}

					&_icon {
						margin-right: 8px;
					}
				}
			}
		}

		&__empty {
			font-family: "Inter", sans-serif;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 53px 0;
			border: 1px dashed #c8c8c8;
			border-radius: 4px;
			letter-spacing: -0.01em;

			&__title {
				font-weight: 600;
				font-size: 24px;
				line-height: 32px;
				text-align: center;
				color: #383838;
				margin-bottom: 4px;
			}

			&__description {
				font-size: 16px;
				line-height: 24px;
				text-align: center;
				color: #5a5a5a;
				margin: 0 0 16px;
			}

			&__btn {
				margin-left: 0;
			}

			&__icon_bg {
				width: 40px;
				height: 40px;
				background: #e8f3eb;
				border-radius: 4px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			&__skeleton {
				width: 0;
				height: 40px;
			}
		}
	}
}

.project {
	width: 100%;
	padding: 24px;
	color: black;
	max-width: 384px;
	text-align: left;
	min-height: 206px;
	border-radius: 4px;
	background: #ffffff;
	box-shadow: 0px 9px 31px rgba(0, 0, 0, 0.04);

	@media only screen and (max-width: 767px) {
		width: 100%;
		max-width: 100%;
	}

	@media only screen and (min-width: 768px) and (max-width: 1023px) {
		width: 49%;
		max-width: 49%;
	}

	@media only screen and (min-width: 1024px) {
		width: 33%;
		max-width: 33%;
	}

	&__head {
		display: flex;
		position: relative;
		margin-bottom: 4px;
		align-items: center;
		justify-content: space-between;

		&_title {
			margin: 0;
			font-size: 22px;
			min-height: 30px;
			font-weight: 600;
			color: #383838;
			line-height: 30px;
			font-family: "Inter", sans-serif;
			letter-spacing: -0.01em;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 80%;
		}

		&__settings {
			top: 35px;
			gap: 14px;
			z-index: 3;
			right: -16px;
			display: flex;
			padding: 16px;
			position: absolute;
			border-radius: 4px;
			background: #ffffff;
			flex-direction: column;
			box-shadow: 0px 0px 31px rgba(0, 0, 0, 0.08);

			&_arrow {
				top: -8px;
				z-index: 2;
				right: 22px;
				position: absolute;
			}

			&_item {
				margin: 0;
				display: flex;
				font-size: 14px;
				font-weight: 400;
				color: #404040;
				line-height: 22px;
				align-items: center;
				font-family: "Inter", sans-serif;
				letter-spacing: -0.01em;

				svg {
					width: 24px;
					height: 24px;
					margin-right: 8px;
				}
			}

			&_item:hover {
				background-color: #f9f9f9;
			}
		}
	}

	&_description {
		margin: 0;
		font-size: 16px;
		min-height: 24px;
		color: #383838;
		font-weight: 400;
		line-height: 24px;
		font-family: "Inter", sans-serif;
		letter-spacing: -0.01em;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 80%;
	}

	&__company_name {
		margin: 0;
		font-size: 14px;
		min-height: 22px;
		font-weight: 400;
		color: #5a5a5a;
		line-height: 22px;
		margin-bottom: 16px;
		font-family: "Inter", sans-serif;
		letter-spacing: -0.01em;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 100%;
	}

	&__statistic {
		display: flex;
		align-items: center;
		min-width: max-content;
		justify-content: space-between;

		:first-child {
			padding-left: 0;
		}

		:last-child {
			border-right: none;
		}

		&__item {
			width: 100%;
			max-width: 106px;
			padding-left: 16px;
			min-width: max-content;
			border-right: 1px solid #e9e9e9;

			&_text {
				font-size: 14px;
				font-weight: 500;
				color: #5a5a5a;
				line-height: 22px;
				font-family: "Inter", sans-serif;
			}

			&_count {
				margin: 0;
				margin-top: 8px;
				font-size: 24px;
				color: #383838;
				font-weight: 600;
				line-height: 28px;
				padding-left: 6px;
				font-family: "Inter", sans-serif;
				letter-spacing: -0.01em;
				border-left: 2px solid #c8c8c8;
			}
		}
	}
}

.info_tooltip {
	opacity: 1;
	z-index: 123;
	padding: 16px;
	color: black;
	width: 328px;
	background-color: white;
	//box-shadow: 0px 9px 31px rgba(0, 0, 0, 0.04);
	box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);

	&_title {
		font-size: 14px;
		color: #271b00;
		font-weight: 500;
		margin: 0 0 8px 0;
		line-height: 22px;
		font-family: "Inter", sans-serif;
	}

	&__description {
		display: block;
		font-size: 14px;
		line-height: 20px;
		font-weight: 400;
		color: #404040;
		margin-bottom: 16px;
		font-family: "Inter", sans-serif;

		&_green {
			font-weight: 600;
			color: #0c5850;
			text-decoration: underline;
		}

		&_dark {
			color: #4e5555;
			font-weight: 600;
		}
	}

	&__buttons {
		display: flex;
		align-items: center;

		button {
			font-family: "Inter", sans-serif;
			font-weight: 600;
			font-size: 12px;
			line-height: 24px;
			color: #404040;
		}

		&_left {
			border: 1px solid #f4f0ed;
			border-radius: 4px 0 0 4px;
			padding: 0 8px 0 4px;

			&_one {
				border-radius: 4px;
			}
		}

		&_right {
			padding: 0 4px 0 8px;
			border: 1px solid #f4f0ed;
			border-left: none;
			border-radius: 0 4px 4px 0;

			&_once {
				border-radius: 4px;
				border: 1px solid #f4f0ed;
			}
		}
	}
}

.is_show {
	display: block;
}

.is_not_show {
	display: none;
}

.searchBarContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.quickSearchButton {
	margin-top: 20px;
}
