.promo {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	//padding-bottom: 20px;
	margin-left: -20px;
	top: 5px;

	.mapxLogo {
		margin-left: 0;
		margin-bottom: 24px;
	}

	.upgradeMessage {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		color: var(--brand-neutral-grey-800, #2c3030);
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px; /* 133.333% */
	}
}

.upgradeNowButton {
	margin-top: 24px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 5px;
	height: 40px;
	padding: 10px 16px;

	color: var(--brand-primary-50, #edf2f2);

	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 142.857% */
}
