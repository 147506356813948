.inter {
	display: inline;

	* {
		font-family: Inter, sans-serif !important;
	}
}

#root {
	height: 100%;

	.inter {
		height: 100%;
	}
}

//
//           Inter font family
//
@font-face {
	font-family: "Inter";
	font-weight: 100;
	src: local("Inter"), url(../assets/fonts/InterThin.ttf) format("truetype");
}

@font-face {
	font-family: "Inter";
	font-weight: 200;
	src: local("Inter"), url(../assets/fonts/InterExtraLight.ttf) format("truetype");
}

@font-face {
	font-family: "Inter";
	font-weight: 300;
	src: local("Inter"), url(../assets/fonts/InterLight.ttf) format("truetype");
}

@font-face {
	font-family: "Inter";
	font-weight: 400;
	src: local("Inter"), url(../assets/fonts/InterRegular.ttf) format("truetype");
}

@font-face {
	font-family: "Inter";
	font-weight: 500;
	src: local("Inter"), url(../assets/fonts/InterMedium.ttf) format("truetype");
}

@font-face {
	font-family: "Inter";
	font-weight: 600;
	src: local("Inter"), url(../assets/fonts/InterSemiBold.ttf) format("truetype");
}

@font-face {
	font-family: "Inter";
	font-weight: 700;
	src: local("Inter"), url(../assets/fonts/InterBold.ttf) format("truetype");
}

@font-face {
	font-family: "Inter";
	font-weight: 800;
	src: local("Inter"), url(../assets/fonts/InterExtraBold.ttf) format("truetype");
}

@font-face {
	font-family: "Inter";
	font-weight: 900;
	src: local("Inter"), url(../assets/fonts/InterBlack.ttf) format("truetype");
}

//
//           Poppins font family
//
@font-face {
	font-family: "Poppins";
	font-weight: 100;
	src: local("Poppins"), url(../assets/fonts/Poppins/PoppinsThin.ttf) format("truetype");
}
@font-face {
	font-family: "Poppins";
	font-weight: 200;
	src: local("Poppins"), url(../assets/fonts/Poppins/PoppinsExtraLight.ttf) format("truetype");
}
@font-face {
	font-family: "Poppins";
	font-weight: 300;
	src: local("Poppins"), url(../assets/fonts/Poppins/PoppinsLight.ttf) format("truetype");
}
@font-face {
	font-family: "Poppins";
	font-weight: 400;
	src: local("Poppins"), url(../assets/fonts/Poppins/PoppinsRegular.ttf) format("truetype");
}
@font-face {
	font-family: "Poppins";
	font-weight: 500;
	src: local("Poppins"), url(../assets/fonts/Poppins/PoppinsMedium.ttf) format("truetype");
}
@font-face {
	font-family: "Poppins";
	font-weight: 600;
	src: local("Poppins"), url(../assets/fonts/Poppins/PoppinsSemiBold.ttf) format("truetype");
}
@font-face {
	font-family: "Poppins";
	font-weight: 700;
	src: local("Poppins"), url(../assets/fonts/Poppins/PoppinsBold.ttf) format("truetype");
}
@font-face {
	font-family: "Poppins";
	font-weight: 800;
	src: local("Poppins"), url(../assets/fonts/Poppins/PoppinsExtraBold.ttf) format("truetype");
}
@font-face {
	font-family: "Poppins";
	font-weight: 900;
	src: local("Poppins"), url(../assets/fonts/Poppins/PoppinsBlack.ttf) format("truetype");
}
