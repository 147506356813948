.formContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;

	&.withPadding {
		padding: 48px;
	}

	form {
		display: flex;
		flex-direction: column;
		align-self: stretch;
		gap: 16px;
		width: 100%;
	}
}

.itemBlock {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	align-self: stretch;
}

.label {
	color: var(--Brand-Neutral-Grey-400, #868d8d);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
}

.customSelectDropdown {
	display: flex;
	height: 40px;
	padding: 8px 8px 8px 16px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border-radius: 4px;
	border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);

	/* Shadows On Light/L */
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
}

.dropdownItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	img {
		height: 24px;
		border-radius: 2px;
		border: 1px solid var(--System-Silver-Chalice-50, #f8f7f7);
		background: transparent lightgray 50% / cover no-repeat;
	}

	span {
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 14px;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
	}
}
