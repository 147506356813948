.container {
	width: 100%;
	padding: 24px;
	max-width: 564px;
	border-radius: 4px;
	background: #ffffff;
	border: 1px solid #eeedec;

	&__title {
		font-size: 18px;
		font-weight: 500;
		line-height: 26px;
		margin: 0 0 24px 0;
		font-family: "Inter";
	}

	&__charts {
		gap: 24px;
		display: flex;
		justify-content: space-between;

		&__wrapper {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			&__title {
				font-size: 18px;
				font-weight: 500;
				line-height: 26px;
				text-align: center;
				margin: 16px 0 4px;
				font-family: "Inter";
			}

			&__description {
				font-size: 12px;
				font-weight: 500;
				color: #808080;
				line-height: 20px;
				text-align: center;
				font-family: "Inter";
			}
		}

		&_is_load {
			min-height: 210px;
			max-height: 210px;
		}
	}
}

.round {
	width: 100%;
	display: flex;
	max-width: 112px;
	min-height: 112px;
	border-radius: 50%;
	position: relative;
	align-items: center;
	justify-content: center;

	&__value {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		height: 27px;
		display: block;
		font-size: 22px;
		font-weight: 500;
		line-height: 27px;
		text-align: center;
		position: absolute;
		font-family: "Inter";
	}
}
