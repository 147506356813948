.wrapper {
	display: flex;
	align-items: flex-start;
	width: 100%;
}

.barContainer {
	flex: 1;
	height: 8px;
	background: #f4f0ed;
	overflow: hidden; /* Crucial part */
	border-radius: 4px;

	&.mini {
		height: 4px;
		border-radius: 3px;
	}
}

.textValue {
	margin-left: 8px;
	width: 42px;
	text-align: right;
}

.fillerBackground {
	//width: 100%;
	height: inherit;
	position: relative;
	transition: width 2s ease-in-out;
	border-radius: 4px;

	&::after {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: "";
		position: absolute;
		border-radius: 3px;
		transform: translateX(-100%);
		animation: shimmer 2s infinite;
		background: linear-gradient(270deg, #0c5850 0%, #29968a 48.44%, #0c5850 100%);
	}

	@keyframes shimmer {
		100% {
			transform: translateX(0%);
		}
	}
}

.filler {
	transition: width 2s ease-in-out;
	height: inherit;
	border-radius: inherit;
	overflow: hidden;
}
