@import "styles/common.module.scss";

.genderDoughnut {
	width: 100%;
}

.doughnutLabels {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	width: 100%;
	justify-content: center;
	z-index: 100 !important;

	.doughnutLabel {
		display: flex;
		flex-direction: column;
		margin: 0 20px;
		align-items: center;

		span:first-child {
			font-size: 18px;
		}

		span:last-child {
			font-size: 30px;
		}
	}

	.labelText {
	}

	.male {
		color: #5b9fc9;
	}

	.female {
		color: #68b68a;
	}
}
