@import "styles/common.module.scss";

.company {
	background: #ffffff;
	//border: 1px solid #EEEDEC;
	box-shadow: 1px 3px 4px 2px rgba(79, 75, 74, 0.05);
	border-radius: 4px;
	padding: 20px;
	margin-bottom: 1.5rem;
	display: flex;
	flex-direction: row;

	@media #{$viewportMobileS} {
		padding: 0.6em;
	}
	@media #{$viewportMobile} {
		padding: 0.6em;
	}
	@media #{$viewportMobileX} {
		padding: 0.8em;
	}
	@media #{$viewportSmall} {
		padding: 0.9em;
	}
	@media #{$viewportMedium} {
		padding: 1em;
	}
	@media #{$viewportTablet} {
		padding: 1em;
	}
	@media #{$viewportMinLarge} {
		padding: 20px;
	}

	@media only screen and (max-width: 1024px) {
		flex-direction: column;
	}

	&.recommended {
		background: rgba(243, 255, 254, 1);
		border: 1px solid #0c5850;
		position: relative;

		@media #{$viewportMobileS} {
			padding: 0.6em;
		}
		@media #{$viewportMobile} {
			padding: 0.6em;
		}
		@media #{$viewportMobileX} {
			padding: 0.8em;
		}
		@media #{$viewportSmall} {
			padding: 0.9em;
		}
		@media #{$viewportMedium} {
			padding: 1em;
		}
		@media #{$viewportTablet} {
			padding: 1em;
		}
		@media #{$viewportMinLarge} {
			padding: 34px 20px 24px 30px;
		}

		.enhanceIcon {
			position: absolute;
			left: 0;
			top: 0;
			background: #0c5850;
			opacity: 0.8;
			border-radius: 0 0 8px 0;
			padding: 1.5px 7px 4.5px 7px;
		}
	}
}

.leftContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	.companyInfo {
		display: flex;
		flex-direction: row;

		.logo {
			display: flex;
			margin-right: 15px;

			img {
				width: 50px;
				height: 50px;
				border-radius: 30px;
			}
		}

		.introduction {
			display: flex;
			flex-direction: column;
			gap: 7px;

			.name {
				font-weight: 700;
				font-size: 18px;
				line-height: 20px;
				color: rgba(39, 27, 0, 1);
			}

			.industry {
				font-weight: 600;
				font-size: 14px;
				line-height: 17px;
				color: #404040;
			}
		}
	}

	.location {
		display: flex;
		flex-direction: row;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #000000;
		margin-top: 25px;
		gap: 10px;

		@media (max-width: 1024px) {
			flex-direction: column;
		}

		.headquarters {
			color: rgba(39, 27, 0, 1);
		}
	}

	.specialities {
		margin-top: 10px;
	}
}

.rightContainer {
	flex: 2;
	display: flex;
	flex-direction: column;

	.shortStats {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 40px;
		margin-top: 10px;
		margin-bottom: 10px;

		@media only screen and (min-width: 1025px) {
			margin-top: 0;
			margin-bottom: 25px;
			justify-content: flex-end;
		}

		.label {
			font-weight: 400;
			font-size: 18px;
			line-height: 22px;
			color: rgba(39, 27, 0, 1);
			margin-right: 10px;

			@media only screen and (max-width: 1024px) {
				font-size: 14px;
			}
		}

		.value {
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			color: #0c5850;

			@media only screen and (max-width: 1024px) {
				font-size: 14px;
			}
		}
	}

	.description {
		display: flex;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #000000;
		margin-bottom: 15px;
		min-height: 50px;
	}

	.targetListButton {
		display: flex;
		justify-content: flex-end;

		.saveButton {
			max-width: 128px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			color: #ffffff;
		}
	}
}
