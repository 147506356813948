.jobTitleContainer {
	display: flex;
	flex-direction: column;
	padding: 16px 5px 16px 16px;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 4px;
	background: var(--system-bw-white, #fff);
	position: relative;

	.titleContainer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		.textTitle {
			color: var(--Brand-Primary-600-B, #0c5850);
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: 28px; /* 155.556% */
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 8px;
		}

		&:hover {
			cursor: pointer;
		}
	}
}

.arrowIcon {
	&:hover {
		cursor: pointer;
	}
}

.rotated {
	transform: rotateX(180deg);
}

.inputContainer {
	display: none;
	flex-direction: column;
	gap: 16px;
	transition: opacity 0.3s ease, transform 0.3s ease;
	opacity: 0;
	transform: translateY(-10px);
	width: 100%;
	height: 0;

	&.open {
		margin-top: 16px;
		opacity: 1;
		transform: translateY(0); // Slides into view when opened
		height: max-content;
		display: flex;
	}
}

.containSwitch {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	color: var(--Brand-Neutral-Grey-400, #868d8d);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */

	.active {
		color: var(--Brand-Neutral-Grey-900, #181b1a);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
	}
}

.count {
	color: var(--Grey-Grey404, #404040);
	text-align: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 166.667% */

	height: 24px;
	padding: 2px 5px 4px 5px;
	width: max-content;
	border-radius: 4px;
	background: var(--System-Silver-Chalice-100, #eeedec);
}
