.wrapper {
	border-radius: 4px;
	background: #fff;
	margin-bottom: 82px;
	padding-bottom: 24px;
	box-shadow: 0px 17px 33px -2px rgba(79, 75, 74, 0.05);
}

.create {
	gap: 8px;
	width: 100%;
	border: none;
	display: flex;
	font-size: 14px;
	max-width: 220px;
	font-weight: 600;
	padding: 8px 16px;
	line-height: 24px;
	border-radius: 4px;
	align-items: center;
}

.head {
	display: flex;
	padding: 15px 24px;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #eeedec;
}

.title {
	margin: 0;
	font-size: 18px;
	color: #271b00;
	font-weight: 500;
	line-height: 22px;
}

.icon {
	display: flex;
	max-width: 22px;
	min-width: 22px;
	max-height: 22px;
	min-height: 22px;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	border: 2px solid #fff;

	svg {
		flex-shrink: 0;
		path {
			stroke: #fff;
		}
	}
}

.saveButton {
	border: 1px solid rgba(12, 88, 80, 1);
	color: #0c5850;

	svg {
		margin-right: 8px;
	}
}
