.container {
	display: flex;
	flex-direction: column;
	padding: 32px 0;
	gap: 24px;
	margin-top: 60px;
}

.section {
	border-radius: 4px;
	background: var(--system-bw-white, #fff);
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
	flex: 1 1 0;
	height: max-content;
}

.sectionTitle {
	color: var(--Brand-Neutral-Grey-800, #2c3030);
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 155.556% */
	border-bottom: 1px solid #eeedec;
	padding: 16px;
}

.sectionContent {
	padding: 16px;
	display: flex;
	flex-direction: column;

	.buttonsWrapper {
		display: flex;
		flex-direction: row;
		gap: 8px;
	}

	.actionsColumn {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		align-content: center;
		flex-wrap: wrap;
		padding-top: 16px;
	}

	.reportColumn {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 0 16px 16px 16px;
		border-bottom: 1px solid #eeedec;
		margin: 0 -16px;

		.reportTitle {
			color: var(--Brand-Neutral-Grey-600-B, #4e5555);
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;

			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			.templateSelection {
				width: max-content;
				display: flex;
				flex-direction: row;

				.dropdownPlaceholder {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					gap: 16px;
					width: 100%;

					color: var(--Brand-Neutral-Grey-900, #4e5555);

					span {
						color: var(--Brand-Neutral-Grey-400, #4e5555);
					}
				}
			}
		}
	}
}

.downloadButton {
	display: flex;
	padding: 9px 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 4px;
	border: 1px solid var(--Brand-Primary-600-B, #0c5850);
	background: var(--Brand-Primary-600-B, #0c5850);

	color: var(--Brand-Primary-50, #edf2f2);
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 142.857% */

	&.disabled {
		opacity: 0.7;
		pointer-events: none;
	}

	&:hover {
		cursor: pointer;
	}
}

.addStatusButton {
	display: flex;
	padding: 9px 16px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 4px;
	border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
	background: #ffffff;

	color: var(--Brand-Primary-600-B, #0c5850);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 142.857% */

	&:hover {
		cursor: pointer;
	}
}

.downloadIcon {
	color: #2c3030;
	//margin-right: 8px;
}

.statusDropdown {
	display: flex;
	padding: 4px 8px 4px 16px;
	justify-content: space-between;
	align-items: center;
	flex: 1 0 0;
	border-radius: 4px;
	border: 1px solid var(--System-Silver-Chalice-400, #b5b1b0);
}
