.container {
	position: fixed;
	bottom: 0;
	right: 20px;
	width: 300px;
	margin: 0;
	height: max-content;
	display: flex;
	flex-direction: column;
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 25px 60px -10px rgba(79, 75, 74, 0.24);
	z-index: 3;
}

.checklistHeader {
	display: flex;
	padding: 16px;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	border-radius: 4px 4px 0 0;
	border-bottom: 1px solid var(--System-Silver-Chalice-100, #eeedec);

	&:hover {
		cursor: pointer;
	}

	.title {
		display: flex;
		align-items: center;
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px; /* 150% */
		gap: 4px;
	}

	.checklistCount {
		display: flex;
		gap: 4px;
		flex-direction: row;

		span {
			display: flex;
			height: 20px;
			min-width: 20px;
			padding: 4px;
			justify-content: center;
			align-items: center;
			gap: 8px;

			color: var(--Brand-Neutral-Grey-800, #2c3030);
			text-align: center;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px; /* 166.667% */
			border-radius: 4px;
			background: var(--System-Silver-Chalice-100, #eeedec);
		}
	}
}

.checklist {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	gap: 12px;
	border-radius: 4px 4px 0px 0px;
	border-bottom: 1px solid var(--System-Silver-Chalice-100, #eeedec);
	background: var(--system-bw-white, #fff);
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;

	&.opened {
		padding: 20px 16px;
		height: max-content;
		max-height: 540px;
	}

	.completed {
		.completedLabelText span {
			color: var(--Brand-Neutral-Grey-300, #b0b5b4);
			text-decoration-line: line-through;
		}

		.completedLabelDescription span {
			color: var(--Brand-Neutral-Grey-300, #b0b5b4);
			text-decoration-line: line-through;
		}
	}
}

.viewButton {
	margin-top: 6px;
	display: flex;
	padding: 4px 8px;
	align-items: flex-start;
	gap: 4px;
	border-radius: 24px;
	border: 1px solid var(--Brand-Primary-600-B, #0c5850);
	background: #ffffff;

	color: var(--Brand-Primary-600-B, #0c5850);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 142.857% */
	text-decoration-line: none !important;

	> * {
		text-decoration-line: none !important;
	}

	&:hover {
		cursor: pointer;
	}
}

.toggleIcon {
	transition: transform 0.8s linear;

	&:hover {
		cursor: pointer;
	}

	&.rotated {
		transform: rotateX(180deg);
	}
}

.skeletonContainer {
	padding: 16px;

	.skeletonCard {
	}
}
