@import "styles/common.module.scss";

.title {
	width: 100% !important;
	text-align: left;
	cursor: pointer;
	outline: none;
	margin: 0;
	border: 0;
	padding-left: 10px;
	padding-right: 10px;
	padding-bottom: 8px;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #a39c9a;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
}

.tabTitle {
	cursor: pointer;
	min-width: 80px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	background: rgba(255, 255, 255, 0.37);
	opacity: 0.85;

	@media (max-width: 768px) {
		margin-right: 0;
		padding-right: 0;
	}

	.count {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 2px 4px !important;
		background: #f4f0ed;
		border-radius: 4px;
		position: unset;
		font-weight: 600;
		font-size: 12px;
		line-height: 20px;
		color: #2c3030;
	}
}

.activeTab {
	color: #2c3030 !important;
	border-bottom: 1px solid #271b00;

	//border-bottom: 1px solid var(--system-silver-chalice-100, #eeedec);
	background: #ffffff !important;

	svg {
		path {
			fill: #271b00 !important;
		}
	}
}
