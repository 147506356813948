.chartCardContainer {
	border-bottom: 1px solid #eeedec;
	border-right: 1px solid #eeedec;
	padding: 16px;
	display: flex;
	position: relative;
	align-items: center;
	height: 100px;
	background: #fff;
	z-index: 2;

	.about {
		display: flex;
		flex-direction: column;
	}

	img {
		width: 48px;
		height: 48px;
		margin-right: 25px;
		object-fit: cover;
	}

	.name {
		font-size: 14px;
		font-weight: 500;
		font-family: Inter, sans-serif !important;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.position {
		font-size: 12px;
		font-weight: 400;
		font-family: Inter, sans-serif !important;
		margin-top: 3px;

		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	.expand {
		color: #3c6662;
		margin-left: 10px;
	}
}

.expanded {
	height: unset;
	min-height: 100px;
}

.positions {
	display: flex;
	flex-direction: column;
}

.newlyMapped {
	background: rgba(12, 88, 80, 0.05);
	mix-blend-mode: multiply;
	border-left: 2px solid #0c5850;
}
