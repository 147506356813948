.wrapper {
	gap: 16px;
	top: 34px;
	z-index: 3;
	right: -14px;
	padding: 16px;
	display: flex;
	width: max-content;
	position: absolute;
	border-radius: 4px;
	flex-direction: column;
	background-color: #fff;
	box-shadow: 0px 0px 31px rgba(0, 0, 0, 0.08);

	&__arrow {
		top: -8px;
		right: 18px;
		position: absolute;
	}

	&__item {
		gap: 10px;
		display: flex;
		cursor: pointer;

		&_disabled {
			cursor: default;

			p {
				color: #a39c9a;
			}

			svg {
				stroke: #f4f4f4;

				path {
					fill: #a39c9a;
				}
			}
		}

		&_label {
			margin: 0;
			font-weight: 400;
			font-size: 14px;
			line-height: 22px;
			color: #404040;
		}

		&_label__remove {
			color: #c91515;
		}
	}
}
