.wrapper {
	padding: 24px 16px 16px 16px;
	border-radius: 4px;
	border: 1px solid #dad8d7;
	display: flex;
	flex-direction: column;
}

.title {
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
	color: #2c3030;
	user-select: none;
}

.description {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	color: #4e5555;
	margin: 16px 0 8px 0;
	min-height: 50px;
}

.discount {
	display: flex;
	user-select: none;
	height: 20px;

	.regularPrice {
		color: #868d8d;
		font-family: Inter;
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
		text-decoration: line-through;
	}
	.discountPercentage {
		margin-left: 12px;
		border-radius: 16px;
		background: #493c70;
		color: #eeecf5;
		font-size: 12px;
		font-weight: 600;
		line-height: 20px;
		padding: 0 6px;
	}
}

.cost {
	display: flex;
	align-items: center;

	.price {
		font-size: 36px;
		font-style: normal;
		font-weight: 600;
		line-height: 44px;
		color: #2c3030;
	}

	.unit {
		user-select: none;
		margin-left: 4px;

		p {
			color: #4e5555;
			font-size: 13px;
			line-height: 18px;
			margin: 0;
		}
	}
}

.button {
	width: 100%;
	height: 48px;
	font-size: 14px;
	background-color: #0c5850;
	margin: 8px 0 0 0;
}

.button.secondary {
	color: #0c5850;
	background-color: transparent;
	border-color: #0c5850;
}

.freeTrial {
	background: #fcf7f0;
	border-radius: 4px;
	border: 1px solid #e0a764;
	box-sizing: border-box;
	color: #e0a764;
	font-size: 13px;
	font-weight: 600;
	height: 22px;
	margin-top: auto;
	text-align: center;
	user-select: none;

	&.hidden {
		visibility: hidden;
	}
}

.features {
	color: #2c3030;
	font-size: 14px;
	line-height: 20px;
	margin-top: 12px;

	.feature {
		margin-top: 8px;
		display: flex;
		align-items: center;

		svg {
			margin-right: 8px;
			flex-shrink: 0;
		}
	}
}
