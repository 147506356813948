.head {
	display: flex;
	padding: 0 16px;
	align-items: center;
	background-color: #fff;
	justify-content: space-between;

	&__text {
		font-weight: 500;
		font-size: 22px;
		line-height: 30px;
		color: #000000;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 100%;
	}

	&__right {
		gap: 8px;
		display: flex;
		align-items: center;

		button {
			color: #fcf7f0;
			font-size: 13px;
			font-weight: 600;
			border-radius: 4px;
			line-height: 18px;
		}

		&__shared {
			position: relative;

			&_btn {
				cursor: default;
				padding: 4px 8px;
				background-color: #e0a764;

				&_z_index {
					z-index: 33;
					cursor: default;
					position: relative;
				}
			}

			&__boarding {
				top: 44px;
				right: -2px;
			}
		}

		&__edit {
			height: 28px;
		}

		&__button {
			gap: 8px;
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
			color: #808080 !important;
			border: 1px solid #808080;
			border-radius: 4px;
			max-height: 28px;
			padding: 8px 16px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.tabs {
	gap: 30px;
	display: flex;
	align-items: center;
	background-color: #fff;
	padding: 10px 10px 0 10px;

	button {
		gap: 8px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #a39c9a;
		display: flex;
		padding-bottom: 12px;
	}

	&__active {
		padding-bottom: 10px;
		color: #2c3030 !important;
		border-bottom: 2px solid #271b00;

		svg {
			fill: #2c3030;
			stroke: #2c3030;
		}
	}
}

.overview {
	display: flex;
	margin-top: 24px;
	flex-direction: column;

	&__counts {
		gap: 16px;
		display: flex;
		justify-content: space-between;

		&__item {
			width: 32%;
			display: flex;
			background: #fff;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			padding: 10px 0;
			box-shadow: 0px 9px 31px rgba(0, 0, 0, 0.2);

			&__count {
				margin: 0;
				font-weight: 500;
				font-size: 36px;
				line-height: 44px;
				display: flex;
				align-items: center;
				text-align: center;
				color: #0c5850;
			}

			&__name {
				margin: 0;
				font-weight: 700;
				font-size: 14px;
			}
		}
	}
}
