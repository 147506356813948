@import "styles/common.module.scss";

// Info Section

.infoTitle {
	color: #271b00;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;

	&.clickable {
		cursor: pointer;
	}
}

.candidateLocation {
	display: flex;
	flex-direction: column;
}

.candidateTop {
	display: flex;
	align-items: center;
	margin-bottom: 5px;

	@media (max-width: 1024px) {
		margin-bottom: 15px;
		flex-direction: column;
		align-items: flex-start;
	}

	@media (max-width: 767px) {
		width: 180px;
	}

	> div {
		@media (min-width: 768px) {
			margin-right: 10px;
		}
	}

	&.brief {
		flex-direction: column;
		align-items: flex-start;

		@media screen and (max-width: 767px) {
			width: 180px;
		}
	}
}

.currentJob {
	span,
	b {
		margin-right: 8px;
	}

	b {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
	}

	span {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
	}

	.from {
		color: #271b00;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
	}

	div {
		display: inline-block;
	}
}

.prevJob {
	span,
	b {
		margin-right: 5px;
	}

	b {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
	}

	span {
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
	}

	.from {
		color: #271b00;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
	}

	div {
		display: inline-block;
	}
}

.viewOnLinkedin {
	font-weight: 300;
	font-size: 12px;
	line-height: 16px;
	color: #808080;
	margin-left: 20px;

	@media (max-width: 768px) {
		margin-left: 0;
	}
}

.avatarContainer {
	position: relative;

	.companyLogo {
		position: absolute;
		right: 11px;
		bottom: -7px;
		height: 27px;
		width: 27px;
		border-radius: 50%;
		object-fit: cover;
	}
}

.infoDetail {
	color: #404040;
	font-size: 12px;
}

.infoCategory {
	margin-top: 0.7rem;
}

.infoCategoryTitle {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 200% */
	margin-bottom: 10px;
	letter-spacing: 0.05em;
	// text-transform: uppercase;

	/* SG Black 50% */

	color: rgba(0, 0, 0, 0.5);
}

.infoCategoryButton {
	background: $candidateIndustryButtonBackground;
	color: rgba(0, 0, 0, 0.5);
	height: $candidateIndustryButtonHeight;
	margin-right: 10px;
	border-radius: 3px;
	font-size: $candidateIndustryButtonFontSize;
	font-weight: 400;
	border: $candidateIndustryButtonBorder;
	margin-bottom: 10px;
	cursor: pointer;

	&.selected {
		background: #d6f0ee !important;
		color: #009882 !important;
	}
}

.infoFirstRow {
	display: flex;
	flex-direction: row;
	align-items: center;

	@media screen and (max-width: 1024px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
	}
}

.logo {
	width: 60px;
	height: 60px;
	margin-right: 15px;
	border-radius: 50%;
	border: 1px solid #d1cdcc;
	object-fit: cover;

	&.clickable {
		cursor: pointer;
	}
}

.iconLogo {
	width: 60px;
	height: 60px;
	margin-right: 15px;
	border-radius: 50%;
	padding: 16px;
	border: 1px solid #d1cdcc;
	object-fit: cover;

	&.clickable {
		cursor: pointer;
	}
}

.informationContainer {
	min-width: 33%;
	flex-direction: column;
}

.buttonText {
	display: flex;
	justify-content: center;
	align-items: center;

	color: rgba(0, 0, 0, 0.5);
	position: relative;
	//right: -10px;
}

.circularBorderWrapper {
	position: relative;
	display: inline-block;
	border-radius: 100%;
	overflow: hidden;
	border: 4px solid #e7e7e7 !important;
	padding: 5px;
	animation: pulse-border 2s infinite ease-in-out;
}

@keyframes pulse-border {
	0% {
		border-color: rgba(255, 255, 255, 0.2);
		transform: scale(1);
	}
	50% {
		border-color: rgba(255, 255, 255, 0.6);
		transform: scale(1.09);
	}
	100% {
		border-color: rgba(255, 255, 255, 0.2);
		transform: scale(1);
	}
}

.rotatingWrapper {
	position: relative;
	display: inline-block;
	width: 56px;
	height: 56px;
	margin-right: 16px;
}

.rotatingWrapper::after {
	content: "";
	position: absolute;
	top: -3px;
	left: -3px;
	right: -3px;
	bottom: -3px;
	border-radius: 50%;
	background: conic-gradient(rgba(134, 141, 141, 1) 0% 75%, transparent 75% 100%);
	animation: rotate 4s linear infinite;
	-webkit-mask: radial-gradient(circle, transparent 57%, rgba(134, 141, 141, 1) 73%);
	mask: radial-gradient(circle, transparent 57%, rgba(134, 141, 141, 1) 73%);
}

.layLoadImage {
	position: relative;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
