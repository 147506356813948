.searchWrapper {
	position: relative;
	display: flex;
	margin-bottom: 5px;

	.loader {
		position: absolute;
		top: 25px;
		right: 5px;
	}
}

.errorClass {
	bottom: -22px;
}
