.footer {
	display: flex;
	padding: 16px 24px;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	border-top: 1px solid #dad8d7;

	.goBack {
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
		text-decoration-line: underline;
		text-underline-offset: 3px;

		&:hover {
			cursor: pointer;
		}
	}

	.inActive {
		&:hover {
			cursor: no-drop;
		}
	}
}

.continueButton {
	display: flex;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	border-radius: 4px;
	background: #0c5850;

	svg {
		margin-right: 0 !important;
	}

	&:hover {
		cursor: pointer;
	}
}
