.message {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 4px;
	font-size: 14px;
	margin-bottom: 32px;
	padding: 10px 16px;

	&.__success {
		background-color: #dff8e6;
		color: #2ba44f;
	}

	&.__error {
		background-color: #ffe0e0;
		color: #e05a5a;
	}

	svg {
		flex-shrink: 0;
		margin-right: 10px;
	}
}
