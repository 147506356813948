.wrapper {
	gap: 8px;
	display: flex;
	max-height: 198px;
	overflow-y: auto;
	padding: 0 12px;
	flex-direction: column;

	&::-webkit-scrollbar {
		width: 4px;
		background-color: #fff;
	}

	&::-webkit-scrollbar-thumb {
		width: 4px;
		background: #3c6662;
		border-radius: 2px;
	}
	&__children {
		display: flex;
		justify-content: space-between;
		margin-left: 36px;

		&_name {
			margin: 0;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: #4e5555;
		}

		&_percent {
			margin: 0;
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			color: #2c3030;

			&:last-child {
				margin-bottom: 8px;
			}
		}
	}

	&__item {
		display: flex;
		position: relative;
		min-height: 28px;
		border-radius: 2px;
		cursor: pointer;

		&_empty {
			cursor: default;
		}

		&_background {
			height: 100%;
			opacity: 0.32;
			position: absolute;
			border-radius: 2px;
		}

		&_content {
			position: absolute;
			display: flex;
			padding-left: 9px;
			justify-content: space-between;
			width: 100%;
			height: 100%;
			align-items: center;

			&_icon_active {
				transform: rotate(180deg);
			}

			&_name {
				display: flex;
				align-items: center;
				gap: 11px;
				margin: 0;
				font-size: 14px;
				line-height: 20px;
				color: #4e5555;

				&_active {
					font-weight: 600;
				}
			}

			&_percent {
				margin: 0;
				font-weight: 600;
				font-size: 14px;
				line-height: 20px;
				color: #2c3030;
			}
		}
	}
}
