@import "styles/common.module.scss";

.buttonContainer {
	user-select: none;
	position: relative;
	background: #ffffff;
	border-radius: 3px;
	text-align: center;
	color: $mainTextColor;
	min-width: 100px;
	padding: 7px 19px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;

	&:hover {
		opacity: 0.7;
	}

	@media (max-width: 767px) {
		font-size: 12px;
		line-height: 14px;
		padding: 4px 6px;
	}
}

.disabled {
	cursor: unset;
	pointer-events: none;
	//background: rgba(178, 200, 203, 1);
	//border-color: #dad8ed !important;
	color: #c3c3c3 !important;
	opacity: 0.6;
}
