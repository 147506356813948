@import "styles/common.module.scss";

.company {
	border-radius: 4px;
	background: var(--system-bw-white, #fff);
	padding: 23px;
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 235px;
	position: relative;
	z-index: 2;

	.infoCategory {
		margin-top: 10px;

		.infoCompanyDescription {
			color: var(--Brand-Neutral-Grey-600-B, #4e5555);
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 142.857% */
		}
	}

	.topRow {
		display: flex;
		justify-content: space-between;
	}

	.bottomRow {
		display: flex;
		justify-content: space-between;
		padding-top: 15px;
	}

	@media #{$viewportMobileS} {
		padding: 0.6em;
	}
	@media #{$viewportMobile} {
		padding: 0.6em;
	}
	@media #{$viewportMobileX} {
		padding: 0.8em;
	}
	@media #{$viewportSmall} {
		padding: 0.9em;
	}
	@media #{$viewportMedium} {
		padding: 1em;
	}
	@media #{$viewportTablet} {
		padding: 1em;
	}
	@media #{$viewportMinLarge} {
		padding: 23px;
	}

	&.recommended {
		background: rgba(243, 255, 254, 1);
		border: 1px solid $enhancedButtonBackground;
		position: relative;

		@media #{$viewportMobileS} {
			padding: 0.6em;
		}
		@media #{$viewportMobile} {
			padding: 0.6em;
		}
		@media #{$viewportMobileX} {
			padding: 0.8em;
		}
		@media #{$viewportSmall} {
			padding: 0.9em;
		}
		@media #{$viewportMedium} {
			padding: 1em;
		}
		@media #{$viewportTablet} {
			padding: 1em;
		}
		@media #{$viewportMinLarge} {
			padding: 34px 18px 24px 25px;
		}

		.enhanceIcon {
			position: absolute;
			left: 0;
			top: 0;
			background: rgba(12, 88, 80, 0.25);
			border-radius: 0 0 8px 0;
			padding: 1.5px 7px 4.5px 7px;
		}
	}
}

.leftContainer {
	width: 79%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@media screen and (max-width: 767px) {
		width: 67%;
	}
}

.rightContainer {
	width: 21%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;

	@media screen and (max-width: 767px) {
		width: 33%;
	}
}

// Stat Section

.relevanceTitle {
	font-size: 0.7rem;
	line-height: 20px;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: rgba(0, 0, 0, 0.5);
}

.progressBar {
	margin-top: 20px;
}

.relevanceDetail {
	font-weight: 600;
	font-size: 0.8rem;
	text-align: center;
	color: rgba(0, 0, 0, 0.75);
}

.description {
	background: #ffffff;
	border: 1px solid rgba(42, 35, 100, 0.25);
	padding: 23px;
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;

	.title {
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.75);
		margin-bottom: 15px;
	}

	.descriptionText {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.75);
	}
}

.buttonWidth {
	max-width: 116px;
}
