.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	overflow: hidden;
	min-height: 80vh;

	@media screen and (max-width: 1400px) {
		min-height: 450px;
	}
}

.noData {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	color: #808080;
	padding: 16px;
	background: #ffffff;
}

.paginationWrapper {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 20px;
}
