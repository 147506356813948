.skeletonContainer {
	&[data-tooltip]:before {
		content: attr(data-tooltip);
		position: absolute;
		opacity: 0;
		transition: all 0.15s ease;
		padding: 10px;
		color: #333;
		border-radius: 3px;
		box-shadow: 2px 2px 1px #f1f1f1;
	}

	&[data-tooltip]:hover:before {
		opacity: 1;
		background: #fff;
		border: 1px solid #f3f3f3;
		margin-top: -50px;
		margin-left: 20px;
		z-index: 2;
	}

	&__card {
		background: #fff;
		border: 1px solid rgba(209, 205, 204, 1);
		margin-bottom: 20px;
		padding: 20px;
	}
}
