.wrapper {
	width: 100%;
	max-width: 280px;
	max-height: 318px;
	padding: 16px;
	background-color: #fff;
	opacity: 1;
	box-shadow: 0 0 31px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	z-index: 2;

	&__height {
		height: 318px;
	}

	&__info {
		display: flex;
		gap: 8px;

		&_logo {
			width: 40px;
			height: 40px;
			object-fit: cover;
			border-radius: 4px;
		}

		&__content {
			&_name {
				margin: 0;
				font-weight: 500;
				font-size: 14px;
				color: #181b1a;
			}
			&__country {
				gap: 8px;
				display: flex;
				overflow-x: auto;
				max-width: 248px;

				&::-webkit-scrollbar {
					width: 0px;
				}

				&_item {
					margin: 0;
					min-width: max-content;
					box-shadow: #4e5555;
					font-weight: 400;
					font-size: 12px;
					color: #4e5555;
				}
			}
		}
	}

	&__subtitle {
		margin: 0;
		background: #f8f7f7;
		max-width: fit-content;
		border-radius: 2px;
		padding: 4px 8px;
		font-weight: 500;
		font-size: 12px;
		color: #2c3030;
	}

	&__description {
		margin: 0;
		font-weight: 400;
		font-size: 12px;
		color: #4e5555;
		min-height: 50px;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	&__specialties {
		&__container {
			max-height: 52px;
			overflow-y: auto;
		}
	}

	&__size {
		display: flex;
		align-items: center;

		&__item {
			display: flex;
			align-items: center;
			gap: 8px;

			&:last-child {
				padding-left: 8px;
			}

			&:first-child {
				padding-right: 8px;
				border-right: 1px solid #eeedec;
			}

			&_text {
				margin: 0;
				font-weight: 500;
				font-size: 12px;
				color: #2c3030;
			}
		}
	}
}
