.content {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.title {
	color: #2c3030;
	font-family: Inter;
	font-size: 24px;
	font-weight: 600;
	line-height: 32px;
}

.description {
	color: #4e5555;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	margin: 16px 0;

	a {
		color: #0c5850;
		text-decoration: underline;
	}
}

.admin {
	border-radius: 4px;
	background: #eeedec;
	padding: 16px;
}

.info {
	color: var(--brand-neutral-grey-600-b, #4e5555);
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;

	&:not(:last-child) {
		margin-bottom: 4px;
	}
}

.info > span {
	color: var(--brand-neutral-grey-600-b, #4e5555);
	text-align: right;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	user-select: none;
}

.cancel {
	border: 1px solid #dad8d7;
	color: #4e5555;
	margin-top: 16px;

	svg {
		margin-right: 8px;
	}
}
