@import "styles/common.module.scss";

.container {
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 16px;
	left: 0;
	position: relative;
	margin-top: 30px;

	@media #{$viewportMobileS} {
		display: flex;
		flex-direction: column;
		//padding: 0 4vw;
	}
	@media #{$viewportMobile} {
		display: flex;
		flex-direction: column;
		//padding: 0 6vw;
	}
	@media #{$viewportMobileX} {
		display: flex;
		flex-direction: column;
		//padding: 0 8vw;
	}
	@media #{$viewportSmall} {
		display: flex;
		flex-direction: column;
		//padding: 0 10vw;
	}
	@media #{$viewportTablet} {
		display: flex;
		flex-direction: column;
		//padding: 0 12vw;
	}
	@media #{$viewportMinLarge} {
		display: flex;
		flex-direction: row;
		//padding: 0 15vw;
	}
}

.resultCount {
	font-weight: 600;
	font-size: 1.125rem;
}

.resultsWrapper {
	width: 100%;
	padding-bottom: 50px;

	&__content_container {
		width: 100%;
	}
}

.headerSectionWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	padding: 15px;
	background: #fff;
	border-bottom: 1px solid #eeedec;
}
