.personContainer {
	min-height: auto;
	padding-left: 25px;
	margin: 8px 0;

	&:first-child {
		margin-top: 0;
	}
}

.searchBox {
	border: 1px solid rgba(163, 156, 154, 0.5);
	box-sizing: border-box;
	border-radius: 3px;
	padding-right: 10px !important;
}

.resetFilterContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-bottom: 16px;
	padding-top: 6px;

	.resetArea {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		//gap: 19px;
	}
}
