.wrapper {
	overflow-y: auto;
	padding: 22px;
	margin: auto;
	width: 550px;
	background: #ffffff;

	@media (max-width: 768px) {
		width: 400px;
	}

	&__title {
		font-weight: 550;
		font-size: 22px;
		line-height: 30px;
		text-align: left;
		color: #312200;
	}

	&__subtitle {
		display: flex;
		justify-content: space-between;
		margin: 16px 0;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		text-align: left;
		color: #312200;
	}

	.resetArea {
		display: flex;
		flex-direction: row;
		margin-top: 10px;
		align-items: center;
		align-content: center;
		//gap: 19px;
	}

	&__success {
		min-height: 294px;
	}

	&__error {
		min-height: 310px;
	}

	&__success,
	&__error {
		&_icon {
			display: block;
			margin: 0 auto 8px;
		}

		&_subtitle {
			font-size: 16px;
			font-weight: 400;
			color: #312200;
			line-height: 24px;
			text-align: center;
		}
	}

	.form_container {
		height: 250px;
		font-size: 13px;
		border: 1px solid #d3d3d3;
		border-radius: 5px;
		padding: 5px;
		overflow-y: auto;

		p {
			padding: 5px 10px;
			background-color: #eeedec;
			width: fit-content;
			border-radius: 10px;

			span {
				margin-left: 5px;
				cursor: pointer;
			}
		}
	}

	&__form {
		gap: 16px !important;

		&__btn {
			margin-top: 0 !important;
			background: #0c5850;
			border: 1px solid #0c5850;

			&:disabled {
				background: #b2c8cb !important;
				border: 1px solid #b2c8cb;
			}
		}
	}
}

.disabled {
	color: #bdbdbd;
	cursor: not-allowed;
}

.button {
	height: 48px;
	border-radius: 4px;
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	color: #ffffff;
}

.wrapper__form__btn {
	margin-top: 20px;
}

.errorContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 14px;
	flex-direction: column;

	&__retry {
		background: #404040;
		border: 1px solid #404040;
	}
}

.inputClass {
	height: 300px;
	border: 1px solid lightgrey;
	padding-bottom: 16px;

	&:has(.errorClass) {
		padding-bottom: 32px;
	}
}

.errorClass {
	//bottom: 85px !important;
	margin-bottom: -30px;
}

.textAreaForPaste {
	border: none;
	outline: none !important;
	width: 100%;
	overflow: hidden;
	resize: none;
	margin-top: 10px;
	padding: 10px;
}

.refresh {
	cursor: pointer;
	animation: spin 2s infinite linear;
	pointer-events: none;
}

@keyframes spin {
	100% {
		transform: rotate(0deg);
	}

	0% {
		transform: rotate(360deg);
	}
}

.custom_file_upload {
	width: 100%;
	position: relative;
	display: inline-block;
	margin-bottom: 20px;
}

.input_file {
	display: none;
}

.file_label {
	display: flex;
	cursor: pointer;
	border: 1px solid #ccc;
	border-radius: 5px;
	overflow: hidden;
}

.left_half {
	flex: 1;
	padding: 10px;
	background: transparent;
	border-right: 1px solid #ccc;
	font-size: 14px;
	font-weight: 300;

	.placeholder {
		color: #595959;
	}
}

.right_half {
	padding: 10px 20px;
	background: #232323;
	color: #bdbdbd;
}

.downloadTemplate {
	display: flex;
	justify-content: space-between;
	margin: 0 13px 20px 5px;
	color: #545454;
	font-size: 13px;
	cursor: pointer;

	span {
		margin-left: 5px;
		margin-top: 2px;
	}
}

.downloadAllReport {
	display: flex;
	justify-content: center;
	align-content: center;

	span {
		margin-left: 7px;
		margin-top: -2px;
	}
}
