@import "../../../../styles/common.module";

* {
}

.statCounts {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 24px;
	//margin-top: 24px;
	background: transparent;
}

.loader {
	width: 100%;
	background-color: transparent;
}

.countBox {
	font-weight: 700;
	font-size: 14px;
	width: 32%;
	display: flex;
	background: #ffffff;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//border: 1px solid #d1cdcc;
	border-radius: 4px;
	padding-bottom: 10px;
	padding-top: 10px;
	box-shadow: 0px 9px 31px rgba(0, 0, 0, 0.04);
}

.itemCount {
	font-weight: 500;
	font-size: 36px;
	line-height: 44px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #0c5850;
}
