.container {
	padding: 16px;
}

.label {
	margin-bottom: 6px;
	color: #a1a1a1;
	text-transform: capitalize;
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	user-select: none;
	display: block;
}

.button {
	width: 100%;
	height: 48px;
	background-color: #0c5850;
	margin-top: 32px;
}

.message {
	margin: 16px 0 0 0;
}
