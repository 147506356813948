.searchContainer {
	background: #fff;
	padding: 16px;
	margin-top: 27px;
}

.filterBy {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: -17px;
	z-index: 1;
	position: relative;

	.filterType {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		//border: 1px solid #cbc5c2;
		padding: 4px 13px;
		display: flex;
		align-items: center;
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		cursor: pointer;
		background: var(--System-Silver-Chalice-200, #dad8d7);
		border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
		border-radius: 0 4px 0 0;

		&:first-child {
			border-radius: 4px 0 0 0;
			border-right: none;
		}

		&.active {
			color: var(--Brand-Neutral-Grey-800, #2c3030);
			//border-bottom: 1px solid rgba(163, 156, 154, 0.5);
			background: #fff;
			border-bottom: none;
			font-weight: 600;
		}

		span {
			pointer-events: none;
		}
	}

	.filters {
		display: flex;
		flex-direction: row;
	}
}

.tooltip {
	border-radius: 4px !important;
	display: flex;
	flex-direction: column;

	font-weight: 300 !important;
	font-size: 14px !important;
	line-height: 22px !important;

	color: #404040 !important;
	width: 314px;
	opacity: 1 !important;
	box-shadow: 0 4px 32px rgba(0, 0, 0, 0.18) !important;
	background-color: #ffffff;
	padding: 16px;
	z-index: 1;

	b {
		font-weight: 500 !important;
	}
}
