div.chart-wrapper {
	background-color: white;
	padding: 0;
	margin: 48px auto 16px auto;
	//margin-top: 96px;

	div.chart {
		height: 80px;
	}

	.label-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-right: 8px;

		span {
			color: rgba(0, 0, 0, 0.5);
			font-family: "Inter", sans-serif;
			font-weight: 400;
			font-size: 12px;
			line-height: 20px;
		}
	}

	form.inputs {
		display: flex;
		justify-content: space-between;

		> div {
			&:first-child {
				margin-right: 12px;
			}

			&:nth-child(2) {
				margin-left: 12px;
			}
		}
	}
}
