.container {
	background: #fff;
	border-radius: 4px;
	margin-bottom: 24px;
	box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.08);

	&__head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #e1e1e1;

		&__title {
			font-size: 18px;
			font-weight: 500;
			line-height: 22px;
			margin-left: 24px;
			font-family: "Inter";
		}

		&__btn {
			margin-right: 24px;
			transform: rotate(0);
			transition: transform 300ms ease;

			&_transform {
				transform: rotate(-180deg);
				transition: transform 300ms ease;
			}
		}
	}

	&__body {
		overflow: scroll;
		max-height: 400px;
		padding: 32px 24px;
		transition: (max-height, padding) 200ms ease;

		&__closed {
			max-height: 0;
			padding: 0 24px;
			transition: (max-height, padding) 200ms ease;
		}
	}
}

.statistic {
	gap: 24px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	&__chart {
		width: 100%;
		max-width: 87%;
		overflow-y: scroll;
		padding-right: 15px;
		max-height: 336px;

		&::-webkit-scrollbar {
			width: 5px;
			background: transparent;
		}

		&::-webkit-scrollbar-thumb {
			width: 5px;
			border-radius: 5px;
			background: #5a5a5a;
		}
	}

	&__totals {
		width: 100%;
		display: flex;
		max-width: 12%;
		min-width: max-content;
		justify-content: flex-end;

		&_container {
			gap: 24px;
			display: flex;
			flex-direction: column;
		}
	}
}

.totals {
	&__title {
		margin: 0;
		font-size: 14px;
		font-weight: 500;
		color: #5a5a5a;
		line-height: 22px;
		margin-bottom: 6px;
		font-family: "Inter";
	}

	&__value {
		margin: 0;
		display: flex;
		font-size: 32px;
		font-weight: 600;
		color: #383838;
		line-height: 38px;
		font-family: "Inter";

		&__badge {
			gap: 2px;
			display: flex;
			font-size: 12px;
			margin-left: 6px;
			padding: 2px 6px;
			font-weight: 600;
			color: #0c5850;
			line-height: 20px;
			align-items: center;
			border-radius: 13px;
			height: min-content;
			font-family: "Inter";
			justify-content: center;
			background-color: #d9d9d9;

			&_is_bottom {
				transform: rotate(180deg);
			}
		}
	}
}
