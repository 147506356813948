.checkbox {
	&__text {
		font-size: 14px;
		color: #a2a2a2;
		font-weight: 500;
		margin-left: 8px;
		line-height: 22px;
		font-family: "Inter";

		a {
			color: #0c5850;
			font-weight: 500;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
