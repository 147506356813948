.container {
	position: relative;
	width: 100%;
}

.searchWrapper {
	position: relative;
	display: flex;
	align-items: center;

	.loader {
		position: absolute;
		right: 16px;
	}
}

.list {
	position: absolute;
	z-index: 2;
	width: 100%;
	left: 0;
	right: 0;
	background: #fff;

	&__empty {
		width: 100%;
		padding: 21px;
		background-color: #fff;
		box-shadow: 0px 0px 31px rgba(0, 0, 0, 0.08);
		border-radius: 4px;
		position: absolute;

		&_text {
			margin: 0;
			font-size: 14px;
			line-height: 18px;
			font-weight: 500;
		}
	}
}

.suggestionList {
	font-family: "Inter", sans-serif;
	background-color: #fff;
	margin-top: 0;
	z-index: 200;
	overflow-y: auto;
	position: absolute;
	width: 100%;
	padding-left: 10px;
	margin-left: 0;
	display: flex;
	flex-direction: column !important;
}

.suggestionContainer {
	font-family: "Inter", sans-serif;
	display: flex;
	align-items: flex-start;
	width: 100%;
	overflow-y: scroll;
	height: 250px;
	position: relative;
}

.resetFilter {
	font-size: 12px;
	line-height: 20px;
	color: #0c5850;
	cursor: pointer;
	display: flex;
	margin-bottom: 16px;
}

.suggestionItem {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 15px 0;

	.suggestionTopPart {
		display: flex;
		justify-content: space-between;

		.topPart {
			display: flex;
			align-items: center;
			h4 {
				margin: 0;
				font-size: 14px;
			}
			img {
				width: 30px;
				height: 30px;
				border-radius: 100px;
				margin-right: 8px;
				border: 1px solid #d1cdcc;
			}

			span {
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
			}
		}
	}
}

.jobTitle {
	margin-top: 5px;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
}

.companyName {
	font-size: 12px;
	margin: 0;
	display: flex;
	align-items: center;
	text-align: right;
}
