.container {
	width: 100%;
	height: 25px;
	display: flex;
	cursor: pointer;
	user-select: none;
	position: relative;
	align-items: center;
	margin-bottom: 32px;
	margin-top: 24px;

	&__wrapper {
		position: relative;
		margin-right: 24px;
	}

	&:hover input ~ .checkmark {
		background-color: #ccc;
	}

	input:checked ~ .checkmark {
		background-color: #dadada;
	}

	&__input {
		width: 0;
		height: 0;
		opacity: 0;
		cursor: pointer;
		position: absolute;
	}

	&__after {
		left: 0px;
		width: 24px;
		height: 24px;
		position: absolute;
		border-radius: 3px;
		top: calc(50% - 12px);
		border: 1px solid #dadada;

		&::after {
			content: "";
			display: none;
			position: absolute;
		}
	}

	input:checked ~ &__after {
		border: none;
		border-radius: 4px;
		background: #0c5850;

		&:after {
			top: 5px;
			left: 9px;
			width: 6px;
			height: 11px;
			display: block;
			border: solid white;
			transform: rotate(45deg);
			border-width: 0 2px 2px 0;
		}
	}
}
