.fitToBriefContainer {
	display: flex;
}

.fitToBrief {
	display: flex;
	height: 40px;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	border-radius: 4px;
	border: 1px solid var(--Brand-Primary-600-B, #0c5850);
	color: #ffffff;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 142.857% */
	background: #0c5850;

	svg path {
		fill: #ffffff;
	}

	&.disabled {
		opacity: 0.7;

		&:hover {
			cursor: not-allowed;
		}
	}

	&.loading {
		opacity: 0.7;
	}
}

.tooltip {
	width: 383px;
	font-weight: 300 !important;
	font-size: 14px !important;
	line-height: 22px !important;
	color: #4e5555 !important;
	opacity: 1 !important;
	box-shadow: 0 0 31px 0 rgba(0, 0, 0, 0.16);
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border-radius: 4px !important;
	padding: 16px;
	z-index: 5;

	b {
		font-weight: 500 !important;
		color: var(--Brand-Primary-600-B, #0c5850);
	}
}
