.containSwitch {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	color: var(--Brand-Neutral-Grey-400, #868d8d);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */

	margin-top: 16px;

	.active {
		color: var(--Brand-Neutral-Grey-900, #181b1a);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
	}
}

.label {
	margin: 16px 0 -12px 0;
	color: var(--Brand-Neutral-Grey-400, #868d8d);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 142.857% */
}
