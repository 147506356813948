@import "styles/common.module.scss";

.notEnoughDataWarningRow {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	@media (max-width: 1200px) {
		flex-direction: column;
	}
}

.notEnoughDataWarningColumn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.warningTitle {
	margin: 0;
	font-size: 30px;
	color: #808080;

	&.smallerWarningTitle {
		font-size: 20px;
	}
}

.warningText {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	display: block;

	line-height: 26px;
	color: #a39c9a;

	&.smallerWarningText {
		font-size: 14px;
		line-height: 20px;
	}
}

.warningContent {
	padding-left: 40px;
	width: 275px;

	@media (max-width: 1200px) {
		padding-left: 0;
		width: unset;
	}
}

.warningContentSmall {
	width: 275px;
	margin-top: -30px;

	@media (max-width: 1200px) {
		padding-left: 0;
		width: unset;
	}
}
