@import "styles/common.module.scss";

.buttonContainer {
	background: $enhancedButtonBackground;
	border-radius: 3px;
	text-align: center;
	font-weight: 500;
	color: $enhancedButtonText;
	padding: 7px 12px 7px 12px;
	align-items: center;
	cursor: pointer;
	user-select: none;

	&:hover {
		background: rgba(22, 135, 122, 0.75);
	}
}

// .buttonActive {
// 	background: rgba(80, 165, 155, 1);
// }

.buttonIcon {
	margin-right: 8px;
}

.buttonText {
	font-size: $enhancedButtonFontSize;

	&:hover {
		cursor: pointer;
	}
}

.checkIcon {
	padding-bottom: 2px;
}
