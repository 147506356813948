.tag {
	font-size: 14px;
	line-height: 22px;
	color: #3c6662;
	background-color: #f9f9f9;
	padding: 4px 11px;
	border-radius: 3px;
	display: inline-flex;
	align-items: center;
	user-select: none;

	&.clickable {
		cursor: pointer;
		color: #404040;
	}

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	& > svg {
		margin-left: 5px;
	}
}
