.filterSectionWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	flex: none;
	align-self: stretch;
	flex-grow: 0;

	.left {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 16px;
	}

	.right {
		display: flex;
		flex-direction: row;
		gap: 16px;
	}
}

.dropdownPlaceholder {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 16px;

	color: var(--Brand-Neutral-Grey-900, #181b1a);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;

	span {
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
	}
}
