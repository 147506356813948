.wrapper {
	top: 32px;
	z-index: 3;
	right: 3px;
	display: flex;
	width: max-content;
	position: absolute;
	border-radius: 4px;
	flex-direction: column;
	background-color: #fff;
	box-shadow: 0px 0px 31px rgba(0, 0, 0, 0.08);
	padding: 8px 0;
	user-select: none;

	.arrow {
		top: -8px;
		right: 18px;
		position: absolute;
	}

	.item {
		padding: 8px 16px;
		cursor: pointer;
		color: #4e5555;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		transition: background-color 0.2s linear;

		&.disabled {
			color: #b0b5b4;
			pointer-events: none;
		}

		&:hover {
			background-color: #f5f5f5;
		}

		.seats {
			border-radius: 4px;
			background: #e5e6e6;
			color: var(--brand-neutral-grey-800, #2c3030);
			text-align: center;
			font-size: 12px;
			font-weight: 600;
			line-height: 16px;
			padding: 2px 4px;
			margin-left: 8px;
		}
	}
}
