@import "styles/common.module.scss";

.container {
	margin-top: 38px;
	width: 100%;
}

.header {
	display: none;
}
.printContainer {
	padding: 20px;
	background: #fff !important;

	.header {
		display: block;
		margin-bottom: 20px;
	}
}

.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

@media print {
	@page {
		size: A5 landscape;
		margin: 0;
	}

	html {
		background-color: #ffffff;
		margin: 0;
	}

	body {
		border: solid 1px blue;
		margin: 10mm 15mm 10mm 15mm;
	}

	#not-print {
		visibility: hidden;
		-webkit-print-color-adjust: exact !important;
	}

	#printBtn {
		visibility: hidden !important;
	}

	#print {
		visibility: visible !important;
		text-align: Center !important;
		-webkit-print-color-adjust: exact !important;
	}

	.legend {
		background: #9dd2e2 !important;
	}
}
