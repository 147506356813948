@import "styles/common.module.scss";

.similarPeopleCard {
	position: relative;
	background: #ffffff;
	//border: 1px solid rgba(163, 156, 154, 0.5);
	padding: 24px;
	margin-bottom: 20px;
	border: 1px solid #d1cdcc;

	@media print {
		display: none !important;
	}
}

.title {
	font-size: 18px;
	color: #271b00;
	font-weight: 600;
	margin-bottom: 24px;
}

.candidateName {
	font-size: 14px;
	color: #271b00;
	font-weight: 500;
	margin-right: 5px;
}

.similarPeopleList {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	grid-gap: 24px 40px;

	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
	}
}

.similarPeopleTitle {
	font-style: normal;
	font-weight: normal;
	font-size: 0.875rem;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.5);
	margin-left: 7px;
}

.companyLogo {
	width: 25px;
	height: 25px;
	border: 0.5px solid #d1cdcc;
	border-radius: 100px;

	@media (max-width: 1024px) {
		margin-right: 3px;
	}
}

.companyName {
	margin-left: 2px;
	font-weight: 600;
}

.companyPosition {
	margin-left: 5px;

	@media (max-width: 1024px) {
		margin-left: 30px;
	}
}

.previousTitle {
	margin-left: 6px;
}

.positions {
	margin-top: 5px;
	font-size: 12px;

	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
	}
}

.avatar {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	border: 1px solid #d1cdcc;
	object-fit: cover;
	margin-right: 7px;

	@media (max-width: 1024px) {
		margin: 0 auto;
	}
}

.previous {
	margin-top: 7px;
	font-size: 12px;

	@media (max-width: 1024px) {
		margin-left: 30px;
	}

	> b {
		font-weight: 600;
	}
}

.logo {
	width: 45px;
	height: 45px;
	margin-right: 7px;
	border-radius: 50%;
	border: 1px solid #d1cdcc;
	object-fit: cover;
}

.infoTitle {
	color: $mainTextColor;

	font-size: 1rem;
	font-weight: 600;
	cursor: pointer;

	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.infoDetail {
	color: $secondaryTextColor;

	font-size: 0.75rem;
}

.infoCategory {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-top: 0.7rem;
}

.infoCategoryIcon {
	color: rgba(0, 0, 0, 0.5);
}

.infoCategoryButton {
	background: rgba(163, 156, 154, 0.1);
	color: rgba(0, 0, 0, 0.5);
	padding: 0.3rem 0.7rem;
	margin-left: 0;
	//margin-top: 0.6rem;
	border-radius: 3px;
	font-size: 12px;
	border: 0;

	&.BottomMargin {
		margin-bottom: 0.5rem;
	}
}

.infoFirstRow {
	border: 1px solid #d1cdcc;
	padding: 15px;
	min-height: 110px;
	height: 100%;
}

.cardGrid {
	display: flex;

	@media (max-width: 1024px) {
		flex-direction: column;
	}
}

.country {
	font-weight: 400;
	font-size: 14px;
	color: #808080;
}

.logo {
	margin-right: 15px;
	border-radius: 50%;
	border: 1px solid #d1cdcc;
	padding: 13px;
	object-fit: cover;

	@media (max-width: 1024px) {
		margin: 0 auto;
	}
}

.informationContainer {
	// flex-direction: column;
	// height: 100%;

	// &:nth-child(even) {
	// 	margin-left: 20px;
	// }

	// &:nth-child(odd) {
	// 	margin-right: 20px;
	// }
}

.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}
