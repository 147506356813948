@import "styles/variables.module.scss";
@import "styles/custom/_mixins.scss";

.inputContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	align-self: stretch;
	gap: 4px;
}

.errorText {
	top: 26px;
	width: 100%;
	height: 28px;
	background: $errorColor;
	color: #ffffff;
	border-radius: 3px;
	padding: 4px 8px;
	font-weight: 400;
	line-height: 20px;
	font-size: 13px;
	position: absolute;
	bottom: 10px;

	&:before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid $errorColor;
		position: absolute;
		bottom: -5px;
		left: 2px;
	}
}

.inputWrapper {
	display: flex;
	align-items: center;
	position: relative !important;
	width: 100%;
}

.rightIcon {
	position: absolute;
	right: 10px;
	pointer-events: none; /* prevents eye icon like active Icons to be clickable */

	color: var(--Brand-Neutral-Grey-400, #868d8d);
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 16px; /* 133.333% */
}

.invalid {
	& .iconError {
		display: block !important;
		color: #ff0b37 !important;
	}

	& .input {
		background: rgba(255, 11, 55, 0.05) !important;
		border: 1px solid #ff0b37 !important;
	}
}

.iconError {
	display: none !important;
}

.input {
	@include input-design;
	border-radius: 4px;

	@include placeholder;
}

.input::placeholder {
	opacity: 1;
	color: var(--Brand-Neutral-Grey-300, #b0b5b4);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	user-select: none;
}

.label {
	color: #868d8d;
	text-transform: capitalize;
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	user-select: none;
}

.invalid {
	position: relative;

	input {
		border: 1px solid $errorColor !important;
		background: #ffffff !important;
	}

	.error-icon {
		display: none !important;
	}
}

.invalidInput {
	border: 1px solid #ff0b37;
}

.errorTextSpan {
	font-size: 12px;
	line-height: 16px;
	color: #ff0b37;
	display: block;
	margin-top: 5px;
}
