.container {
	grid-column: 1 / 3;
	max-height: 800px;
	position: relative;
	width: 100%;

	overflow-y: hidden;
	overflow-x: auto;
	border-radius: 4px;
	background: var(--system-bw-white, #fff);
	box-shadow: 0px 17px 33px -2px rgba(79, 75, 74, 0.05);
}

.hidden {
	display: none !important;
}

.headerArea {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #eeedec;
	padding: 16px 24px;

	.title {
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 28px; /* 155.556% */
	}
}

.mapOptions {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	.mapOption {
		cursor: pointer;
		color: var(--Brand-Neutral-Grey-300, #b0b5b4);
		font-size: 13px;
		font-style: normal;
		font-weight: 600;
		line-height: 18px;

		&.active {
			border-bottom: 1px solid var(--Brand-Primary-600-B, #0c5850);
			color: var(--Brand-Primary-600-B, #0c5850);
			font-size: 13px;
			font-style: normal;
			font-weight: 600;
			line-height: 18px; /* 138.462% */
		}
	}
}

.legendWrapper {
	position: absolute;
	right: 2em;
	top: 1em;
	user-select: none;
	display: flex;
	width: 328px;
	padding: 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	border-radius: 4px;
	border: 1px solid var(--System-Silver-Chalice-100, #eeedec);
	background: var(--system-bw-white, #fff);

	.expander {
		cursor: pointer;
	}

	> div {
		display: flex;
		align-items: center;
	}

	.dot {
		height: 12px;
		width: 12px;
		border-radius: 100px;
	}

	.hqDot {
		background-color: #083e38;
	}

	.legendTitle {
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px; /* 142.857% */

		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
		width: 100%;
	}

	.legendContent {
		color: var(--Brand-Neutral-Grey-600-B, #4e5555);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 142.857% */

		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;
		width: 100%;
		position: relative;
	}
}

.regionList {
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: flex-start;
	position: relative;
	width: 100%;
}

.regionCountryList {
	transition: max-height 0.3s ease, opacity 0.3s ease;
	opacity: 0;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	max-height: 0;
	overflow: hidden;

	&.opened {
		width: 100%;
		max-height: 500px;
		gap: 4px;
		opacity: 1;
		//transform: translateY(0);

		overflow: hidden;
		color: var(--Brand-Neutral-Grey-600-B, #4e5555);
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
	}

	.countriesListWrapper {
		max-height: 500px;
		overflow: hidden auto;
		scrollbar-width: thin;
		padding-right: 10px;
	}

	.country {
		margin-left: 16px;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;
		justify-content: space-between;
		//width: 93%;
	}
}

.count {
	display: flex;
	width: max-content;
	height: 20px;
	padding: 4px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	background: var(--System-Silver-Chalice-100, #eeedec);

	color: var(--Brand-Neutral-Grey-800, #2c3030);
	text-align: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 166.667% */
}

.percentage {
	color: var(--Brand-Neutral-Grey-600-B, #4e5555);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
}
