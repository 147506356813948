.filterSelectionWrapper {
	display: flex;
	flex-direction: column;
	align-self: stretch;
	overflow: auto;
	position: relative;
	border-radius: 4px;
	background: var(--system-bw-white, #fff);
	box-shadow: 0 0 0 -2px rgba(79, 75, 74, 0.05);

	&::-webkit-scrollbar {
		width: 4px;
	}

	h2 {
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 28px; /* 155.556% */
		border-bottom: 1px solid #eeedec;
		padding: 16px 24px;
		margin: 0;
	}
}

.filterColumns {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 24px;
	align-self: stretch;
	padding: 24px;
}

.filterColumn {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 0;
	border-radius: 4px;
	border: 1px solid var(--System-Silver-Chalice-100, #eeedec);

	h4 {
		display: flex;
		padding: 16px;
		align-items: center;
		align-self: stretch;
		margin: 0;
		gap: 8px;
		border-radius: 4px 4px 0px 0px;
		background: var(--Brand-Primary-50, #edf2f2);

		color: var(--Brand-Primary-600-B, #0c5850);
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px; /* 150% */
	}

	.companyList,
	.companyFilters {
		display: flex;
		flex-direction: column;
		align-self: stretch;
	}

	.filterWrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex: 1 0 0;
		justify-content: space-between;
		align-self: stretch;
	}
}

.info {
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
	padding: 4px 8px;
	border-radius: 4px;
	border: 1px solid var(--System-Silver-Chalice-100, #eeedec);
	margin: 24px 24px 0;

	color: var(--Brand-Neutral-Grey-800, #2c3030);
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px; /* 138.462% */
}
