$grey: rgb(234, 233, 240);
$primary: #0c5850;

div.slider-outer-wrapper {
	//padding-bottom: 16px;

	div.slider-input-wrapper {
		display: flex;
		align-items: center;

		div.line {
			position: relative;
			height: 3px;
			flex: 1;
			background-color: rgb(230, 230, 230);

			div.highlight {
				position: absolute;
				top: 0;
				bottom: 0;
				background-color: $primary;
				transition: none;
			}
		}

		div.knob-container {
			user-select: none;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			z-index: 12;
			transition: none;

			div.verticalLine {
				position: absolute;
				top: -90px;
				left: -1px;
				bottom: 100px;
				height: 100px;
				width: 2px;
				border-right: 2px dotted $primary;
				z-index: -1;
			}

			div.actual {
				position: absolute;
				top: -100px;
				width: 100%;
				left: 0;
				right: 0;
				white-space: nowrap;
				padding: 2px 4px;
				background-color: $primary;
				font-weight: 600;
				font-size: 10px;
				line-height: 18px;
				color: #ffffff;
				box-shadow: 0px 0px 31px rgba(0, 0, 0, 0.08);
				border-radius: 4px;
				// min-width: 28px;
				text-align: center;
			}
		}

		div.knob-wrapper {
			user-select: none;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			z-index: 12;
			transition: none;

			&:hover,
			&:active {
				div.knob {
					box-shadow: 0 0 8px 0px rgba($primary, 0.4);
				}

				div.knob-label {
					opacity: 1;
					visibility: visible;
					transform: translate(-50%, -100%);
				}
			}

			&:active {
				div.knob {
					cursor: grabbing;
				}
			}

			div.knob {
				width: 24px;
				height: 24px;
				border-radius: 500px;
				background-color: #f4f0ed;
				cursor: grab;
				border: 6px solid #0c5850;
				box-sizing: border-box;
			}

			div.knob-label {
				position: absolute;
				pointer-events: none;
				top: -12px;
				transform: translate(-50%, calc(-100% + 8px));
				display: flex;
				padding: 4px 12px;
				white-space: nowrap;
				left: 50%;
				background: white;
				border: 1x solid $primary;
				border-radius: 3px;
				opacity: 0;
				visibility: hidden;
			}
		}
	}
}
