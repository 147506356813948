section.container {
	padding: 16px;
	background: #ffffff;
	box-shadow: 0px 9px 31px rgba(0, 0, 0, 0.04);
	border-radius: 4px;
	// min-width: 300px;
	box-sizing: border-box;
	flex-grow: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	margin-bottom: 16px;
	transition: opacity 0.6s linear;

	> .main {
		// max-height: 76%;
		// overflow: auto;
		margin-bottom: 32px;
	}

	&.highlighted {
		border: 2px solid #0c5850;
	}

	&.hide {
		opacity: 0;
	}
}

.candidate {
	display: flex;
	align-items: center;
	margin-bottom: 24px;

	.candidateLogo {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
		box-sizing: border-box;
		background: #f4f0ed;
		border: 2px solid #271b00;
		border-radius: 50%;
		object-fit: contain;
		margin-right: 8px;
		text-align: center;
		font-weight: 600;
		font-size: 18px;
		line-height: 26px;
		pointer-events: none;
		user-select: none;
	}
	.candidateName {
		font-weight: 500;
		font-size: 18px;
	}
}

.company {
	header {
		border: 1px solid #f4f0ed;
		padding: 4px 8px;
		margin-bottom: 8px;
		display: flex;
		align-items: center;

		.industry {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: #271b00;
			font-weight: 500;
			font-size: 12px;
			max-width: 60%;
			> svg {
				margin-right: 6px;
			}
		}
		.size {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: #271b00;
			font-weight: 500;
			font-size: 12px;
			> svg {
				margin-right: 6px;
			}
		}

		.verticalLine {
			border-left: 1px solid #f4f0ed;
			width: 1px;
			height: 20px;
			margin: 0 8px;
		}
	}

	> .main {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: auto;

		.right {
			flex: 1;

			.exp:not(:last-child) {
				margin-bottom: 8px;
			}

			.title {
				font-weight: 500;
				font-size: 12px;
				color: #271b00;
				display: -webkit-box;
				max-width: 210px;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}

			.date {
				color: #808080;
				font-size: 12px;
			}
		}
		.left {
			display: flex;
			align-items: center;
			font-weight: 500;
			font-size: 12px;
			align-self: flex-start;
			margin-right: 24px;
			width: 150px;

			.linkContainer {
				cursor: pointer;
				display: inline-flex;
				align-items: center;
				justify-content: flex-start;
			}

			.companyName {
				display: -webkit-box;
				max-width: 210px;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}
		.companyLogo {
			width: 32px;
			height: 32px;
			border-radius: 2px;
			object-fit: contain;
			margin-right: 8px;
			pointer-events: none;
			user-select: none;
		}
	}

	&:not(:last-child) {
		margin-bottom: 16px;

		.line {
			border-bottom: 1px solid #f2f2f2;
			height: 1px;
			width: 100%;
			margin: 16px 0;
		}
	}
}

footer.button {
	margin-top: auto;

	&.right {
		margin-left: auto;
	}
	&.bottom {
		margin-left: auto;
		margin-right: auto;
	}
}
