.container {
	display: flex;
	flex-direction: column;
	padding-bottom: 50px;
	gap: 10px;

	&.noGap {
		gap: 0;
	}

	&__card {
		background: #fff;
		box-shadow: 0 9px 31px 0 rgba(0, 0, 0, 0.04);
		border-radius: 4px;
		gap: 1rem;
		padding: 8px 16px;

		&.noGap {
			gap: 0;
			box-shadow: none;
			border-bottom: 1px solid #eeedec;
			border-radius: 0;
		}
	}
}
