.inputContainer {
	display: flex;
	align-self: stretch;
	border-radius: 5px;
	transition: height 0.2s ease;
	background: #ffffff;
	min-height: 40px;

	&:focus {
		border-color: transparent;
	}

	@media screen and (max-width: 767px) {
		width: 100%;
	}

	@media screen and (max-width: 1024px) {
		width: 550px;
	}
}

.inputClass {
	padding-right: 30px;
}

.searchIcon {
	position: absolute;
	left: 10px;
	top: 13px;
	line-height: 0.8;
}

.label {
	margin: 16px 0 4px 0;
	color: var(--Brand-Neutral-Grey-400, #868d8d);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 142.857% */
}

.button {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 20px;
	width: 20px;
	height: 20px;
	flex-shrink: 0;
	border-radius: 4px;
	padding: 0;
	position: absolute;
	right: 7px;
	top: 36px;
	z-index: 1;

	svg {
		margin-right: 0;
	}

	&.loading {
		background: #b5b1b0;
		height: 24px;
		width: 24px;

		svg {
			height: 14px;
			width: 14px;

			path {
				stroke: white;
			}
		}
	}
}
