.filterBlockButtonWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 5px;
	background: transparent;
	//padding-bottom: 1px;
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
	position: relative;

	.icon {
		svg {
			fill: gray;
		}

		&.disabled {
			svg {
				fill: #b0b5b4;
			}
		}
	}

	.title {
		color: var(--Brand-Neutral-Grey-900, #181b1a);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */

		&.disabled {
			color: #b0b5b4;
		}

		.rejectCount {
			font-weight: 400;
			font-size: 18px;
			line-height: 22px;
			margin-left: 10px;
			color: #808080;
		}
	}

	&.opened {
		border: 1px solid #0c5850;

		.title {
			color: #0c5850 !important;
		}

		.icon {
			svg {
				fill: #0c5850 !important;
			}
		}
	}
}

.count {
	//margin-left: 10px;
	height: 20px;
	max-width: max-content;
	flex-grow: 1;
	padding: 4px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 4px;
	background: var(--System-Silver-Chalice-100, #eeedec);

	flex: auto;
	color: var(--Brand-Neutral-Grey-800, #2c3030);
	text-align: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
}

.filters {
	position: absolute;
	min-width: 382px;
	width: 100%;
	background: #fff;
	z-index: 4;
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
	border-radius: 0 4px 4px 4px;
	left: 0;
	top: 36px;
	overflow: auto;

	&.filterDisplayed {
		@keyframes fadeInDown {
			0% {
				opacity: 0;
				transform: translateY(-0.2em);
			}
			100% {
				opacity: 1;
				transform: translateY(0);
			}
		}

		height: calc(100vh - 320px);
		animation-name: fadeInDown;
		animation-duration: 0.2s;
		border: 1px solid var(--Brand-Primary-600-B, #0c5850);
	}
}

.accordion {
	padding: 15px 17px;
	border: none;
	border-bottom: 1px solid rgba(209, 205, 204, 1);

	&.resetFilterAccordion {
		padding-top: 10px;
		padding-bottom: 0px;
		border-bottom: none;
	}
}

.resultFilterAccordion {
	&:last-child {
		border-bottom: none;
	}
}

.selectedFilters {
	padding: 15px 17px;
	//border: none;
	border-top: 1px solid rgba(209, 205, 204, 1);
}

.checkboxContainer {
	margin-bottom: 12px;

	.labelContainer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex: 1;
		align-items: center;

		.labelTitle {
			max-width: 254px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 8px;

			.image {
				img {
					height: 20px;
					width: 20px;
					border-radius: 2px;
				}
			}
		}

		.labelIconContainer {
			font-size: 14px;
			line-height: 22px;
			color: #808080;
			gap: 6px;
		}
	}
}

.resetFilterContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding-bottom: 16px;
	padding-top: 6px;

	.resetArea {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		//gap: 19px;
	}
}

.filterExpandIcon {
	transition: transform 0.2s linear;
	position: absolute;
	right: 10px;

	&.rotated {
		transform: rotateX(180deg);
	}
}
