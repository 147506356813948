.summaryBlock {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;

	.summaryActionWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		//align-items: center;

		.summaryTitle {
			font-size: 13px;
			line-height: 20px;
			letter-spacing: 0;
			text-align: left;
			color: #181b1a;
		}
	}

	.description {
		width: 100%;
		display: flex;
		color: #868d8d;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0;
		text-align: left;
	}

	.loadingSummaryText {
		color: #cacaca;
		font-size: 12px;
		font-weight: 400;
		text-align: left;
	}

	.editSummaryBlock {
		width: 100%;
		border: none;
		scrollbar-width: thin;
		padding: 6px;
		margin-top: 5px;
		cursor: text;
	}
}

.noPadding {
	padding-left: 0;
	margin: 10px 0 20px 0;
	border: none;
}

.generateSummaryButton {
	display: flex;
	align-items: center;
	gap: 5px;
	border-radius: 4px;
	padding: 6px 8px;
	background: transparent;
	color: #4e5555;
	height: 30px;
	border: 1px solid #dad8d7;

	.loading {
		pointer-events: none;
		background: #e5e6e6;
	}
}

.boldTitle {
	font-size: 14px;
	font-weight: 500;
}

.pointer {
	cursor: pointer;
	font-weight: 700;
}
