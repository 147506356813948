.location__wrapper {
	position: relative;
}

.skeleton_container {
	gap: 25px;
	margin: 60px 0 0 24px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.skeleton__wrapper {
	display: flex;
	gap: 25px;
}

.location__wrapper {
	position: relative;
}

.location__item {
	position: absolute;
	top: 110px;
	right: 80px;
}

.round__chart {
	display: flex;
	margin-top: 15px;
	margin-left: 50px;
}
