.container {
	padding: 0 8px;
	transition: all 0.2s linear;
	border: 1px solid #eeedec;

	// To handle adding --padding amount of padding to the left and right
	// of the container while keeping the parent container's padding same.
	--padding: 8px;
	position: relative;
	left: calc(-1 * var(--padding));
	right: 0;
	width: calc(100% + calc(var(--padding) * 2));
}

.dragOver {
	background-color: #f5fdfc;
	border-radius: 4px;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.left {
		display: flex;
		align-items: center;
		margin: 8px 0;
		user-select: none;
		font-size: 14px;
		font-weight: 500;

		> .count {
			align-items: center;
			background-color: #f4f0ed;
			border-radius: 2px;
			color: #271b00;
			display: flex;
			font-size: 12px;
			font-weight: 600;
			justify-content: center;
			line-height: 20px;
			margin-left: 6px;
			max-width: max-content;
			height: 22px;
			padding: 4px 6px;
		}

		& > svg {
			margin-left: 5px;
			cursor: pointer;
		}
	}

	.right {
		display: flex;
		align-items: center;
	}
}

.moveToContainer {
	.actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		user-select: none;
		margin-bottom: 5px;
	}

	.moveTo {
		color: #271b00;
		font-size: 14px;
	}

	.otherPosition {
		color: #3c6662;
		font-size: 14px;
		font-weight: 500;
		margin-left: 7px;
		cursor: pointer;

		&.disabled {
			color: #808080;
			pointer-events: none;
		}
	}

	.selectAll {
		color: #3c6662;
		font-weight: 500;
		font-size: 14px;
		cursor: pointer;
	}
}

.tags {
	display: flex;
	flex-wrap: wrap;
	max-height: 255px;
	overflow: hidden auto;
	margin-top: 8px;

	& > span {
		margin-right: 10px;
		margin-bottom: 10px;
	}
}
