@import "styles/common.module.scss";

.container {
	width: fit-content;
	display: flex;
	justify-content: center;
	gap: 16px;
	left: 0.1px;
	position: absolute;

	@media #{$viewportMobileS} {
		display: flex;
		flex-direction: column;
		//padding: 0 4vw;
	}
	@media #{$viewportMobile} {
		display: flex;
		flex-direction: column;
		//padding: 0 6vw;
	}
	@media #{$viewportMobileX} {
		display: flex;
		flex-direction: column;
		//padding: 0 8vw;
	}
	@media #{$viewportSmall} {
		display: flex;
		flex-direction: column;
		//padding: 0 10vw;
	}
	@media #{$viewportTablet} {
		display: flex;
		flex-direction: column;
		//padding: 0 12vw;
	}
	@media #{$viewportMinLarge} {
		display: flex;
		flex-direction: row;
		//padding: 0 15vw;
	}
	@media #{$viewportLargeX} {
		margin-left: 12vw;
	}
	@media #{$viewportXLarge} {
		margin-left: 25vw;
	}
}

.noResult {
	background: #fff;
	padding: 16px;
	position: relative;
	box-shadow: 0 9px 31px 0 rgba(0, 0, 0, 0.04);

	.clearResults {
		font-weight: 400;
		font-size: 14px;
		margin-left: 10px;

		&:hover {
			cursor: pointer;
		}
	}
}

.resultCount {
	font-weight: 600;
	font-size: 1.125rem;
}

.resultsWrapper {
	margin-top: 30px;
	padding-bottom: 50px;
	position: absolute;
	left: calc(384px + 100px);
	min-width: 45rem;
	width: fit-content;

	@media screen and (max-width: 1480px) {
		margin-top: 10px;
		min-width: 40rem;
	}
	@media screen and (max-width: 1300px) {
		margin-top: 10px;
	}
	@media screen and (max-width: 1024px) {
		margin-top: 10px;
		width: auto;
	}

	&__content_container {
		width: 100%;
		overflow-x: hidden;
	}
}

.loaderContainer {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.headerSectionWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	padding: 15px;
	background: #fff;
	//border-radius: 4px;
	border-bottom: 1px solid #eeedec;

	.actionButtonsRight,
	.actionButtonsLeft {
		display: flex;
		flex-direction: row;
		gap: 8px;
	}

	.resetFilterButton {
		display: flex;
		align-items: center;
		cursor: pointer;
		font-size: 12px;
		font-weight: 500;
		gap: 5px;
		border-radius: 4px;
		padding: 6px 8px;
		background: transparent;
		color: var(--brand-neutral-grey-600-b, #4e5555);
		border: 1px solid var(--system-silver-chalice-200, #dad8d7);
	}

	.toggleExpandButton,
	.saveButton {
		display: flex;
		align-items: center;
		gap: 5px;
		border-radius: 4px;
		padding: 6px 8px;
		background: transparent;
		color: var(--brand-neutral-grey-600-b, #4e5555);
		height: 36px;
	}

	.toggleExpandButton {
		border-radius: 4px;
		border: 1px solid var(--system-silver-chalice-200, #dad8d7);

		&.reverse {
			background: var(--brand-neutral-grey-50, #f7f8f8);

			svg {
				transform: rotate(180deg);
			}
		}
	}

	.count {
		display: flex;
		align-items: center;
		font-size: 14px;
		line-height: 22px;
		color: var(--brand-neutral-grey-600-b, #4e5555);
		font-weight: 600;
		background: #f1f1f1;
		border-radius: 3px;
		padding: 3px 6px;
		border: 1px solid var(--system-silver-chalice-200, #dad8d7);
	}
}
