@import "styles/themes.module.scss";

.contentWrapper {
	flex-grow: 1;
	margin-bottom: 50px;
	border-radius: 4px;
	box-shadow: 0px 17px 33px -2px rgba(79, 75, 74, 0.05);
}

//.step {
//	margin-bottom: 25px;
//	gap: 15px;
//	display: flex;
//	flex-direction: column;
//	justify-content: flex-start;
//	align-items: flex-start;
//
//	.stepTitle {
//		font-weight: 500;
//		font-size: 18px;
//		line-height: 26px;
//		color: #312200;
//	}
//}
//

//
//.instructionBox {
//	display: flex;
//	flex-direction: row;
//	justify-content: flex-start;
//	justify-items: center;
//	align-items: center;
//	gap: 16px;
//
//	font-weight: 400;
//	font-size: 16px;
//	line-height: 24px;
//	color: #404040;
//
//	.optional {
//		color: #a39c9a;
//	}
//
//	.colored {
//		color: #0c5850;
//		font-weight: bold;
//	}
//
//	.filterLabels {
//		font-weight: 600;
//		//color: #000000;
//	}
//}

.profileRequestStats {
	//position: absolute;
	//z-index: 2;
	//right: 15px;
	//top: -58px;

	margin-top: 5px;
	display: flex;
	justify-content: flex-start;
	gap: 0px;
	align-items: center;
	align-self: stretch;

	@media screen and (max-width: 1024px) {
		display: none;
	}

	span {
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
		color: #a39c9a;
		padding: 0 8px;
		border-left: 1px solid #f4f0ed;

		&:first-child {
			padding-left: 0;
			border-left: none;
		}

		&:last-child {
			padding-right: 0;
			//border-left: none;
		}

		b {
			color: #404040;
			font-weight: 500;
		}

		span {
			color: $mainThemeColor;
			font-weight: 500;
		}
	}
}

.rejectedBlock {
	padding-top: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
}
