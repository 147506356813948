.container {
	width: 100%;
	padding: 16px;
	min-height: 245px;

	&:last-child {
		border-radius: 0 0 4px 4px;
	}
}

.empty {
	gap: 8px;
	display: flex;
	flex-direction: column;
	border: 1px solid #e3e3e3;
	border-top: none;
	padding: 24px;

	.text {
		margin: 0;
		font-size: 14px;
		font-weight: 500;
		color: #a39c9a;
		line-height: 22px;
	}

	.btn {
		gap: 8px;
		display: flex;
		max-width: 117px;
		color: #0c5850;
		padding: 6px 8px;
		border: 1px solid #0c5850;
		background-color: transparent;
		align-items: center;
	}
}
