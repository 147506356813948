.checkboxWrapper {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	align-items: center;
	align-content: center;
}

.statusCountSection {
	display: flex;
	justify-content: flex-start;
	gap: 2px;
	align-items: center;
	padding-right: 5px;

	small {
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		text-align: right;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	}
}

.checkboxContainer {
	margin: 6px 0;
}

.checkboxList {
	display: inline-flex;
	flex-direction: column;
	padding-right: 10px;
	width: 100%;
	max-height: 280px;
	overflow: hidden auto;
	scrollbar-width: thin;
}
