.filterContainer {
	min-height: auto;
	padding-bottom: 32px;
	padding-top: 25px;
	position: relative;
}

.title {
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: #271b00;
	margin-bottom: 15px;
	display: flex;
	flex-direction: row;
	gap: 8px;

	&.locked {
		justify-content: space-between;
		position: relative;

		.lockIconWrapper {
			position: relative;
			right: -5px;
			cursor: pointer;
		}
	}
}

.tooltip {
	border-radius: 4px !important;

	font-weight: 300 !important;
	font-size: 14px !important;
	line-height: 22px !important;

	color: #404040 !important;
	width: 314px;
	opacity: 1 !important;
	box-shadow: 0 4px 32px rgba(0, 0, 0, 0.18) !important;
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	padding: 16px;
	z-index: 1;

	b {
		font-weight: 500 !important;
	}

	.green {
		font-weight: 600;
		color: #0c5850;
		text-decoration: underline;
	}
}

.overlay {
	position: absolute;
	width: 110%;
	top: 10px;
	height: 100%;
	left: -10px;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #ffffff;
	opacity: 0.85;
	z-index: 2;

	.overlayText {
		position: relative;
		top: -20px;
		text-align: center;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		width: 100%;
		color: #808080;

		.loading {
			&:after {
				content: "....";
				width: 0;
				position: absolute;
				overflow: hidden;
				animation: loader-dots-animation 3s infinite;
				word-break: break-all;
			}

			@keyframes loader-dots-animation {
				0% {
					width: 0.1em;
				}
				50% {
					width: 1.2em;
				}
				100% {
					width: 0;
				}
			}
		}
	}
}
