.container {
	width: 100%;
	z-index: 3;
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-bottom: 30px;
}

.wrapper {
	position: relative;

	.stickyHeader {
		z-index: 3;
		position: fixed;
		top: 65px;
		left: 70px;
		width: calc(100% - 70px);
		background-color: white;
		display: flex;
		padding: 16px 16px 12px 16px;
		justify-content: space-between;
		align-items: center;
		align-self: stretch;

		h1 {
			overflow: hidden;
			color: var(--Brand-Neutral-Grey-800, #2c3030);
			text-overflow: ellipsis;
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: 32px;
		}

		.rightSection {
			display: flex;
			padding: 6px 8px;
			justify-content: center;
			align-items: center;
			gap: 6px;

			.pptButton,
			.editProjectButton {
				height: 32px;
				display: flex;
				padding: 6px 8px;
				justify-content: center;
				align-items: center;
				gap: 6px;
				color: var(--Brand-Neutral-Grey-600-B, #4e5555);
				font-size: 13px;
				font-style: normal;
				font-weight: 600;
				line-height: 18px;
				border-radius: 4px;
				border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
				background: transparent;
				white-space: nowrap;
				width: fit-content;
			}
		}
	}
}
