.buttonRow {
	display: flex;
	flex-direction: row;
	//padding: 10px;
}

.buttonRow {
	justify-content: flex-end;
}

.saveButton,
.rejectButton,
.recommendButton {
	//margin-top: 1rem;
	display: flex;
	align-items: center;
	gap: 6px;
	border-radius: 4px;
	max-height: 32px;
	padding: 6px 12px;
	min-width: auto;
	background-color: #ffffff;
	color: #181b1a;
}

.saveButton {
	border: 1px solid rgba(12, 88, 80, 1);
}

.rejectButton {
	border: 1px solid rgba(201, 21, 21, 1);
}

.recommendButton {
	border: 1px solid var(--Brand-Tertiary-600-B, #e0a764);

	svg {
		width: 16px;
		height: 16px;
	}
}

.expandable {
	width: 32px;
	height: 30px;
	border-left: 1px solid #dad8d7;

	svg {
		height: 18px;
		width: 19px;
		position: relative;
		right: 2px;

		path {
			fill: #4e5555;
		}
	}
}

.buttonGroup {
	font-style: normal;
	font-size: 18px;
	line-height: 22px;
	display: flex;
	text-align: right;
	justify-content: flex-end;
	gap: 8px;
}
