.checkboxContainer {
	margin: 6px 0;
}

.checkboxList {
	display: inline-flex;
	flex-direction: column;
	padding-right: 10px;
	width: 100%;
	max-height: 280px;
	overflow: hidden auto;
}
