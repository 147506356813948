.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 32px 0 24px 0;

	h1 {
		margin: 0;
		font-size: 22px;
		line-height: 30px;
		font-weight: 500;
		user-select: none;
		color: #808080;
	}

	.search {
		background-color: #ffffff;
	}

	.buttonGroup {
		display: flex;
		align-items: center;

		.createButton {
			flex-shrink: 0;
			border-radius: 4px;
			border: none;
			color: #ffffff;
			display: flex;
			font-size: 14px;
			font-weight: 600;
			line-height: 24px;
			margin-left: 8px;
			padding: 8px 12px;
			text-align: center;
			width: auto;

			svg {
				margin-right: 8px;
			}
		}
	}

	.multiSelect {
		margin-left: 8px;
	}
}
