.modalContent {
	gap: 24px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
}

.input {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	align-self: stretch;
}
