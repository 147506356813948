.wrapper {
	border-radius: 4px;
	background: #fff;
	margin-bottom: 82px;
	padding-bottom: 24px;
	box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.08);

	&__head {
		display: flex;
		padding: 15px 24px;
		align-items: center;
		justify-content: space-between;

		&_title {
			margin: 0;
			font-size: 18px;
			color: #271b00;
			font-weight: 500;
			line-height: 22px;
			font-family: "Inter";
		}

		&__create {
			gap: 8px;
			width: 100%;
			border: none;
			display: flex;
			font-size: 14px;
			max-width: 220px;
			font-weight: 600;
			padding: 8px 16px;
			line-height: 24px;
			border-radius: 4px;
			align-items: center;
			font-family: "Inter";

			&_icon {
				display: flex;
				max-width: 22px;
				min-width: 22px;
				max-height: 22px;
				min-height: 22px;
				border-radius: 50%;
				align-items: center;
				justify-content: center;
				border: 2px solid #fff;

				svg {
					path {
						stroke: #fff;
					}
				}
			}
		}
	}

	&__hr {
		width: 100%;
		height: 1px;
		margin-top: 0;
		margin-bottom: 24px;
		border: 0.5px solid #e1e1e1;
	}
}
