.switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
	user-select: none;
}

.switch .input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 16px;
	width: 16px;
	left: 2px;
	bottom: 2px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:after {
	position: absolute;
	content: "";
	height: 4px;
	width: 4px;
	left: 8px;
	bottom: 8px;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 50%;
}

.input:checked + .slider {
	background-color: #0c5850;
}

.input:focus + .slider {
	box-shadow: 0 0 1px #0c5850;
}

.input:checked + .slider:after {
	-webkit-transform: translateX(20px);
	-ms-transform: translateX(20px);
	transform: translateX(20px);
	background-color: #ffffff;
}

.input:checked + .slider:before {
	-webkit-transform: translateX(20px);
	-ms-transform: translateX(20px);
	transform: translateX(20px);
}

.slider.round {
	border-radius: 18px;
}

.slider.round:before {
	border-radius: 50%;
}
