.sidebarTabWrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 4px;
	background: var(--system-bw-white, #fff);
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
	max-height: calc(100vh - 370px);
	overflow-y: auto;

	&.noSearchRequests {
		display: none;
	}

	.tab {
		display: flex;
		padding: 20px 8px 20px 16px;
		justify-content: space-between;
		align-items: center;
		align-self: stretch;
		border-radius: 4px 4px 0 0;
		border-bottom: 1px solid var(--System-Silver-Chalice-100, #eeedec);
		background: var(--System-Silver-Chalice-50, #f8f7f7);

		&.active {
			background: #ffffff;
			border-bottom: 1px solid var(--System-Silver-Chalice-100, #eeedec);
		}

		&:hover {
			cursor: pointer;
		}
	}

	.title {
		display: -webkit-box;
		max-width: 280px;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;

		overflow: hidden;
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		text-overflow: ellipsis;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
	}

	.countWrapper {
		display: flex;
		flex-direction: row;
		gap: 5px;
		align-items: center;

		.count {
			display: flex;
			height: 20px;
			min-width: 20px;
			padding: 4px;
			justify-content: center;
			align-items: center;
			gap: 8px;
			border-radius: 4px;
			background: var(--System-Silver-Chalice-100, #eeedec);

			flex: 1 0 0;
			color: var(--Brand-Neutral-Grey-800, #2c3030);
			text-align: center;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px; /* 166.667% */
		}
	}
}
