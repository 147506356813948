.container {
	//padding: 0 20px 17px;

	&__is_empty {
		text-align: center;
	}
}

.hidden {
	display: none;
}

.companies {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: 1fr;
	gap: 12px;

	@media screen and (min-width: 1025px) and (max-width: 1440px) {
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	}

	@media (max-width: 1024px) {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
}

.company {
	background: #fff;
	border: 1px solid #eeedec;
	border-radius: 4px;
	padding: 16px;
	transition: filter 0.2s linear;
	position: relative;

	&__select {
		display: none;
		top: 16px;
		right: 16px;
		cursor: pointer;
		position: absolute;

		&_active {
			display: block;
		}
	}

	&:hover &__select {
		display: block;
	}
}

.top {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.right {
	width: 78%;
	margin-left: 16px;
}

.name {
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 4px;
	width: 100%;
	cursor: pointer;
}

.industry {
	font-weight: 500;
	font-size: 13px;
	line-height: 19px;
	color: #404040;
}

.logo {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid #d1cdcc;
	user-select: none;
	pointer-events: none;
}

.bottom {
	font-size: 13px;
	font-weight: 400;
	line-height: 19px;
	padding-left: 57px;
	align-self: stretch;
}

.left {
	cursor: pointer;
}

.tooltip {
	background-color: #ffffff;
	box-shadow: 0px 0px 31px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	opacity: 1;
	width: 280px;
	padding: 16px;
	z-index: 1;

	@media (max-width: 753px) {
		left: 40% !important;
	}
}

.loader {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	gap: 5px;
}
.showMore {
	text-align: center;
	margin-top: 24px;
}

.skeleton_container {
	gap: 12px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}
