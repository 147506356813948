@import "styles/common.module.scss";

.badge {
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	/* identical to box height, or 200% */
	padding: 1px 5px 1px 5px;
	color: #ffffff;
	background: $mainThemeColor;
	border-radius: 10px;
	top: 10px;
	position: absolute;
}

.link {
	cursor: pointer;
}

.shrinkedBadge {
	font-size: 8px !important;
}
