.wrapper {
	width: 100%;
	display: flex;
	margin: 0 auto;
	padding: 8px 0;
	background: #e3e3e3;
	border: 1px solid #f0f0f0;
	border-bottom: none;
	border-radius: 4px 4px 0px 0px;
	user-select: none;
	box-sizing: border-box;

	&__container {
		width: 100%;
		display: flex;
		max-width: 1064px;
	}

	&__btn {
		width: 100%;
		display: flex;
		padding-left: 16px;
		min-width: max-content;
		box-sizing: border-box;

		&_text {
			width: 100%;
			cursor: default;
			font-size: 14px;
			font-weight: 500;
			color: #383838;
			line-height: 22px;
			width: max-content;
			font-family: "Inter";
		}

		&_left {
			text-align: left;
		}

		&_right {
			text-align: right;
		}

		&_center {
			text-align: center;
		}

		&:nth-child(1) {
			max-width: 29%;
		}

		&:nth-child(2) {
			max-width: 29%;
		}

		&:nth-child(3) {
			max-width: 14%;
		}

		&:nth-child(4) {
			max-width: 14%;
		}

		&:nth-child(5) {
			max-width: 14%;
		}
	}

	&_skeleton {
		max-width: 40px;
		min-width: 40px;
	}
}
