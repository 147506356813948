.wrapper {
	margin-top: 84px;

	.insight_container {
		width: 100%;

		&__genders {
			display: grid;
			grid-template-columns: 30% 70%;

			@media (max-width: 767px) {
				display: flex;
				flex-direction: column;
			}
		}
	}
}

.company {
	gap: 16px;
	width: 100%;
	display: flex;
	max-width: 100%;
	flex-wrap: wrap;
}
