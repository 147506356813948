@import "styles/common.module.scss";

.container {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 384px;
	position: fixed;
	top: 140px;
	left: 80px;
	max-height: calc(100vh - 200px);
	overflow: hidden auto;
	scrollbar-width: thin;

	@media #{$viewportLargeX} {
		margin-left: 12vw;
	}
	@media #{$viewportXLarge} {
		margin-left: 25vw;
	}
}

.wrapper {
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
	border-radius: 4px;
	background-color: #fff;

	@media #{$viewportTablet} {
		width: auto;
	}

	.jobTitleContainer {
		margin-bottom: 100px;
	}
}

.filterTitle {
	display: flex;
	align-items: center;
	gap: 8px;
	color: var(--Brand-Primary-600-B, #0c5850);
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px; /* 155.556% */
	padding: 16px;
	border-bottom: 1px solid var(--System-Silver-Chalice-100, #eeedec);
}
