.container {
	width: 100%;
	max-width: 420px;
	position: relative;

	&_margin {
		display: block;
		margin: 24px auto 16px;

		&_error {
			margin: 3px auto;
		}
	}

	&__wrapper {
		position: relative;
	}

	&__header {
		display: flex;
		margin-bottom: 6px;
		align-items: center;
		justify-content: space-between;

		&_label {
			font-size: 14px;
			font-weight: 600;
			color: #a2a2a2;
			line-height: 22px;
			font-family: "Inter";

			a {
				color: #0c5850;
				font-weight: 500;
				text-decoration: none;
			}
		}

		&_forgot {
			font-size: 14px;
			color: #0c5850;
			font-weight: 500;
			line-height: 22px;
			font-family: "Inter";
		}
	}

	&__inner {
		height: 48px;
		display: flex;
		font-size: 1rem;
		font-weight: 400;
		color: #383838;
		overflow: hidden;
		line-height: 26px;
		position: relative;
		border-radius: 4px;
		align-items: center;
		font-family: "Inter";
		transition: all 0.1s;
		border: 1px solid #e4e6eb;

		&_disabled {
			background-color: rgba(127, 17, 224, 0.6);
		}

		&_error {
			border-color: #e05a5a !important;
		}

		&__input {
			width: 100%;
			height: 100%;
			border: none;
			outline: none;
			font-size: 1rem;
			color: #383838;
			font-weight: 400;
			line-height: 26px;
			font-family: "Inter";
			padding: 8px 40px 8px 16px;
			background-color: white;

			&:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 1000px #fff inset;
			}
		}

		&_right_icon {
			padding: 0;
			right: 17px;
			height: 100%;
			border: none;
			display: flex;
			cursor: pointer;
			position: absolute;
			align-items: center;
			background-color: transparent;

			svg {
				height: 21px;
			}
		}
	}

	&__error {
		display: block;
		font-size: 12px;
		margin-top: 6px;
		color: #e05a5a;
		font-weight: 500;
		line-height: 18px;
		font-family: "Inter";
	}

	&__is_valid {
		display: block;
		margin-top: 6px;
		font-size: 14px;
		font-weight: 400;
		color: #d94222;
		line-height: 24px;
		font-family: "Inter";
	}
}

.errors {
	top: 17px;
	width: 100%;
	z-index: 999;
	max-width: 314px;
	position: absolute;
	left: calc(100% + 6px);

	&__container {
		width: 100%;
		padding: 16px;
		position: relative;
		border-radius: 4px;
		background: #fff;
		box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);

		ul {
			li {
				display: flex;
				align-items: center;

				svg {
					margin-right: 8px;
				}

				span {
					font-size: 14px;
					color: #2ba44f;
					font-weight: 400;
					line-height: 24px;
					font-family: "Inter";
				}
			}
		}

		&__icon {
			top: 32px;
			left: -9px;
			position: absolute;
			transform: rotate(-90deg);
			padding-bottom: 0 !important;
			border-bottom: none !important;
			box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
		}

		&__text {
			margin-top: 0;
			font-size: 16px;
			color: #000000;
			font-weight: 500;
			line-height: 24px;
			margin-bottom: 8px;
			font-family: "Poppins";
		}
	}
}
