@import "styles/variables.module.scss";

.container {
	display: flex;
	font-size: 14px;
	border-top: 1px solid #e1e1e1;
	border-bottom: 1px solid #e1e1e1;
	padding: 16px 24px;
	position: sticky;
	top: 70px;
	background-color: #fff;
	z-index: 2;

	@media (max-width: 870px) {
		top: 0px;
	}

	@media (max-width: 805px) {
		margin-left: 0;
	}

	@media (max-width: 1024px) {
		flex-direction: column;
	}

	&_name {
		@media (max-width: 950px) {
			top: 0px;
		}
	}

	&__filters {
		display: flex;
		justify-content: space-between;
		width: 100%;
		max-width: 870px;

		//@media #{$viewportTablet} {
		//  width: 100%;
		//  display: grid;
		//  grid-template-columns: repeat(2, 1fr);
		//  gap: 15px;
		//  border: none;
		//}

		@media (max-width: 1024px) {
			max-width: 100%;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			flex-direction: column;
		}

		@media (max-width: 767px) {
			display: flex;
			flex-direction: column;
			width: 100%;
			max-width: 100%;
		}

		&__wrapper {
			width: 500px;
			position: relative;
		}
	}

	&__actions {
		max-width: 234px;
		width: 100%;
		display: flex;

		@media #{$viewportTablet} {
			width: 100%;
			//margin-left: 15px;
			align-items: center;
			justify-content: end;
		}

		@media screen and (max-width: 1024px) {
			margin-top: 20px;
			max-width: 100%;
		}

		&__reset {
			width: 100%;
			height: 40px;
			display: flex;
			padding: 0 5px;
			min-width: 100px;
			align-items: center;
			border: 1px solid #cbc5c2;
			background-color: transparent !important;
			border-right: none;

			@media #{$viewportLargeS} {
				padding: 0 15px;
			}

			@media #{$viewportTablet} {
				max-width: 120px;
				padding: 9px 15px;
				border: 1px solid #cbc5c2;
				border-radius: 4px 0 0 4px;
				border-right: none;
			}

			span {
				color: #d1cdcc;
				font-weight: 600;
				font-size: 14px;
				line-height: 22px;
				margin-left: 8px;
			}

			&_resettable {
				border: 1px solid #cbc5c2;
				background-color: transparent !important;

				span {
					color: #404040;
				}
			}

			&_disabled {
				path {
					fill: #312200;
				}
			}
		}

		&__map {
			display: flex;
			height: 40px;
			min-width: 180px;
			align-items: center;
			justify-content: center;
			border: 1px solid #0c5850;
			border-radius: 0 4px 4px 0;

			&__disabled {
				pointer-events: none;
				border: 1px solid #ccc;
			}

			span {
				font-size: 12px;
				margin-left: 8px;
				font-weight: 600;
				line-height: 22px;
				min-width: 72px;
				text-align: left;

				@media #{$viewportLargeS} {
					font-size: 14px;
				}
			}

			&_loading {
				pointer-events: none;

				span,
				svg {
					opacity: 0.5;
				}
			}
		}
	}
}

.progress {
	left: -1px;
	bottom: -1px;
	position: absolute;

	&__mini {
		border-radius: 0 1px 1px 1px !important;
		background: #e1e1e1 !important;
		height: 2px !important;
	}
}
