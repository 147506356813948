@import "styles/variables.module.scss";

.filter_container {
	@media (max-width: 1025px) {
		max-width: 347px;
	}

	@media (max-width: 950px) {
		max-width: 300px;
	}

	@media (max-width: 850px) {
		max-width: 250px;
	}
}

.filterBlocksWrapper {
	display: flex;
	gap: 7px;
	flex-direction: column;
	width: 384px;
	min-height: 500px;
	flex-shrink: 0;
	position: sticky;
	top: 150px;

	@media screen and (max-width: 1024px) {
		width: 100%;
		max-width: 100%;
		position: relative;
		top: 0;
		left: 0;
		min-height: max-content;
	}

	@media screen and (max-width: 767px) {
		min-height: auto;
	}

	.additionalProfilesFilter,
	.rejectedProfilesFilter {
		@media screen and (max-width: 767px) {
			display: none;
		}
	}
}

.filterBlock {
	display: flex;
	flex-direction: row;
	border-radius: 5px;
	background: #fff;
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);

	&.prFilter {
		flex-direction: column;
	}

	&.active {
		min-height: 200px;

		.headerSection {
			border-bottom: 1px solid rgba(209, 205, 204, 1);
			//margin-bottom: 18px;
		}
	}

	&.active {
		border: 1px solid rgba(12, 88, 80, 0.2);
	}
}

.headerSection {
	position: relative;
	padding: 13px 37px 13px 17px;
	display: flex;
	justify-items: center;
	justify-content: space-between;
	gap: 8px;

	.filterTitle {
		flex-grow: 10;
		//color: #181B1A;
		display: flex;
		align-items: center;
		gap: 12px;
	}

	&.opened {
		background: var(--System-Silver-Chalice-100, #eeedec);
	}

	.titleLabel {
		gap: 8px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		flex-basis: max-content;
	}
}

.findHeaderSection {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media (max-width: 950px) {
		flex-direction: column;
		align-items: flex-start;
	}

	.findInfoBlock {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;

		&__title {
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			color: #0c5850;
			min-width: max-content;
		}

		&__resultCountOnFind {
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 22px;
			display: flex;
			align-items: flex-end;
			color: #808080;
			min-width: max-content;
		}

		&:hover {
			cursor: pointer;
		}
	}
}

.inProgress {
	cursor: not-allowed;

	* {
		cursor: not-allowed;
		opacity: 0.85;
	}

	.title {
		color: gray;
	}
}

.disabled {
	* {
		opacity: 0.75;
	}
}

.selectAll {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	color: #5a5a5a;

	&:hover {
		cursor: pointer;
	}
}

.sortable {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #0c5850;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
}

.buttonBlock {
	display: flex;
	flex-direction: column;
	padding: 13px 17px;
	width: 100%;

	.infoText {
		font-weight: 400;
		font-size: 13px;
		line-height: 20px;
		//margin-left: 7px;

		span {
			font-weight: 500;
		}
	}

	.actionButton {
		margin-top: 12px;
		width: 100%;
		display: flex;
		//align-items: center;
		//justify-content: center;
		flex-direction: column;
	}

	.lunchButton {
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 600;
		font-size: 14px;
		line-height: 22px;
		color: #ffffff;
		width: 100%;

		&__out_of_credit {
			background: #a39c9a !important;
			border: 1px solid #a39c9a !important;
		}
	}
}

.checkboxList {
	display: inline-flex;
	flex-direction: column;
	padding-right: 0;
	width: 100%;
	max-height: 280px;
	overflow: auto;
}

.tooltip {
	background-color: #ffffff;
	box-shadow: 0 0 31px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	opacity: 1;
	width: 280px;
	padding: 16px;
	z-index: 1000;

	@media (max-width: 753px) {
		left: 40% !important;
	}
}

.aiWebSearch {
	display: flex;
	padding: 16px;
	justify-content: space-between;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 4px;
	background: var(--White, #fff);
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);

	.title {
		display: flex;
		align-items: center;
		gap: 8px;

		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: 28px; /* 155.556% */
	}

	.newSearch {
		display: flex;
		padding: 6px 16px 6px 8px;
		justify-content: center;
		align-items: center;
		gap: 4px;
		border-radius: 100px;
		border: 1px solid var(--Brand-Primary-600-B, #0c5850);

		color: var(--Brand-Primary-600-B, #0c5850);
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */

		&:hover {
			cursor: pointer;
		}
	}

	&.inProgress {
		cursor: not-allowed;

		* {
			cursor: not-allowed;
			opacity: 0.85;
		}

		.title {
			color: gray;
		}
	}

	.disabled {
		* {
			opacity: 0.75;
		}
	}
}
