.accordion {
	width: 100%;
	display: flex;
	flex-direction: column;
	font-family: "Inter", sans-serif;
	font-size: 14px;
	color: #4e5555;
}

.accordionItem {
	border: 1px solid #eeedec;
	border-top: none;
	overflow: hidden;
	transition: all 0.3s ease;

	&:first-child {
		border-top: 1px solid #eeedec;
		border-radius: 4px 4px 0 0;
	}

	&:last-child {
		border-radius: 0 0 4px 4px;
	}
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	padding: 6px 8px;
	background: #ffffff;
	cursor: pointer;
}

.titleWithIcon {
	display: flex;
	flex-direction: row;
	gap: 8px;
}

.title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	color: var(--Brand-Neutral-Grey-600-B, #4e5555);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}

.content {
	max-height: 0;
	padding: 0 8px 2px 36px;
	overflow: hidden;
	transition: max-height 0.5s ease, height 0.3s ease;
	background: #ffffff;

	color: var(--Brand-Neutral-Grey-500, #686e6e);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
}

.contentOpen {
	max-height: 1500px;
}
