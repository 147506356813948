@import "styles/variables.module.scss";

.is {
	&_home {
		margin-right: 0 !important;
	}

	&_z_index {
		z-index: 0 !important;
	}
}

.menu {
	display: none;

	@media screen and (max-width: 1024px) {
		display: flex;
		position: absolute;
		left: 10px;
		top: 10px;
		z-index: 101;
	}

	&.closeMenu {
		top: 10px;
		left: 170px;
	}
}

.nav {
	align-items: flex-start;
	background-color: #fff;
	border-right: 1px solid #e9e9e9;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: flex-start;
	left: 0;
	top: 0;
	padding: 0 16px 16px;
	position: fixed;
	user-select: none;
	width: 248px;
	z-index: 99;
	margin-right: auto;

	@media print {
		display: none !important;
	}

	//&__selected {
	//  @media (max-width: 950px) {
	//    top: 125px;
	//    height: calc(100vh - 125px);
	//  }
	//}

	@media (max-width: 1024px) {
		height: 100vh;
		padding-top: 30px;
		padding-left: 6px;
		width: 200px;
	}

	&.navCollapsed {
		width: 72px;

		@media screen and (max-width: 1024px) {
			visibility: hidden;
		}
	}

	.navItems {
		width: 100%;
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: space-between;

		&__logo {
			margin: 20px 0 20px 3px;
			border: 0;
			cursor: pointer;
			font-size: 14px;
			height: 40px;
			font-weight: 500;
			line-height: 17px;
			flex-direction: row;
			background: transparent;
			display: none;

			@media screen and (min-width: 1025px) {
				display: flex;
			}
		}

		&__container {
			gap: 16px;
			display: flex;
			flex-direction: column;
		}

		&__upgrade {
			gap: 6px;
			padding: 8px;
			display: flex;
			align-items: center;
			justify-content: center;

			&_hovered {
				height: 48px;
				align-items: flex-start;
			}

			&__icon {
				svg {
					path {
						fill: #fff;
					}
				}

				&_hovered {
					margin-top: 2px;
				}
			}

			&__content {
				text-align: left;
				overflow: hidden;

				&_title {
					margin: 0;
					color: #edf2f2;
					font-size: 14px;
					font-weight: 600;
					line-height: 20px;
					overflow: hidden;
				}

				&_subtitle {
					color: #b1c9c6;
					overflow: hidden;
					font-size: 10px;
					font-weight: 600;
					line-height: 14px;
				}
			}
		}

		&__footer {
			gap: 8px;
			display: flex;
			align-items: center;
			flex-direction: column;

			&__user_img {
				border-radius: 50px;
				border: 1px solid grey;
				width: 24px;
				height: 24px;
				object-fit: contain;
				padding: 4px;
			}

			&__info {
				gap: 8px;
				width: 100%;
				display: flex;
				cursor: pointer;
				align-items: center;

				&_close {
					justify-content: center;
				}

				&_text {
					color: #868d8d;
					margin: 0;
					font-family: Inter;
					font-size: 14px;
					font-weight: 500;
					overflow: hidden;
					line-height: 20px;
				}
			}

			&__solid {
				width: 100%;
				height: 1px;
				border: 0.5px solid #eeedec;
			}

			&__user {
				gap: 8px;
				width: fit-content;
				display: flex;
				align-items: center;

				&_close {
					justify-content: center;
				}

				&_img {
					width: 20px;
					height: 20px;
				}

				&__info {
					display: flex;
					flex-direction: column;

					&_name {
						color: #2c3030;
						font-family: Inter;
						font-size: 14px;
						font-weight: 500;
						line-height: 20px;
						overflow: hidden;
						margin: 0;
						text-overflow: ellipsis;
					}

					&_email {
						overflow: hidden;
						color: #868d8d;
						font-family: Inter;
						font-size: 12px;
						font-weight: 500;
						line-height: 16px;
						margin: 0;
						max-width: 188px;
						width: 100%;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
			}

			&__plus {
				gap: 8px;
				width: 100%;
				display: flex;
				flex-direction: column;

				&_title {
					color: #4e5555;
					text-align: center;
					font-family: Inter;
					font-size: 12px;
					font-weight: 400;
					line-height: 16px;
					margin: 0;
					overflow: hidden;
				}

				&_description {
					border-radius: 4px;
					border: 1px solid #0c5850;
					margin: 0;
					overflow: hidden;
					padding: 8px;
					width: 100%;
					color: #0c5850;
					text-align: center;
					font-family: Inter;
					font-size: 12px;
					font-weight: 600;
					line-height: 16px;
				}
			}

			&_video {
				width: 100%;
			}
		}
	}
}

.toggle {
	width: 100%;
	margin-top: auto;

	&::before {
		font-size: 1.3rem;
	}
}

.navItem {
	text-decoration: none;
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 6px;
	width: 100%;
	transition: all 0.2s ease-in-out;
	white-space: nowrap;

	svg {
		flex-shrink: 0;
	}

	&.expanded {
		&:hover {
			transition: background 0.2s linear, color 0.2s linear;
			background: rgba(42, 35, 100, 0.1);
			border-radius: 4px;
			color: #2c2c2c !important;
			text-decoration: none !important;
		}
	}
}

.navItemIcon {
	width: 25px;
	height: 25px;
	font-size: 20.64px;
	display: flex;
}

.navItem {
	color: #808080;
}

.navItemText {
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	font-family: "Inter", sans-serif;
	animation: fadeIn 0.4s;
}

.link {
	color: #808080;

	svg path {
		fill: #808080;
	}
}

.link.active {
	.navItem,
	.navMainItem {
		color: #383838 !important;
		fill: $mainThemeColor !important;
		transition: background 0.2s linear, color 0.2s linear;
		background: rgba(42, 35, 100, 0.1);
		border-radius: 4px;

		svg path {
			fill: #383838 !important;
		}
	}
}

.hamburgerIcon {
	color: rgba(42, 35, 100, 0.75) !important;
	padding-left: 6px;

	&:hover {
		cursor: pointer;
	}
}

.navItemWrapper {
	position: relative;
	width: 100%;

	.countWrapper {
		position: absolute;
		left: 30px;
		bottom: 20px;
		z-index: 9999;
		width: 45px;

		.badge {
			font-size: 10px;
			padding: 4px 8px 5px;
			color: #ffffff;
			background: $badgeBackground;
			border-radius: 30px;
			position: relative;
			top: -10px;
		}
	}
}

.plus {
	background-color: #edf2f2;
	padding: 0 2px;
	border-radius: 2px;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	color: #0c5850;
	height: 20px;
	margin-left: 6px;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.mainTitle {
	display: flex;
	flex-direction: column;

	.navMainItem {
		gap: 8px;
		padding: 6px;
		display: flex;
		align-items: center;
		width: 100%;
		//height: 40px;

		&:hover {
			transition: background 0.2s linear, color 0.2s linear;
			background: rgba(42, 35, 100, 0.1);
			border-radius: 4px;
			color: #2c2c2c !important;
			text-decoration: none !important;
			cursor: pointer;
		}

		.navItemText {
			//margin-left: 8px;
			min-width: 150px;
			//display: inline-flex;
		}
	}

	.navChildItem {
		overflow: hidden;
		color: var(--Brand-Neutral-Grey-800, #868d8d);
		text-overflow: ellipsis;

		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */

		margin-left: 14px;
		padding: 6px 8px;

		&.active {
			border-radius: 4px;
			background: rgba(42, 35, 100, 0.1);
			color: #2c2c2c !important;
		}
	}
}

.navChildItemsWrapper {
	margin-top: 18px;
	margin-left: 17px;
	margin-bottom: 8px;
	border-left: 1px solid #eeedec;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.arrowReversed {
	transform: rotateX(180deg);
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.logoFadeIn {
	animation: fadeIn 0.6s ease-in-out;
}
