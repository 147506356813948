@import "responsive";
@import "../variables.module";

@mixin breakpoint($class) {
	@if $class == $smallerMobileDevice {
		@media (max-width: $smallerMobileBreakPoint) {
			@content;
		}
	} @else if $class == $mobileDevice {
		@media (max-width: $mobileBreakPoint) {
			@content;
		}
	} @else if $class == $tabDevice {
		@media (min-width: $mobileBreakPoint) and (max-width: $tabBreakPoint) {
			@content;
		}
	} @else if $class == $mediumDevice {
		@media (min-width: $tabBreakPoint) and (max-width: $mediumDeviceBreakPoint) {
			@content;
		}
	} @else if $class == $largeDevice {
		@media (min-width: $mediumDeviceBreakPoint) and (max-width: $largeDeviceBreakPoint) {
			@content;
		}
	} @else if $class == $extraLargeDevice {
		@media (min-width: $largeDeviceBreakPoint) {
			@content;
		}
	} @else if $class == $largerThanMobile {
		@media (min-width: $mobileBreakPoint) {
			@content;
		}
	} @else if $class == $largerThanTab {
		@media (min-width: $tabBreakPoint) {
			@content;
		}
	} @else if $class == $largerThanMediumDevice {
		@media (min-width: $mediumDeviceBreakPoint) {
			@content;
		}
	} @else if $class == $largerThanLargerDevice {
		@media (min-width: $largeDeviceBreakPoint) {
			@content;
		}
	} @else {
		@warn "Breakpoint mixin supports: xs, sm, md, lg";
	}
}

@mixin section-title {
	font-size: 20px;
	line-height: 30px;
	font-style: normal;
	font-weight: 500;
	color: #141414;
}

@mixin section-title-light {
	font-size: 16px;
	line-height: 26px;
	font-style: normal;
	font-weight: 500;
	color: #141414;
	display: flex;
	justify-content: space-between;
}

@mixin font14-light($font-weight: 400) {
	font-size: 14px;
	line-height: 20px;
	font-weight: $font-weight;
}

@mixin font13-light($font-weight: 300) {
	font-size: 13px;
	line-height: 20px;
	font-weight: $font-weight;
}

@mixin font14-bold($font-weight: 500) {
	font-size: 14px;
	line-height: 20px;
	font-weight: $font-weight;
}

@mixin containerPadding($paddingX: 21px) {
	padding: $paddingX 20px;
	//overflow: auto;
	@include breakpoint(gtxs) {
		padding: $paddingX 25px;
	}

	@include breakpoint(gtsm) {
		padding: $paddingX 50px;
	}

	@include breakpoint(gtmd) {
		padding: $paddingX 75px;
	}

	@include breakpoint(gtlg) {
		padding: $paddingX 100px;
	}
}

@mixin input-design() {
	flex: 1;
	border: 1px solid #dadada;
	background: transparent;
	font-size: 14px;
	line-height: 24px;
	height: 40px;
	padding: 8px 32px 8px 16px;
	border-radius: 4px;
	outline: none;
	color: $inputColor !important;
	box-sizing: border-box;

	&:focus {
		border-color: #0c5850;
	}

	@include placeholder {
		color: #a39c9a;
	}
}

@mixin candidate-card-gap($width: auto) {
	@include breakpoint(gtxs) {
		margin-right: 10px;
	}

	@include breakpoint(gtsm) {
		margin-right: 15px;
		width: $width;
	}

	@include breakpoint(gtmd) {
		margin-right: 20px;
	}
}

@mixin scroll-bar() {
	::-webkit-scrollbar {
		width: 4px;
	}

	::-webkit-scrollbar-track {
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb {
		background: #3c6662;
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #0c5850;
	}
}

@mixin ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin responsive-home-flex() {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 15px;
}

@mixin responsive-home-grid() {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(288px, 0.1fr));
	grid-column-start: col-start 2;
	gap: 15px;
	justify-content: center;
	margin-top: 24px;
}
