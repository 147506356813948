.checkboxContainer {
	margin-bottom: 12px;
}

.scrollContainer {
	display: flex;
	flex-direction: column;
}

.checkboxList {
	display: inline-flex;
	flex-direction: column;
	padding-right: 10px;
	width: 100%;
	max-height: 280px;
	overflow: hidden auto;
}

.headerSection {
	display: flex;
	justify-content: flex-start;
	cursor: pointer;
	font-size: 12px;
	font-weight: 500;
	color: #5a5a5a;
	gap: 15px;
	height: 25px;
}
.hasNoSelectAll {
	height: fit-content;
}

.selectAll {
	margin-top: -2px;
	font-size: 12px;
	font-weight: 500;
}

.DateInputWrapper {
	margin: 10px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	label {
		font-size: 14px;
		font-weight: 500;
	}

	section {
		border: none;
		border-radius: 4px;
	}
}
