.container {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: flex;
	position: fixed;
	align-items: center;
	justify-content: center;
	background-color: rgba(20, 20, 20, 0.7);
}
.wrapper {
	gap: 16px;
	width: 100%;
	display: flex;
	padding: 32px;
	max-width: 400px;
	border-radius: 8px;
	position: relative;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	flex-direction: column;

	&__icon {
		stroke: #f5a300;
	}

	&__title {
		margin: 0;
		font-weight: 500;
		font-size: 22px;
		line-height: 30px;
		text-align: center;
		color: #2c3030;
	}

	&__subtitle {
		margin: 0;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: #4e5555;

		&_email {
			margin: 0;
			font-weight: 600;
		}
	}

	&__buttons {
		display: flex;
		align-items: center;
		gap: 16px;
		width: 100%;

		&_cancel {
			gap: 8px;
			border: none;
			padding: 13px 32px;
			width: 50%;
			border-radius: 4px;
			color: #fff;
			background: #808080;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&_delete {
			gap: 8px;
			width: 50%;
			padding: 13px 16px;
			background: #0b5850;
			border-radius: 4px;
			color: #fff;
			border: none;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
