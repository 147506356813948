.checkboxContainer {
	margin-bottom: 12px;
}

.scrollContainer {
	display: flex;
	flex-direction: column;
}

.checkboxList {
	display: inline-flex;
	flex-direction: column;
	padding-right: 10px;
	width: 100%;
	max-height: 280px;
	overflow: hidden auto;
}

.searchInputWrapper {
	position: relative;

	.floatAddedIcon {
		position: absolute;
		right: 9px;
		top: 8px;
		cursor: pointer;
		z-index: 2;
	}
}
