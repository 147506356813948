.moreButton {
	background: rgba(163, 156, 154, 0.1);
	border-radius: 3px;
	border: 0px;
	color: rgba(0, 0, 0, 0.5);
	padding: 0.5rem 0.7rem 0.5rem 0.7rem;
	margin-right: 0.4rem;
	margin-top: 0.6rem;
	cursor: pointer;
	transition: opacity 0.5s;
	opacity: 1;
}

.hide {
	opacity: 0;
	pointer-events: none;
}
