.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	user-select: none;
}

.title {
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	color: #2c3030;
}

.close {
	cursor: pointer;
	flex-shrink: 0;
}
