.tooltip {
	opacity: 1;
	z-index: 2;
	width: 100%;
	padding: 16px;
	display: flex;
	max-width: 320px;
	border-radius: 4px;
	flex-direction: column;
	background-color: #fff;
	box-shadow: 0px 0px 31px rgba(0, 0, 0, 0.08);

	&__head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;

		&_title {
			font-family: "Inter";
			margin: 0;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			color: #2c3030;
		}

		&_description {
			font-family: "Inter";
			margin: 0;
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			color: #2c3030;
		}
	}

	&__body {
		gap: 16px;
		display: flex;
		overflow-y: auto;
		max-height: 252px;
		flex-direction: column;

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			height: 100%;
			border-radius: 5px;
			background-color: #eeedec;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 5px;
			background-color: #0c5850;
		}

		&__item {
			gap: 8px;
			display: flex;
			cursor: pointer;

			&_img {
				height: 32px;
				min-height: 32px;
				max-height: 32px;
				min-width: 32px;
				max-width: 32px;
				border-radius: 50%;
			}

			&__texts {
				display: flex;
				flex-direction: column;

				&_name {
					font-family: "Inter";
					font-weight: 400;
					font-size: 14px;
					line-height: 22px;
					color: #2c3030;
					margin: 0;
				}

				&_description {
					font-family: "Inter";
					font-weight: 500;
					font-size: 12px;
					line-height: 20px;
					color: #4e5555;
					margin: 0;

					&__company {
						display: flex;
						gap: 6px;

						&_logo {
							width: 21px;
							height: 21px;
							border-radius: 5px;
						}

						&_name {
							font-size: 12px;
							margin: 0;
							font-weight: 500;
							color: #5a5a5a;
						}
					}
				}
			}
		}
	}
}
