@import "styles/common.module.scss";

.accordion {
	padding: 16px;
	height: 54px;
	flex: 1;
	width: 100%;

	@media (max-width: 950px) {
		min-width: 193px;
	}

	&:not(:last-child) {
		border-bottom: 1px solid #eeedec;
	}

	&.expanded {
		height: auto;
	}

	::-webkit-scrollbar {
		width: 4px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		border-radius: 10px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #3c6662;
		border-radius: 10px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #0c5850;
	}
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;

	.left {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		justify-content: space-between;
		flex: 0 0 96%;

		.title {
			color: #404040;
			font-size: 14px;
			line-height: 20px;
			font-weight: 600;
			user-select: none;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 100%;
			display: flex;
			flex-wrap: nowrap;
			flex-grow: 1;
		}

		.count {
			margin-left: 8px;
			color: #404040;
			font-weight: 600;
			font-size: 12px;
			user-select: none;

			padding: 4px;
			min-width: max-content;
			height: 24px;
			background: #f4f0ed;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.right {
		display: flex;
		& > svg {
			transition: transform 0.2s linear;

			&.rotated {
				transform: rotateX(180deg);
			}
		}
	}
}

.children {
	display: none;

	&.expanded {
		display: block;
		animation: fadeIn 1s;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.disabled {
	//pointer-events: none;

	.title {
		color: #b0b5b4 !important;
	}
}
