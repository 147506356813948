.addCandidateButton {
	display: flex;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	gap: 6px;
	border-radius: 4px;
	width: 140px;
	border: 1px solid var(--Brand-Primary-600-B, #0c5850);
	background: transparent !important;

	color: var(--Brand-Primary-600-B, #0c5850);
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px; /* 171.429% */
}

.addPeopleByLinkedIn {
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
}

.linkInNotification {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	text-decoration-line: underline;
	color: #0c5850;
}

.refresh {
	cursor: pointer;
	animation: spin 2s infinite linear;
	pointer-events: none;
}

@keyframes spin {
	100% {
		transform: rotate(0deg);
	}

	0% {
		transform: rotate(360deg);
	}
}
