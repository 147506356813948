.wrapper {
	position: relative;
	display: inline-flex;
	padding: 4px;
	border-radius: 4px;
	border: 1px solid #eeedec;
	background: #f8f7f7;
	width: 200px;
	height: 44px;
	user-select: none;
	gap: 8px;
}

.wrapper [type="radio"] {
	position: absolute;
	left: -9999px;
}

.wrapper [type="radio"]:checked:global(#modeOne) ~ label[for="modeOne"],
.wrapper [type="radio"]:checked:global(#modeTwo) ~ label[for="modeTwo"] {
	color: #edf2f2;
}

.wrapper [type="radio"]:checked:global(#modeOne) ~ label[for="modeOne"]:hover,
.wrapper [type="radio"]:checked:global(#modeTwo) ~ label[for="modeTwo"]:hover {
	background: transparent;
}

.wrapper [type="radio"]:checked#modeOne + label[for="modeOne"] ~ .highlighter {
	transform: none;
}

.wrapper .highlighter.modeTwo {
	transform: translateX(100%);
}

.wrapper label {
	align-items: center;
	border-radius: 2px;
	cursor: pointer;
	display: flex;
	font-size: 14px;
	font-weight: 600;
	justify-content: center;
	line-height: 20px;
	padding: 8px;
	transition: color 0.25s ease-in-out;
	width: 96px;
	z-index: 1;
}

.wrapper label:hover {
	background: #eeedec;
}

.wrapper .highlighter {
	background: #0c5850;
	border-radius: 2px;
	height: calc(100% - 8px);
	left: 4px;
	position: absolute;
	top: 4px;
	transition: transform 0.25s ease-in-out;
	width: calc(50% - 4px);
}
