@import "styles/common.module.scss";

.infoTitle {
	color: var(--Brand-Neutral-Grey-900, #181b1a);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 140% */
	cursor: pointer;
}

.infoDetail {
	color: var(--Brand-Neutral-Grey-600-B, #4e5555);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
}

.infoCategory {
	margin: 8px 0;
	display: flex;
	padding: 4px 8px;
	align-items: flex-start;
	width: max-content;
	gap: 8px;
	border-radius: 2px;
	background: var(--System-Silver-Chalice-50, #f8f7f7);
}

.infoCategoryIcon {
	color: rgba(0, 0, 0, 0.5);
}

.infoCategoryButton {
	color: var(--Brand-Neutral-Grey-800, #2c3030);
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 133.333% */

	&.selected {
		background: #d6f0ee !important;
		color: #009882 !important;
	}
}

.infoFirstRow {
	display: flex;
	flex-direction: row;
}

.logo {
	// width: 56px;
	// height: 56px;
	// margin-right: 25px;
	// border-radius: 50%;
	// border: 1px solid #d1cdcc;
	// object-fit: cover;
	// padding: 5px;

	margin-right: 25px;
	background-size: 49px;
	background-repeat: no-repeat;
	background-position: center;
	width: 56px;
	height: 56px;
	border-radius: 100px;

	@media screen and (max-width: 767px) {
		margin-right: 10px;
	}
}

.infoCompanyDescription {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}
