@import "styles/common.module.scss";

.title {
	background-color: #fff;
	color: $mainTextColor !important;
	outline: none;
	margin: 0;
	width: 100% !important;
	text-align: center;
	height: 48px;
	color: $mainTextColor !important;
	font-size: 14px;
	cursor: pointer;
	border: 0;
}

.tabTitle {
	width: 100%;
	cursor: pointer;
	min-width: 120px;
}

.activeTab {
	border-bottom: 2px solid $mainThemeColor !important;
}
