.wrapper {
	display: flex;
	padding: 8px 16px;
	align-items: center;
	background: #493c70;
	//margin: 72px 0 32px 49px;
	margin-bottom: -32px;
	justify-content: space-between;
	box-shadow: 0px 9px 35px rgba(0, 0, 0, 0.04);

	//@media (max-width: 870px) {
	//	margin-top: 125px;
	//}

	&__project_name {
		@media (max-width: 950px) {
			margin-top: 125px;
		}
	}

	&__info {
		gap: 12px;
		display: flex;
		align-items: center;
		justify-content: center;

		&_icon {
			width: 40px;
			height: 40px;
			display: flex;
			border-radius: 50%;
			align-items: center;
			background: #eeecf5;
			justify-content: center;
		}

		&_text {
			color: #f7f8f8;

			&_title {
				margin: 0;
				font-size: 20px;
				font-weight: 600;
				line-height: 28px;
			}

			&_subtitle {
				margin: 0;
				font-size: 14px;
				font-weight: 500;
			}
		}
	}

	&__video {
		display: flex;
		gap: 18px;
		align-items: center;
		justify-content: center;

		&_play {
			gap: 6px;
			display: flex;
			padding: 8px 16px;
			border-radius: 4px;
			align-items: center;
			font-family: "Inter";
			font-weight: 600;
			font-size: 14px;
			line-height: 22px;
			color: #493c70;
			background: #eeecf5;
			justify-content: center;
		}

		&_cancel {
			cursor: pointer;
		}
	}
}
