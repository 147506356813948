.container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	.progressBarSection {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		background: #ffffff;
		padding: 16px;
		border-bottom: 1px solid #eeedec;

		span {
			margin-top: 10px;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			color: #000000;
		}
	}

	.progressInfo {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;

		font-weight: 600;
		font-size: 14px;
		line-height: 22px;
		color: #312200;

		margin-bottom: 10px;

		span {
			font-weight: 400;
			font-size: 12px;
			line-height: 20px;
			color: #a39c9a;
			padding: 0 8px;
			border-left: 1px solid #f4f0ed;

			&:first-child {
				padding-left: 0;
				border-left: none;
			}

			&:last-child {
				padding-right: 0;
				//border-left: none;
			}

			b {
				color: #404040;
				font-weight: 500;
			}
		}
	}
}

.interactiveProgressInfo {
	display: flex;
	flex-direction: row;
	padding: 7px 16px;
	height: 48px;
	background: #ffffff;
	border-top: 1px solid #eeedec;
	border-bottom: 1px solid #eeedec;
	justify-content: space-between;
	align-items: center;

	.textInstruction {
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		text-align: left;
		color: #2c3030;
	}
}
