.regions {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: absolute;
	right: 34px;
	top: 34px;
	background: #ffffff;

	border: 1px solid #d1cdcc;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;

	.round {
		height: 24px;
		width: 24px;
		border-radius: 100px;
		margin-right: 7px;
		transform: translateY(-2px);
	}

	.region {
		margin: 0 12px;
		display: flex;
		align-items: center;
		position: relative;
		display: flex;
		flex-direction: column;

		.regionHeader {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 100%;

			.activeArrow {
				transform: rotate(180deg);
			}
		}

		.key {
			margin-right: 6px;
		}

		.countryList {
			top: 40px;
			right: -13px;
			background: #ffffff;
			max-height: 0;
			overflow: hidden;
			transition: 0.3s;

			z-index: 99;
			border-radius: 4px;
			width: 200px;

			div {
				display: flex;
				justify-content: space-between;

				p {
					margin: 4px 0;
				}
			}
		}

		.activeList {
			max-height: 400px;
		}
	}
}

.bold {
	font-weight: 600;
	margin-left: 5px;
}

.regionContainer {
	display: flex;
	padding: 12px 0;
	cursor: pointer;
}
