.wrapper {
	width: 100%;
	margin: 0 auto;

	&:nth-of-type(2n + 1) {
		background-color: #fafafa;
	}

	&__empty {
		gap: 8px;
		display: flex;
		flex-direction: column;
		border: 1px solid #e3e3e3;
		border-top: none;
		padding: 24px;

		&__text {
			margin: 0;
			font-size: 14px;
			font-weight: 500;
			color: #a39c9a;
			line-height: 22px;
		}

		&__btn {
			gap: 8px;
			display: flex;
			max-width: 117px;
			color: #0c5850;
			padding: 6px 8px;
			border: 1px solid #0c5850;
			background-color: transparent;
			align-items: center;
		}
	}

	&__latest {
		&:last-child &__head {
			border-radius: 0 0 4px 4px;
		}
	}

	&__head {
		width: 100%;
		display: flex;
		margin: 0 auto;
		border: 1px solid #e3e3e3;
		border-top: none;

		&__container {
			width: 100%;
			display: flex;
			max-width: 1032px;
		}

		&__show {
			display: flex;
			padding: 10px;
			min-width: 40px;
			cursor: pointer;
			min-height: 40px;
			align-items: center;
			justify-content: center;
			border-right: 1px solid #e3e3e3;

			&__arrow {
				transition: all 500ms;

				path {
					stroke: #a39c9a;
				}

				&__rotate {
					transform: rotate(180deg);

					path {
						stroke: #0c5850;
					}
				}
			}
		}

		&__create {
			width: 40px;
			display: flex;
			padding: 10px;
			cursor: pointer;
			min-width: 40px;
			min-height: 40px;
			align-items: center;
			justify-content: center;
			border-left: 1px solid #e3e3e3;
		}

		&__three {
			position: relative;

			&_dot {
				width: 40px;
				display: flex;
				padding: 10px;
				cursor: pointer;
				min-width: 40px;
				min-height: 40px;
				align-items: center;
				justify-content: center;
				border-left: 1px solid #e3e3e3;
			}

			&_modal {
				top: 40px;
				right: -4px;
			}
		}

		&__btn {
			display: flex;
			align-items: center;

			&__text {
				margin: 0;
				font-size: 16px;
				font-weight: 400;
				color: #5a5a5a;
				line-height: 24px;
				user-select: none;
				font-family: "Inter";
			}

			&_left {
				text-align: left;
			}

			&_right {
				display: flex;
				text-align: right;
				justify-content: flex-end;
			}

			&:nth-child(1) {
				width: 100%;
				max-width: 34.8%;
				padding-left: 16px;

				p {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			&:nth-child(2) {
				width: 100%;
				max-width: 13.95%;

				p {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			&:nth-child(3) {
				width: 100%;
				max-width: 13.17%;

				p {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			&:nth-child(4) {
				width: 100%;
				max-width: 21.7%;

				p {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			&:nth-child(5) {
				width: 100%;
				max-width: 16.2%;
				p {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			&:last-child {
				padding-right: 15px;
			}
		}
	}

	&__content {
		padding: 24px;
		border: 1px solid #f0f0f0;
		border-top: none;
		background-color: #fff;

		&__btn {
			gap: 8px;
			display: flex;
			max-width: 117px;
			color: #0c5850;
			padding: 6px 8px;
			margin: 25px auto 0;
			align-items: center;
			justify-content: center;
			border: 1px solid #0c5850;
			background-color: transparent;
		}
	}
}

.badge {
	gap: 8px;
	margin: 0;
	display: flex;
	font-size: 14px;
	padding: 1px 8px;
	font-weight: 500;
	line-height: 22px;
	align-items: center;
	border-radius: 16px;
	font-family: "Inter";

	&__active {
		color: #0c5850;
		background: #e6f4ea;

		&_dot {
			min-width: 4px;
			max-width: 4px;
			min-height: 4px;
			border-radius: 50%;
			background: #0c5850;
		}
	}

	&__de_active {
		color: #a39c9a;
		background: #f4f4f4;

		&_dot {
			min-width: 4px;
			max-width: 4px;
			min-height: 4px;
			border-radius: 50%;
			background: #a39c9a;
		}
	}
}
