.container {
	min-height: 100vh;
	display: flex;
	//padding: 16px;
	position: relative;
	align-items: flex-start;
	background-color: white;
	justify-content: space-between;
	overflow-x: hidden;

	&__login {
		width: 100%;
		display: flex;
		margin: auto;
		padding: 40px 20px;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		align-content: center;
		flex-wrap: wrap;

		@media only screen and (min-width: 992px) {
			max-width: 400px;
			min-width: 400px;
		}

		@media only screen and (min-width: 1200px) {
			max-width: 580px;
			min-width: 580px;
		}

		&__wrapper {
			width: 100%;
			z-index: 999;
			display: flex;
			max-width: 590px;
			position: relative;
			margin-right: auto;
			align-items: center;
			flex-direction: column;
		}

		&_icon {
			min-height: 72px;
		}

		&__title {
			font-size: 28px;
			font-weight: 600;
			color: #383838;
			line-height: 36px;
			text-align: center;
			margin: 40px auto 8px;
			font-family: "Poppins", sans-serif;
			letter-spacing: -0.01em;
		}

		&__description {
			font-size: 16px;
			font-weight: 400;
			color: #5a5a5a;
			line-height: 26px;
			text-align: center;
			margin-bottom: 40px;
			font-family: "Poppins", sans-serif;
		}
	}
}

.ui {
	width: 100%;
	min-width: 800px;
	display: none;
	max-width: 828px;
	position: sticky;
	top: 0;
	flex-direction: column;
	height: 100%;
	background-color: #fff9f4;
	justify-content: space-between;
	margin-left: auto;

	@media only screen and (min-width: 992px) {
		display: flex;
	}

	&_f_container {
		display: flex;
		margin-top: 3.9vh;
		justify-content: space-between;

		&_first_card {
			margin-top: 11vh;
			margin-left: 47px;

			&_chart {
				margin-top: 13px;
			}
		}

		&_rule1 {
			top: 21vh;
			left: 67px;
			position: absolute;
		}

		&_rule2 {
			top: 35vh;
			left: 184px;
			position: absolute;
		}
	}

	&__bottom_block {
		display: flex;
		position: relative;
		justify-content: space-around;
		overflow: hidden;

		&_first {
			margin-top: 6vh;
		}

		&_second {
			margin-top: 10vh;
			margin-bottom: 8vh;
		}

		&_rule3 {
			top: 3vh;
			right: 84px;
			position: absolute;
		}
	}

	&__descriptions {
		z-index: 2;
		position: relative;

		&_subtitle {
			margin-top: 0;
			font-size: 20px;
			font-weight: 600;
			line-height: 28px;
			text-align: center;
			background-clip: text;
			font-family: "Poppins", sans-serif;
			background: linear-gradient(37.59deg, #493c70 19.69%, #008170 103.26%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		&_title {
			margin-top: 8px;
			font-size: 36px;
			font-weight: 600;
			color: #383838;
			margin-bottom: 0;
			line-height: 48px;
			text-align: center;
			font-family: "Poppins", sans-serif;
			letter-spacing: -0.01em;
		}

		&__description {
			width: 100%;
			font-size: 12px;
			font-weight: 300;
			color: #5a5a5a;
			max-width: 559px;
			margin: 8px auto;
			line-height: 19px;
			text-align: center;
			font-family: "Poppins", sans-serif;
		}
	}

	&__right_container {
		width: 100%;
		max-width: 410px;
		position: relative;

		&_third {
			margin-top: 5vh;
			margin-left: 108px;
		}
	}

	&__img {
		width: 100%;
		height: 100%;
		//padding-bottom: 0;
		padding: 24px 22px;
	}

	&__bottom_icon {
		z-index: 1;
		left: -50px;
		bottom: 0;
		position: absolute;
		display: none;

		@media only screen and (min-width: 1300px) {
			display: flex;
		}
	}

	&__card {
		z-index: 2;
		width: 100%;
		padding: 16px;
		max-width: 280px;
		min-height: 156px;
		position: relative;
		border-radius: 4px;
		height: max-content;
		background: #ffffff;
		box-shadow: 16px 20px 23px rgba(11, 71, 71, 0.08);

		&__relative {
			width: 100%;
			margin-top: 14px;
			position: relative;

			&__img {
				z-index: 2;
				width: 42px;
				height: 42px;
				display: block;
				margin: 0 auto;
				position: relative;
			}

			&__chart {
				left: 0;
				right: 0;
				top: 21px;
				z-index: 1;
				margin: 0 auto;
				position: absolute;
			}

			&__person_container {
				z-index: 2;
				display: flex;
				margin-top: 14px;
				position: relative;
				align-items: center;
				justify-content: space-between;

				img {
					width: 32px;
					height: 32px;
				}
			}
		}

		&__chart {
			display: flex;
			align-items: center;

			&__item {
				gap: 13px;
				width: 100%;
				display: flex;
				margin-left: 24px;
				flex-direction: column;

				&_el {
					width: 100%;
					display: flex;
					align-items: center;

					&_badge {
						min-width: 8px;
						min-height: 8px;
						margin-right: 8px;
						border-radius: 50%;
					}

					&_empty {
						width: 100%;
						height: 16px;
						border-radius: 4px;
						background: #c6c6c6;
					}
				}
			}
		}

		&_badge {
			left: 0;
			right: 0;
			top: -15px;
			width: 100%;
			margin: 0 auto;
			display: block;
			color: #ffffff;
			max-width: 185px;
			padding: 2px 16px;
			text-align: center;
			position: absolute;
			border-radius: 24px;
			background: linear-gradient(89.86deg, #493c70 -21.18%, #48668d 102.12%);
		}

		&_title {
			margin-top: 0;
			font-size: 18px;
			color: #000000;
			font-weight: 500;
			margin-bottom: 0;
			line-height: 22px;
			font-family: "Inter", sans-serif;
		}

		&_container {
			gap: 12px;
			display: flex;
			flex-direction: column;

			&__user {
				display: flex;
				align-items: center;

				&_img {
					width: 56px;
					height: 56px;
					border-radius: 50%;
				}

				&_item {
					gap: 8px;
					width: 100%;
					display: flex;
					margin-left: 16px;
					flex-direction: column;

					&_name {
						font-size: 18px;
						color: #000000;
						font-weight: 500;
						line-height: 22px;
						font-family: "Inter", sans-serif;
					}

					&_sub_name {
						font-size: 18px;
						color: #000000;
						font-weight: 500;
						line-height: 22px;
						font-family: "Inter", sans-serif;
					}

					&_empty {
						height: 16px;
						border-radius: 4px;
						background: #c6c6c6;
					}
				}
			}
		}
	}
}
