.wrapper {
	width: 100%;
	display: flex;
	margin: 0 auto;
	align-items: center;
	border: 1px solid #f0f0f0;
	border-top: none;
	box-sizing: border-box;

	&:nth-of-type(2n + 1) {
		background-color: #fafafa;
	}

	&:last-child {
		border-radius: 0 0 4px 4px;
	}
	&__container {
		width: 100%;
		display: flex;
		max-width: 1064px;
	}
	&__btn {
		width: 100%;
		padding-left: 16px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		box-sizing: border-box;

		&_text {
			margin: 0;
			font-size: 16px;
			font-weight: 400;
			text-align: left;
			color: #5a5a5a;
			line-height: 24px;
			min-width: max-content;
			&_cut {
				width: 100%;
				overflow: hidden;
				white-space: nowrap;
				min-width: unset;
				text-overflow: ellipsis;
			}
		}

		&_invite {
			margin: 0;
			font-size: 16px;
			color: #a39c9a;
			font-weight: 400;
			line-height: 24px;
			user-select: none;
		}

		&_left {
			text-align: left;
		}
		&_right {
			padding-left: 0;
			text-align: right;
			padding-right: 6px;
		}

		&_center {
			text-align: center;
		}

		&:nth-child(1) {
			width: 29%;
		}

		&:nth-child(2) {
			width: 29%;
		}

		&:nth-child(3) {
			width: 14%;
		}

		&:nth-child(4) {
			width: 14%;
		}

		&:nth-child(5) {
			width: 14%;
		}
	}
	&__three {
		position: relative;
		margin-left: auto;
		border-left: 1px solid #f0f0f0;

		&.hidden {
			visibility: hidden;
		}

		&_dot {
			width: 40px;
			display: flex;
			padding: 10px;
			cursor: pointer;
			min-width: 40px;
			min-height: 40px;
			align-items: center;
			justify-content: center;
		}

		&_modal {
			top: 40px;
			right: -4px;
		}
	}
}

.plansDropdown {
	padding-left: 16px;
	width: 14%;
}
