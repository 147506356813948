.body {
	gap: 24px;
	width: 100%;
	display: flex;
	margin-top: 24px;
	align-items: flex-start;

	&__container {
		width: 100%;
		display: flex;
		flex-direction: column;

		&__label {
			display: flex;
			padding: 10px 0;
			padding-left: 16px;
			background-color: #fff;
			border-radius: 4px 4px 0 0;
			margin: 0;
		}

		&__controllers {
			gap: 8px;
			display: flex;
			padding: 16px 0;
			background-color: #fff;

			&_btn {
				gap: 8px;
				display: flex;
				font-size: 14px;
				cursor: default;
				font-weight: 500;
				padding: 6px 12px;
				line-height: 20px;
				border-radius: 4px;
			}

			&__expend {
				border: 1px solid;
				margin-left: 16px;
				background-color: #fff;
			}

			&__get_more {
				position: relative;

				&__btn {
					cursor: default;
					color: #edf2f2;
					background: #0c5850;

					&_z_index {
						z-index: 33;
						position: relative;
					}
				}

				&__boarding {
					top: -104px;
					left: -30px;

					&_starter {
						top: -109px;
					}
				}
			}
		}

		&__wrapper {
			gap: 16px;
			display: flex;
			flex-direction: column;
		}
	}
}

.fake_filter {
	width: 100%;
	max-width: 384px;
	position: relative;

	&__boarding {
		top: -30px;
		left: -30px;
	}

	&__head {
		gap: 8px;
		height: 57px;
		display: flex;
		padding: 14px 17px;
		align-items: center;
		background-color: white;
		border-radius: 4px 4px 0 0;
		border: 1px solid #eeedec;

		svg {
			width: 24px;
			height: 24px;

			path {
				stroke: #0c5850;
			}
		}

		&_text {
			margin: 0;
			font-size: 18px;
			color: #0c5850;
			font-weight: 600;
			line-height: 26px;
			font-family: Inter;
		}
	}

	&__filters {
		display: flex;
		flex-direction: column;
		background-color: white;

		&_item {
			height: 54px;
			padding: 16px;
			display: flex;
			font-size: 14px;
			color: #2c3030;
			font-weight: 600;
			line-height: 20px;
			font-family: Inter;
			align-items: center;
			border: 1px solid #eeedec;
			border-width: 0 1px 1px 1px;
			justify-content: space-between;

			svg {
				width: 20px;
				height: 20px;
			}
		}
	}

	:last-child {
		border-radius: 0 0 4px 4px;
	}
}

.fake_card {
	gap: 16px;
	padding: 16px;
	display: flex;
	width: 100%;
	border-radius: 4px;
	max-width: 100%;
	flex-direction: column;
	background-color: #fff;

	&__head {
		gap: 16px;
		display: flex;
		align-items: center;

		&__img {
			height: 56px;
			min-width: 56px;
			max-width: 56px;
			border-radius: 50%;
		}

		&__description {
			gap: 8px;
			display: flex;
			flex-direction: column;

			&__name {
				cursor: pointer;
				color: #181b1a;
				font-size: 20px;
				margin: 0;
				font-weight: 500;
				line-height: 28px;
			}

			&__country {
				color: #4e5555;
				font-size: 14px;
				font-weight: 300;
				line-height: 20px;
			}
		}
	}

	&__company {
		display: flex;
		align-items: flex-start;

		&__details {
			width: 31%;

			&_img {
				height: 30px;
				max-width: 30px;
				min-width: 30px;
				border-radius: 50%;
			}

			&_name {
				color: #4e5555;
				font-size: 14px;
				font-weight: 300;
				line-height: 20px;
			}
		}

		&__info {
			display: flex;
			align-items: flex-start;

			&__details {
				gap: 10px;
				margin: 0;
				display: flex;
				color: #404040;
				font-size: 12px;
				align-items: center;

				&_name {
					font-weight: 500;
					font-size: 13px;
				}
			}
		}
	}

	&__buttons {
		gap: 16px;
		display: flex;
		justify-content: space-between;

		&__btn {
			gap: 8px;
			display: flex;
			cursor: default;
			border: 1px solid;
			padding: 6px 12px;
			border-radius: 4px;
		}

		&__right {
			gap: 8px;
			display: flex;

			&__more {
				position: relative;

				&_btn {
					cursor: default;
					stroke: #181b1a;
					background-color: #fff;

					svg {
						height: 16px;
					}

					&_z_index {
						z-index: 33;
						cursor: default;
						position: relative;
					}
				}

				&__boarding {
					top: -109px;
					left: -30px;
				}
			}

			&__reject {
				border-color: rgb(201, 21, 21) !important;
			}

			&__save {
				position: relative;

				&_btn {
					border-color: rgb(12, 88, 80) !important;
					background-color: #fff;

					&_z_index {
						z-index: 33;
						position: relative;
					}
				}

				&__boarding {
					top: -282px;
					left: -160px;
				}
			}
		}
	}
}
