.container {
	right: 20px;
	bottom: 70px;
	display: flex;
	position: fixed;
	z-index: 2;
	justify-content: center;
	//width: calc((100vw - 1198px) / 5);

	@media (max-width: 1340px) {
		width: 74px;
	}

	&__button {
		z-index: 4;
		width: 40px;
		height: 40px;
		border: none;
		display: flex;
		cursor: pointer;
		border-radius: 50%;
		transform: scale(0);
		align-items: center;
		justify-content: center;
		transition-duration: 3s;
		transition: transform 0.3s;
		background-color: #3c6662;

		&:hover {
			transform: scale(1.2);
			transition: transform 0.2s;
			background-color: #0c5850;
		}

		&__animation {
			transform: scale(1);
		}
	}
}
