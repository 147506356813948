@import "styles/common.module.scss";

.container {
	width: 100%;
	height: 40px;
	border: 1px solid #cbc5c2;
	border-right: none;
	position: relative;

	@media only screen and (min-width: 1025px) {
		border: 1px solid #cbc5c2 !important;
	}

	&_once {
		max-width: 273px;
		border-radius: 4px;
		border: 1px solid #cbc5c2;

		&_open {
			border-radius: 4px 4px 0 0;
		}

		label span {
			max-width: 160px;

			@media #{$viewportLargeS} {
				max-width: 250px;
			}
		}
	}

	&_large {
		max-width: 273px;

		@media #{$viewportTablet} {
			border: none;
			max-width: 100% !important;
		}

		label span {
			max-width: 160px;

			@media #{$viewportLargeS} {
				max-width: 250px;
			}
		}
	}

	&_small {
		max-width: 183px;

		@media #{$viewportTablet} {
			max-width: 100% !important;
		}

		label span {
			max-width: 95px;

			@media #{$viewportLargeS} {
				max-width: 160px;
			}
		}
	}

	&_small_left_radius {
		max-width: 184px;
		border-radius: 4px 0px 0px 4px;

		@media #{$viewportTablet} {
			max-width: 100% !important;
			border-radius: 0;
		}

		.container__body__wrapper {
			border-radius: 4px 0px 4px 4px;

			@media #{$viewportTablet} {
				border-radius: 0;
			}
		}

		.selected {
			border-radius: 4px 0px 0px 4px;
		}

		label span {
			max-width: 60px;
			@media #{$viewportLargeS} {
				max-width: 80px;
			}
		}
	}

	&__body {
		left: -1px;
		z-index: 1;
		max-height: 234px;
		position: absolute;
		background: transparent;

		&__wrapper {
			overflow-y: auto;
			background-color: #fff;
			border: 1px solid #cbc5c2;
			border-radius: 0 0 4px 4px;
			padding: 10px 8px;

			&_left {
				border-radius: 0 0 4px 0;
			}

			&_second {
				border-radius: 0 0 0 4px;

				&_left {
					border-radius: 0 0 4px 4px;
				}
			}

			@media only screen and (max-width: 1024px) {
				width: 100% !important;
				min-width: 100% !important;
				max-width: 100% !important;
			}
		}

		&__multi {
			top: 0;
			right: 0;
			max-width: 100%;
			min-width: 190px;
			overflow-y: auto;
			min-height: 240px;
			max-height: 240px;
			position: absolute;
			background-color: #fff;
			border: 1px solid #cbc5c2;
			padding: 11px 8px;
			border-radius: 0px 4px 4px 0px;
			border-left: none;

			label span {
				max-width: 100px;
			}

			&_left {
				right: unset;
				border-radius: 0;
				border-right-width: 0;
				border: solid #cbc5c2;
				border-width: 1px 0.5px 1px 1px;
			}
		}

		&__region {
			top: 0;
			right: unset;
			padding: 11px 8px;
			min-height: 240px;
			position: absolute;
			border: solid #cbc5c2;
			border-radius: 0 0 0 4px;
			background-color: #fff;
			border-width: 1px 0 1px 1px;
		}

		@media only screen and (max-width: 1024px) {
			width: 100% !important;
			min-width: 100% !important;
			max-width: 100% !important;
		}
	}

	@media only screen and (max-width: 1024px) {
		width: 100% !important;
		max-width: 100% !important;
	}

	@media only screen and (min-width: 768px) and (max-width: 1024px) {
		border: 1px solid #cbc5c2 !important;
		border-radius: 0 !important;

		&:nth-child(odd) {
			border-right: none !important;
		}

		&:nth-child(-n + 2) {
			border-bottom: none !important;
		}
	}

	@media only screen and (max-width: 767px) {
		border: 1px solid #cbc5c2 !important;
		border-top: none !important;

		&:first-child {
			border-top: 1px solid #cbc5c2 !important;
		}
	}
}

.selected {
	display: flex;
	cursor: pointer;
	padding: 8px 5px;
	align-items: center;
	background-color: #fff;
	justify-content: space-between;

	&__once {
		border-radius: 4px;

		&_open {
			border-radius: 4px 4px 0 0;
		}
	}

	@media #{$viewportLargeS} {
		padding: 8px 16px;
	}

	&__title {
		margin: 0;
		font-size: 12px;
		color: #404040;
		font-weight: 500;
		line-height: 22px;
		font-family: "Inter", sans-serif;
		min-width: max-content;

		@media #{$viewportLargeS} {
			font-size: 14px;
		}
	}

	&__item {
		gap: 4px;
		margin: 0;
		display: flex;
		padding: 0 8px;
		cursor: default;
		border-radius: 3px;
		background: #f9f9f9;
		min-width: max-content;

		&_text {
			font-size: 14px;
			color: #404040;
			font-weight: 400;
			line-height: 22px;
			font-family: "Inter", sans-serif;
		}

		svg {
			cursor: pointer;
			max-width: 16px;
			max-height: 16px;
		}
	}

	&__container {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;

		svg {
			max-width: 20px;
			max-height: 20px;
		}

		&_search {
			padding: 0;
			width: 100%;
			border: none;
			outline: none;
			margin-left: 8px;
		}

		.selected__inputContainer {
			position: relative;

			.loader {
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translateY(-50%);
			}

			input {
				border: none;
				margin-left: 8px;
				padding: 2px 1px 2px 0;
			}
			input:focus {
				outline: none;
				border: 1px dotted grey;
			}
		}
	}

	&__transform {
		transform: rotate(0deg);
		transition: transform 200ms ease-in;
		width: 16px;
		height: 16px;

		&_rotate {
			transform: rotate(180deg);
			transition: transform 200ms ease-in;
		}

		path {
			fill: #a39c9a;
		}
	}

	&_wrapper {
		gap: 8px;
		display: flex;
		align-items: center;

		&_loader {
			height: 16px;
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}

	&__reset {
		display: flex;
		min-width: max-content;
		font-size: 12px;
		font-weight: 500;
		line-height: 22px;
		padding: 0 5px;
		border-radius: 4px;
		background-color: #f9f9f9;

		&:hover {
			background-color: #d9d9d9;
		}
	}
}

.wrapper {
	gap: 4px;
	width: 100%;
	display: flex;
	max-width: 90%;

	&__reset_show {
		max-width: 63%;
	}

	&_container {
		gap: 4px;
		width: 100%;
		display: flex;
		max-width: 80%;
		cursor: default;
		overflow-x: auto;
		align-items: center;

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
		}

		&_full {
			max-width: 100%;
		}
	}

	&__others {
		margin: 0;
		padding: 0 8px;
		font-size: 14px;
		font-weight: 600;
		color: #404040;
		line-height: 22px;
		border-radius: 3px;
		font-family: "Inter", sans-serif;
		background: #f9f9f9;
		min-width: max-content;

		&:hover {
			background-color: #d9d9d9;
		}
	}
}

.item {
	width: 100%;
	display: flex;
	max-height: 32px;
	padding: 6px 8px;
	align-items: center;
	justify-content: space-between;

	&:hover {
		cursor: pointer;
		border-radius: 5px;
		background: #d9d9d9;
	}

	&_reverse {
		flex-direction: row-reverse;

		svg {
			margin-right: 8px;
			transform: rotate(90deg);
		}
	}

	&__left {
		width: 100%;
		display: flex;
		position: relative;
		align-items: center;

		&__checkbox {
			margin: 0;
			width: 100%;

			div {
				margin-right: 0;
			}

			input:checked ~ &__border {
				background: #808080;

				&::after {
					top: 1px;
					left: 5px;
				}
			}

			&__border {
				width: 16px;
				height: 16px;
				top: calc(50% - 8px);
				background: transparent;
				border: 1px solid #808080;
			}
		}

		&__name {
			font-size: 14px;
			color: #404040;
			font-weight: 400;
			line-height: 22px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-family: "Inter", sans-serif;
			margin-left: 24px;

			&[data-tooltip]:hover::after {
				position: absolute;
				content: attr(data-tooltip);
				//opacity : 0;

				background: #333;
				background: rgba(0, 0, 0, 0.8);
				border-radius: 5px;
				color: #fff;
				top: -40px;
				left: 10px;
				padding: 5px 15px;
				z-index: 98;
				width: auto;
			}

			&[data-tooltip]:hover::before {
				bottom: 20px;
				content: "";
				left: 50%;
				position: absolute;
				z-index: 99;
			}
		}

		&_partial {
			left: 3px;
			z-index: 1;
			width: 10px;
			height: 2px;
			position: absolute;
			border: 1px solid #808080;
		}
	}

	&__right {
		display: flex;
		cursor: default;
		align-items: center;

		&__icon {
			margin-right: 8px;
		}

		&__count {
			font-size: 14px;
			font-weight: 500;
			color: #808080;
			margin-right: 4px;
			line-height: 22px;
			min-width: max-content;
			max-width: max-content;
			font-family: "Inter", sans-serif;

			&__arrow {
				transform: rotate(-90deg);
			}
		}
	}
}
