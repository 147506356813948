.container {
	display: flex;
	padding: 48px;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	align-self: stretch;
	max-height: calc(100vh - 150px);
	overflow: auto;

	*::-webkit-scrollbar {
		width: 4px;
	}
}

.content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
}

.inputContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;

	label {
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-size: 14px;
		font-weight: 600;
		line-height: 20px; /* 142.857% */
	}
}

.dropdownItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	img {
		height: 24px;
		border-radius: 2px;
		border: 1px solid var(--System-Silver-Chalice-50, #f8f7f7);
		background: transparent lightgray 50% / cover no-repeat;
	}

	span {
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 14px;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
	}
}

.note {
	color: var(--Brand-Neutral-Grey-800, #868d8d);
	font-size: 13px;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
}

.loadingMessage {
	color: var(--Brand-Neutral-Grey-800, #868d8d);
	font-size: 14px;
	font-weight: 400;
	line-height: 20px; /* 142.857% */

	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	justify-content: center;
}
