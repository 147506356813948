@import "styles/common.module.scss";

.workList {
	position: relative;
	min-height: 80px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.experience {
	display: flex;
	flex-direction: row;
	gap: 16px;

	@media (max-width: 767px) {
		flex-direction: column;
		gap: 10px;
	}

	.printRequested {
		margin-top: 1rem;
		page-break-before: auto;
	}
}

.expandButton {
	color: #404040;
	width: 147px;
	padding: 7px 0;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border: 1px solid #0c5850;
	border-radius: 4px;
	cursor: pointer;
	margin-bottom: 20px;

	@media (max-width: 1024px) {
		margin-bottom: 10px;
	}

	span {
		margin-left: 5px;
	}
}

.expandContainer {
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;
}

.marginBottom {
	margin-bottom: 50px !important;
}

.hidden {
	display: none;
}

.description {
	font-size: 12px;
	padding: 5px 14px 0 12px;
	width: 530px;
	margin-left: 104px;
	color: #4e5555;

	@media (max-width: 1200px) {
		width: 400px;
	}

	@media (max-width: 767px) {
		padding: 10px 0 5px 0;
		width: 284px;
	}
}

.companyNameContainer {
	display: flex;
	justify-content: space-between;
	justify-items: flex-start;
	min-width: 220px;
	max-width: 220px;
}

.companyName {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	color: rgba(0, 0, 0, 0.75);
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-shrink: 0;
	position: relative;
	cursor: pointer;
	z-index: 1;

	@media (max-width: 767px) {
		width: 100%;
	}

	&__skeleton {
		display: none;
	}

	.companyLogo {
		width: 24px;
		height: 24px;
		margin-right: 5px;
		border-radius: 2px;
		border: 1px solid #eeedec;
		object-fit: cover;
	}
}

.companyName {
	z-index: 0;

	&:hover {
		.companyName__skeleton {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: transparent;
		}
	}
}

.workDate {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	text-align: center;
	color: rgba(0, 0, 0, 0.5);
	width: 15%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.workDateFirst {
	color: #c4c4c4;
	border-left: 1px solid #c4c4c4;
	margin-top: 7px;

	&.noBorder {
		border-left: none;
	}
}

.workDateIcon {
	margin-top: -25px;
	margin-left: -7px;
}

.workDateLast {
	color: #c4c4c4;
	border-left: 1px solid #fff;
	margin-top: 7px;
}

.workTitle {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.5);
	width: 52%;
	margin-left: 14px;
}

.showMoreBlock {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: absolute;
	bottom: -38px;
}

.details {
	display: flex;
	flex-direction: column;

	@media (max-width: 767px) {
		gap: 10px;
	}
}

.detail {
	line-height: 16px;
	margin-top: 6px;
	margin-left: 14px;
	display: flex;

	@media (max-width: 767px) {
		margin: 0;
	}
}

.years {
	color: #404040;
	font-size: 12px;
	min-width: 90px;
	max-width: 90px;
	display: inline-flex;
	white-space: nowrap;
}

.jobTitle {
	font-weight: 500;
	font-size: 12px;
	margin-left: 10px;
	width: 100%;
}

.highlighter {
	background-color: rgba(250, 242, 224, 1);
	padding: 5px 3px;
}

.tooltip {
	background-color: #ffffff;
	box-shadow: 31px 10px 31px rgba(0, 0, 0, 1);
	border-radius: 4px;
	opacity: 1;
	width: 280px;
	padding: 16px;
	z-index: 1;

	@media (max-width: 753px) {
		left: 40% !important;
	}
}
