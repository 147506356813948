.feedbackWrapper {
	position: relative;

	@media print {
		display: none !important;
	}

	.flagWrapper {
		cursor: pointer;
	}

	.feedbackModalWrapper {
		position: absolute;
		left: -15px;
		top: 20px;

		@media (max-width: 1024px) {
			left: -118px;
		}

		.triangleWrapper {
			position: relative;
			top: 8px;
			left: 26px;

			@media (max-width: 1024px) {
				right: 0;
				left: 0;
				width: 22px;
				margin: 0 auto;
			}
		}

		.quivkViewTriangle {
			left: 113px;
		}

		.feedbackModal {
			background: #ffffff;
			border: 1px solid #e6e6e6;
			box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.16);
			border-radius: 4px;
			padding: 16px;
			width: 280px;
			position: relative;
			z-index: 99999;

			p {
				margin: 0 0 12px 0;
				color: #404040;
				font-weight: 600;
				font-size: 16px;
			}
		}
	}

	.feedbackSent {
		color: #404040;
		span {
			font-size: 14px;
			font-weight: 400;
		}
	}

	.quickViewModal {
		left: -100px;
	}

	.sentModal {
		width: 280px !important;
	}

	.successMessage {
		display: flex;

		> div {
			width: 34px;
		}

		span {
			font-weight: 400;
			font-size: 14px;
			line-height: 22px;
			padding-left: 20px;
		}
	}
}

.opposite {
	left: -248px !important;

	.triangleWrapper {
		left: 258px !important;
	}
}
