.dropdownInputWrapper {
	margin: 10px 0;
	position: relative;

	.date {
		width: 168px;
		padding: 3px 8px;
	}

	label {
		font-size: 14px;
		font-weight: 500;
	}

	section {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid #dad8d7;
		border-radius: 4px;
		padding: 0 8px;
		height: 38px;
		color: #b0b5b4;
		cursor: pointer;
		margin: 10px 0;

		.truncatedContainer {
			display: flex;
			align-items: center;
			width: 285px;
			overflow: hidden;

			.truncatedText {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-weight: 500;
				justify-content: flex-start;
				font-size: 14px;
				color: #2c3030;
				max-width: 260px;
				display: inline-block;
				vertical-align: middle;
				flex-grow: 1;
				min-width: 0;
				margin-left: 10px;
			}

			.numberIndicator {
				color: #2c3030;
				font-size: 14px;
				font-weight: 600;
				white-space: nowrap;
				margin-left: 10px;
			}

			.titleText {
				font-size: 14px;
				font-weight: 500;
				color: #b0b5b4;
				position: absolute;
				left: 10px;
			}
		}
	}
}
