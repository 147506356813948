.chart_container {
	gap: 24px;
	display: flex;
	flex-direction: row;
	width: 100%;
	flex: 1;
	//align-items: center;
	margin-bottom: 23px;

	@media (max-width: 1210px) {
		display: flex;
		align-items: unset;
		flex-direction: column;
	}

	&__item {
		padding: 24px;
		min-height: 294px;
		border: 1px solid #e1e1e1;
		border-radius: 4px;
		background-color: #fff;

		@media (max-width: 1210px) {
			width: 100%;
			max-width: 100%;
		}
	}
}

.hidden {
	display: none;
}
