.toast {
	height: 100%;
	display: flex;
	align-items: center;
	padding: 16px 8px;

	svg {
		flex-shrink: 0;
	}

	:global(.toast-link) {
		cursor: pointer;
		font-weight: 500;
	}
}

.textContainer {
	margin: 0 8px;
	font-family: "Inter", sans-serif;
}

.title {
	color: #181b1a;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
}

.description {
	font-size: 14px;
	font-weight: 400;
	color: #535a5a;
	line-height: 20px;
}

.close {
	margin-left: auto;
	cursor: pointer;
}
