.infoTooltip {
	z-index: 99999;
	position: absolute;
	opacity: 1;
	padding: 16px;
	color: black;
	width: 328px;
	background-color: white;
	box-shadow: 0 4px 32px rgba(0, 0, 0, 0.08);
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	&_title {
		font-size: 14px;
		color: #271b00;
		font-weight: 500;
		margin: 0 0 8px 0;
		line-height: 22px;
		font-family: "Inter", sans-serif;
	}

	&__description {
		display: block;
		font-size: 14px;
		font-weight: 400;
		color: #404040;
		line-height: 22px;
		margin-bottom: 16px;
		font-family: "Inter", sans-serif;
		text-align: left;

		&_green {
			font-weight: 600;
			color: #0c5850;
			text-decoration: underline;
		}

		&_dark {
			font-weight: 600;
		}
	}
}
