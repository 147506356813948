@import "styles/common.module.scss";

* {
}

.educationCard {
	position: relative;
	background: #ffffff;
	border: 1px solid rgba(163, 156, 154, 0.5);
	padding: 24px;
	margin-bottom: 20px;
}

.title {
	font-size: 18px;
	color: #271b00;
	font-weight: 600;
	line-height: 26px;
	margin-bottom: 24px;
}

.educationList {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-top: 1rem;
	font-size: 14px;
}

.education {
	margin-bottom: 15px;

	&:last-child {
		margin-bottom: 0;
	}
}

.educationDate {
	font-size: 14px;
	color: #404040;
}

.instituteTitle {
	font-weight: 600;
	color: #271b00;
	margin-bottom: 2px;

	.institutionLink {
		&:hover {
			color: $mainThemeColor;
		}
	}
}

.info {
	font-weight: 400;
	color: #404040;
	margin-bottom: 2px;
}
