@import "styles/common.module.scss";

.container {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: flex;
	position: fixed;
	align-items: center;
	justify-content: center;
	background-color: rgba(20, 20, 20, 0.7);
}

.wrapper {
	width: 100%;
	max-width: 400px;
	border-radius: 4px;
	background-color: #fff;

	&__title {
		margin: 0;
		padding: 16px;
		font-size: 18px;
		font-weight: 500;
		border-bottom: 1px solid #d1cdcc;
	}

	&__content {
		padding: 16px;

		&_item {
			gap: 6px;
			display: flex;
			margin-bottom: 16px;
			flex-direction: column;

			&_title {
				margin: 0;
				font-size: 14px;
				color: #a1a1a1;
			}

			&__transfer_name {
				padding: 8px 16px;
				background: #dadada;
				border-radius: 4px;
				margin: 0;
				font-weight: 400;
				font-size: 14px;
				line-height: 24px;
				color: #383838;
			}
		}

		&_button {
			width: 100%;
			padding: 13px 0;
			font-size: 14px;
			margin-top: 16px;
			font-weight: 600;
			margin-top: 16px;
			user-select: none;
			position: relative;
			background: #3c6662;
			border-radius: 3px;
			text-align: center;
			font-size: 13px;
			font-weight: 500;
			color: #fff;
			cursor: pointer;
			min-width: 116px;

			&:hover {
				background: $generalButtonActive;
			}
		}
	}
}
