.wrapper {
	width: 383px;
	max-height: 176px;
	padding: 16px;
	margin-left: 18px;
	color: #232323;
	background-color: #fff;
	box-shadow: 0 4px 32px rgba(0, 0, 0, 0.18) !important;
	border-radius: 4px;
	z-index: 10;
	line-height: 0.9;
	display: flex;

	h4 {
		margin-bottom: 8px;
		margin-top: 0;
	}

	p {
		color: var(--Brand-Neutral-Grey-600-B, #4e5555);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		margin-bottom: 8px;
		margin-top: 0;

		strong {
			color: var(--Brand-Neutral-Grey-600-B, #4e5555);
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px; /* 142.857% */
		}

		span {
			display: inline-block;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}
