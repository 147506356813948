@import "styles/common.module.scss";

.container {
	margin-top: 30px;
	position: relative;
}

.wrapper {
	box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
	border-radius: 4px;
	background-color: #fff;
	//border: 0.5px solid #d1cdcc;
	position: sticky;
	top: 150px;

	/* TODO: this is not a good attr here. Width should be dynamic
     but for that main container should be fixed instead */
	width: 100%;

	max-height: calc(100vh - 200px);
	overflow: hidden auto;

	@media #{$viewportMinLarge} {
		width: 384px;
	}
}
