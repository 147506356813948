.tagsWrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;
	margin-top: 16px;
	max-height: 200px;
	overflow-y: auto;

	.tagTitle {
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: rgba(39, 27, 0, 1);
	}

	span {
		max-width: fit-content;
	}
}
