.skeletonContainer {
	position: relative;
}

.tooltip {
	background: #fff;
	border: 1px solid #f3f3f3;
	color: #333;
	left: 0 !important;
	position: absolute;
	top: -50px !important;
	z-index: 2;
}

.arrow {
	display: none;
}

.card {
	border-radius: 4px;
	box-shadow: 0px 9px 31px rgba(0, 0, 0, 0.04);
	box-sizing: border-box;
	padding: 24px;
	background: #ffffff;
	position: relative;

	&:not(:last-child) {
		margin-bottom: 16px;
	}
}
