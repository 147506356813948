.wrapper {
	cursor: default;

	&__bg {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 30;
		width: 100%;
		height: 100%;
		opacity: 0.4;
		cursor: default;
		position: fixed;
		background: #2c3030;
	}

	&__content {
		z-index: 31;
		padding: 32px;
		cursor: default;
		position: absolute;
		border-radius: 8px !important;
		background: rgba(44, 48, 48, 0.95);

		&__horizontal {
			gap: 32px;
			width: 760px;
			display: flex;
			align-items: flex-start;

			&__left {
				width: 100%;
				max-width: 384px;

				&__head {
					gap: 8px;
					display: flex;
					padding: 14px 17px;
					align-items: center;
					background-color: white;
					border-radius: 4px 4px 0 0;
					height: 57px;
					border: 1px solid rgb(209, 205, 204);

					svg {
						width: 24px;
						height: 24px;

						path {
							stroke: #0c5850;
						}
					}

					&_text {
						margin: 0;
						font-size: 18px;
						color: #0c5850;
						font-weight: 600;
						line-height: 26px;
						font-family: Inter;
					}
				}

				&__filters {
					display: flex;
					flex-direction: column;
					background-color: white;

					&_item {
						padding: 16px;
						display: flex;
						align-items: center;
						border: solid #eeedec;
						border-width: 0 1px 1px 1px;
						justify-content: space-between;
						color: #2c3030;
						font-family: Inter;
						font-size: 14px;
						font-weight: 600;
						height: 54px;
						line-height: 20px;

						svg {
							transform: rotate(180deg);

							width: 20px;
							height: 20px;
						}
					}
				}

				:last-child {
					border-radius: 0 0 4px 4px;
				}
			}
		}

		&__container {
			display: flex;
			position: relative;
			flex-direction: column;

			&__search {
				margin-top: 52px;

				&_banner {
					padding: 16px;
					background: #fff;
					border-radius: 4px;
					margin-bottom: 24px;
					border: 1px solid #dad8d7;
					box-shadow: 0px 25px 60px -10px rgba(79, 75, 74, 0.12);

					&_title {
						margin: 0 0 8px 0;
						color: #4e5555;
						font-size: 14px;
						font-weight: 600;
						line-height: 20px;
					}

					&_empty {
						margin: 0;
						gap: 8px;
						display: flex;
						align-items: center;
						color: #4e5555;
						font-size: 14px;
						font-weight: 400;
						line-height: 20px;

						&_dot {
							height: 3px;
							min-width: 3px;
							margin-left: 8px;
							max-width: 3px;
							min-height: 3px;
							border-radius: 50%;
							background-color: #4e5555;
						}
					}
				}

				&_sub {
					font-size: 24px;
					color: #f7f8f8;
					font-weight: 600;
					margin: 0 0 4px 0;
					line-height: 32px;
				}

				&_info {
					margin: 0;
					font-size: 16px;
					color: #f7f8f8;
					font-weight: 400;
					line-height: 24px;
					margin-bottom: 8px;

					&_strong {
						font-weight: 700;
					}
				}
			}

			&_text {
				margin: 0;
				font-size: 16px;
				color: #f7f8f8;
				font-weight: 400;
				text-align: left;
				line-height: 24px;
				font-family: "Inter", sans-serif;
			}

			&__one {
				margin-bottom: 32px;
			}

			&__second {
				margin-bottom: 70px;
			}

			&__third {
				margin-bottom: 8px;
			}

			&__fourth {
				margin-bottom: 36px;

				&__container {
					position: relative;

					&_stars {
						left: 2px;
						top: -19px;
						z-index: 1;
						position: absolute;
					}
				}
			}

			&__fifth {
				position: relative;

				&_icon {
					top: -80px;
					right: -7px;
					position: absolute;
				}

				&_margin {
					margin-bottom: 8px;
				}

				&_shared {
					cursor: default;
					padding: 4px 8px;
					margin-bottom: 8px;
					background-color: #e0a764;
				}
			}

			&__last {
				margin-bottom: 52px;
			}

			&__plus_logo {
				margin-bottom: 17px;
			}

			&__five {
				margin-bottom: 35px;
			}

			&__navigate {
				right: 0;
				bottom: 50px;
				gap: 8px;
				display: flex;
				position: absolute;
			}

			&_close {
				top: -23px;
				z-index: 32;
				right: -23px;
				position: absolute;
			}
		}

		&__width {
			width: 304px;
		}

		&__width_2 {
			width: 400px;
		}

		&__width_4 {
			width: 760px;
		}

		&__width_5 {
			width: 336px;
		}
	}
}

.close {
	gap: 8px;
	display: flex;
	margin-bottom: 8px;
	align-items: center;
	justify-content: space-between;

	&__text {
		margin: 0;
		width: 100%;
		font-size: 24px;
		color: #f7f8f8;
		font-weight: 600;
		line-height: 32px;
		font-family: Inter;
	}
}

.footer {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__step {
		font-size: 12px;
		color: #f7f8f8;
		font-weight: 600;
		line-height: 16px;
		font-family: Inter;
	}

	&__navigation {
		gap: 8px;
		display: flex;
		align-items: center;

		svg {
			width: 32px;
			height: 32px;
			padding: 6px;
			cursor: pointer;
			border: 1px solid #eeedec;
			border-radius: 4px !important;
		}

		&_left {
			transform: rotate(180deg);
		}

		&_done {
			cursor: pointer;
			font-size: 14px;
			color: #f7f8f8;
			font-weight: 400;
			padding: 4px 14px;
			line-height: 22px;
			border-radius: 4px;
			font-family: Inter;
			border: 1px solid #eeedec;
		}
	}
}

.search__button {
	height: 40px;
	display: flex;
	max-width: 178px;
	padding: 8px 16px;
	align-items: center;
	gap: 8px;
	border-radius: 4px;
	border: none;
	background: #0c5850;
	color: #edf2f2;
	font-size: 14px;
	cursor: default;
	font-weight: 600;
	line-height: 20px;
	margin-bottom: 8px;
}
