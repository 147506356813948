@import "styles/custom/_mixins.scss";

.list {
	height: 312px;
	display: flex;
	margin-top: 20px;
	user-select: none;

	@include scroll-bar();
}

.left {
	height: 312px;
	overflow: auto;
	width: 50%;
	padding-right: 14px;
}

.empty {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 300;
	font-size: 13px;
	height: 80%;
	line-height: 22px;
	color: #a1a1a1;
	user-select: none;
}

.right {
	height: 312px;
	width: 50%;
	overflow: auto;
	margin-left: 24px;
	position: relative;

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
		position: sticky;
		top: 0;
		background: #fff;

		.title {
			font-size: 14px;
			font-weight: 600;
			line-height: 22px;
			color: #a39c9a;

			@include ellipsis();
		}

		.count {
			margin-left: 8px;
			padding: 4px;
			height: 20px;
			min-width: 20px;
			background: #f4f0ed;
			border-radius: 4px;
			font-weight: 600;
			font-size: 12px;
			line-height: 20px;
			color: #312200;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.separator {
	height: 100%;
	width: 1px;
	background: #d9d9d9;
	margin-left: 6px;
}

.map {
	cursor: pointer;
	border-radius: 4px;
	color: #404040;
	font-size: 14px;
	line-height: 22px;
	padding: 5px 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	> .name {
		width: 100%;

		@include ellipsis();
	}

	&:hover {
		background: #f0f0f0;
	}

	&.active {
		background: #d9d9d9;
		font-weight: 600;
	}
}

.infiniteLoaderContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loaderContainer {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
}

.company {
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	.logo {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		user-select: none;
		pointer-events: none;
		margin-right: 8px;
	}

	.name {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: #404040;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.emptyResult {
	color: #404040;
	font-size: 16px;
	line-height: 24px;
	user-select: none;

	strong {
		font-weight: 500;
	}
}
