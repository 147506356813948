@import "styles/variables.module.scss";
@import "styles/custom/_mixins.scss";

.inputContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	// margin: 16px 0;
	width: 100%;
	z-index: 0;
}

.inputWrapper {
	display: flex;
	align-items: center;
	width: 100%;
	position: relative;

	.loader {
		position: absolute;
		top: 8px;
		right: 5px;
	}

	&.error {
		border-color: var(--System-Danger, red);
	}
}

.searchInput {
	width: 100%;
	flex: 1;
	font-size: 14px;
	line-height: 24px;
	min-height: 50px;
	max-height: 200px;
	outline: none;
	padding: 11px 8px 8px 36px;
	box-sizing: border-box;
	resize: none;
	border-radius: 4px 0 0 4px;
	border: 1px solid var(--system-silver-chalice-200, #dad8d7);
	background: var(--system-bw-white, #fff);

	&.errorInput {
		border: 1px solid var(--System-Danger, red);
	}
}

textarea.searchInput::-webkit-input-placeholder, /* Webkit based */
textarea.searchInput::placeholder {
	/* Modern browsers */
	color: #868d8d !important;
	opacity: 0.8;
}
