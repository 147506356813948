@import "styles/variables.module.scss";
@import "styles/custom/_mixins.scss";

.inputContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	// margin: 16px 0;
	width: 100%;
	z-index: 0;
}

.inputWrapper {
	display: flex;
	align-items: center;
	width: 100%;
	position: relative;

	.loader {
		position: absolute;
		top: 8px;
		right: 5px;
	}
}

.invalid {
	& .iconError {
		display: block;
		color: #ff0b37;
	}

	& .input {
		background: rgba(255, 11, 55, 0.05);
		border: 1px solid #ff0b37;
	}
}

.iconError {
	display: none;
	position: absolute;
	right: 10px;
	line-height: 0.8;
}

.searchIcon {
	position: absolute;
	left: 10px;
	line-height: 0.8;
}

.searchInput {
	width: 100%;
	flex: 1;
	border: 1px solid #dadada;
	background: transparent;
	font-size: 14px;
	line-height: 24px;
	height: 40px;
	border-radius: 4px;
	outline: none;
	padding: 8px 40px !important;
	box-sizing: border-box;

	&:not(.disableFocus):focus {
		border-color: #0c5850;
	}
}

.disableFocus:focus {
	outline: none;
}

.searchInput::placeholder {
	color: #a39c9a;
}

.label {
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: $lightGray;
	margin-bottom: 8px;
}

.errorText {
	font-size: 12px;
	line-height: 16px;
	color: #ff0b37;
	position: absolute;
	bottom: 10px;
}
