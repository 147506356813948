@import "styles/variables.module.scss";

.fitToBriefForm {
	display: flex;
	padding: 48px;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	align-self: stretch;
}

.inputsRow {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;
	width: 100%;

	> * {
		flex: 0 0 48.5%;
	}
}

.updateInputsRow {
	display: flex;
	justify-content: space-between;
	width: 100%;

	.textarea {
		width: 82%;

		.assessment {
			border-radius: 4px;
			border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
			box-shadow: 0 17px 33px -2px rgba(79, 75, 74, 0.05);
			overflow: auto;
			height: 144px;

			&:focus {
				outline: none !important;
				border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
			}
		}
	}
}

.targetTabArea {
	width: 100%;
}

.loaderText {
	font-size: 13px;
	color: rgba(176, 181, 180, 1);
}

.hide {
	opacity: 0;
}

.updateInputContainer {
	width: 16%;
	margin-top: 14px;
	margin-bottom: 5px;

	.margin {
		margin-top: 3px;
	}

	label {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: $lightGray;
	}
}

.inputContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;

	label {
		color: var(--Brand-Neutral-Grey-400, #868d8d);
		font-size: 14px;
		font-weight: 600;
		line-height: 20px; /* 142.857% */
	}
}

.dropdownItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	img {
		height: 24px;
		border-radius: 2px;
		border: 1px solid var(--System-Silver-Chalice-50, #f8f7f7);
		background: transparent lightgray 50% / cover no-repeat;
	}

	span {
		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 14px;
		font-weight: 400;
		line-height: 20px; /* 142.857% */
	}
}

.criteriaList {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;

	.criteriaListItem {
		display: flex;
		flex: 0 0 100%;
		position: relative;
		flex-direction: column;
		width: 100%;
	}

	.info {
		color: var(--Brand-Neutral-Grey-600-B, #4e5555);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 142.857% */
	}

	.accordion {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 16px;
		gap: 16px;
		align-self: stretch;
		border-radius: 4px;
		border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
		position: relative;

		&[aria-expanded="true"] {
			background: #f8f7f7;
		}
	}
	.warning {
		border: 1px solid red !important;
	}

	.accordionTitle {
		display: flex;
		//height: 20px;
		min-width: 20px;
		padding: 5px 8px;
		justify-content: flex-start;
		align-items: center;
		gap: 8px;
		border-radius: 4px;
		//border: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
		width: max-content;
		position: relative;

		color: var(--Brand-Neutral-Grey-800, #2c3030);
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px; /* 166.667% */
	}

	.accordionContent {
		margin: -16px;
		margin-top: 0px;
		padding: 16px;
		border-top: 1px solid var(--System-Silver-Chalice-200, #dad8d7);
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.accordionSubtitle {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 6px;
		background: transparent;
	}
}

.bitToBriefInput input {
	background: #ffffff;
}

.addCriteria {
	display: flex;
	padding: 6px 16px 6px 8px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	border-radius: 100px;
	border: 1px solid var(--Brand-Secondary-600-B, #493c70);
	color: var(--Brand-Secondary-600-B, #493c70);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;

	&.disabled {
		background: #dad8d7;
		border: 1px solid var(--Brand-Primary-600-B, #dad8d7);
		color: var(--Brand-Neutral-Grey-300, #b0b5b4);
	}
}

.criteriaName {
	position: absolute;
	top: 17px;
	left: 110px;
	color: var(--Brand-Neutral-Grey-800, #2c3030);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
}

.companyLogo {
	margin-right: 5px;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	object-fit: cover;
}

.dotSpacing {
	color: rgba(134, 141, 141, 1);
	margin: 0 5px;
	font-size: 30px;
	line-height: 0.3;
}

.boldText {
	color: rgba(44, 48, 48, 1);
	font-weight: 600;
}

.logo {
	position: relative;
	width: 60px;
	height: 60px;
	border-radius: 100px;
	object-fit: cover;
}

.disabled {
	color: var(--Brand-Neutral-Grey-300, #b0b5b4);
	cursor: not-allowed;
}
