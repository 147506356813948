.wrapper {
	background-color: white;
	border-radius: 4px;
	z-index: 101;
	padding: 15px 20px;
	margin-left: 110px;
	width: fit-content;
}

.eventSection {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.eventHeader {
	display: flex;
	align-items: center;
	gap: 8px;
}

.eventDetails {
	display: flex;
	align-items: center;
}

.eventInfo {
	font-size: 13px;
	font-weight: 500;
	color: #2c3030;
}

.eventDescription {
	font-size: 13px;
	color: #4e5555;
	margin-top: 4px;
	margin-left: 28px;

	@media (max-width: 767px) {
		width: 284px;
	}
}
