.list {
	margin-bottom: 32px;
}

.map {
	background: #ffffff;
	box-shadow: 0px 9px 31px rgba(0, 0, 0, 0.04);
	border-radius: 4px;
	padding: 24px;
	box-sizing: border-box;

	&:not(:last-child) {
		margin-bottom: 16px;
	}
}

.name {
	color: #383838;
	font-weight: 600;
	font-size: 22px;
	line-height: 30px;
	cursor: pointer;
	display: inline-block;
	user-select: none;
}

.description {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	margin: 4px 16px 16px 0;
}

.companies {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.companyImageWrapper {
	border: 3px solid #fff;
	border-radius: 50%;
	background: #fff;

	&:not(:first-child) {
		margin-left: -8px;
	}
}

.companyImage {
	border-radius: 50%;
	width: 32px;
	height: 32px;
	border: 2px solid #e0e0e0;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	color: #404040;
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
}

.interaction {
	display: flex;
	align-items: center;
}

.createdBy {
	margin-left: auto;
	font-size: 14px;
	line-height: 22px;
	color: #5a5a5a;
	user-select: none;
}

.empty {
	color: #404040;
	font-size: 16px;
	line-height: 24px;
	user-select: none;

	strong {
		font-weight: 500;
	}
}

.paginationWrapper {
	display: flex;
	justify-content: flex-end;
	margin-top: 5px;
}
