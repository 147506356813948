.company_block {
	border-radius: 5px;
	margin-bottom: 24px;
	padding-bottom: 24px;
	background-color: #fff;

	&__head {
		display: flex;
		padding: 16px 24px;
		align-items: center;
		justify-content: space-between;

		p {
			margin: 0;
		}

		&_title {
			font-family: "Inter";
			font-weight: 500;
			font-size: 18px;
			line-height: 22px;
		}
	}

	&__line {
		border: 0.5px solid #e1e1e1;
	}

	&__card {
		gap: 10px;
		width: 100%;
		padding: 24px;
		display: flex;
		max-width: 100%;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__show_more {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
