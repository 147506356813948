.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-height: 620px;
	box-sizing: content-box;
	overflow: auto;
}

.cards {
	flex-grow: 1;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 16px;
	margin-top: 32px;

	@media (max-width: 900px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 690px) {
		grid-template-columns: repeat(1, 1fr);
	}
}
