.wrapper {
	width: 100%;
	margin: 0 auto;
	background: #e3e3e3;
	padding: 8px 80px 8px 41px;
	border-radius: 4px 4px 0px 0px;

	&__container {
		width: 100%;
		display: flex;
		max-width: 1032px;
	}

	&__btn {
		position: relative;

		button {
			cursor: default;
			min-width: max-content;
		}

		&_pointer {
			cursor: pointer !important;
		}

		&_left {
			text-align: left;
		}

		&_right {
			text-align: right;
		}

		&__icon {
			cursor: pointer;
		}

		&__select {
			right: 20px;
		}

		&:nth-child(1) {
			width: 100%;
			max-width: 34.8%;
			padding-left: 16px;
		}

		&:nth-child(2) {
			width: 100%;
			max-width: 13.95%;
		}

		&:nth-child(3) {
			width: 100%;
			max-width: 13.17%;
		}

		&:nth-child(4) {
			width: 100%;
			max-width: 21.7%;
		}

		&:nth-child(5) {
			width: 100%;
			max-width: 16.2%;
			padding-right: 15px;
		}
	}
}
