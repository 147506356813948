.pagination {
	.paginationControls {
		//display: flex;
		//justify-content: flex-start;
		//gap: 5px;
	}
}

.dots {
	color: rgba(134, 141, 141, 1);
	margin: 0 5px;
}

.button {
	padding: 5px;
	margin: 5px;
	width: 32px;
	height: 32px;
	border: 1px solid rgba(218, 216, 215, 1);
	border-radius: 4px;
	background-color: transparent;
	color: rgba(78, 85, 85, 1);
}

.active {
	border: 1px solid rgba(12, 88, 80, 1);
	color: rgba(12, 88, 80, 1);
}

.mediumWidth {
	width: 50px !important;
}

.largeWidth {
	width: 70px !important;
}

.perPageWrapper,
select {
	padding: 5px;
	margin: 5px;
	width: 32px;
	height: 32px;
	background-color: transparent;
	border-radius: 4px;
}

select {
	border: 1px solid rgba(218, 216, 215, 1);
	border-radius: 4px;
	width: 100px;
}

.inputWrapper {
	margin-left: 80px;
	padding: 5px;
	width: 32px;
	height: 32px;
	background-color: transparent;
	border-radius: 4px;
}

.lessMargin {
	margin-left: 5px;
}

input {
	border: 1px solid rgba(218, 216, 215, 1);
	padding: 5px;
	margin: 5px;
	width: 50px;
	height: 32px;
	background-color: transparent;
	border-radius: 4px;
	outline: none;
}
