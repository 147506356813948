.resultCount {
	display: flex;
	margin-bottom: 10px;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
}

.backToSearchLabel {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #0c5850;
	margin-left: 30px;
	cursor: pointer;
}

.actionBlock {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	gap: 16px;
	padding: 15px;
	background: #fff;
	//border-radius: 4px;
	border-bottom: 1px solid #eeedec;
}

.inputProfilesBlock {
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: #000000;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	border-bottom: 1px solid #eeedec;
	width: 100%;
	padding-bottom: 16px;

	span.label {
		min-width: 75px;
		padding-top: 5px;
	}

	.buttonsRow {
		display: flex;
		gap: 7px;
		flex-flow: wrap;
	}
}

.actionButtons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
	width: 100%;

	@media screen and (max-width: 767px) {
		gap: 10px;
		flex-direction: column;
		align-items: flex-start;
	}

	.saveButton {
		margin-right: 8px;
	}

	&__container {
		gap: 8px;
		display: flex;
		position: relative;
		align-items: center;

		&__get_more {
			position: relative;

			&_button {
				border-radius: 4px;
				background: #0c5850;
				display: flex;
				align-items: center;
				gap: 6px;
				padding: 6px 12px;
				color: #edf2f2;
				font-family: "Inter", sans-serif;
				font-size: 14px;
				font-weight: 500;
				line-height: 20px;
				position: relative;

				&__z_index {
					position: relative;
					z-index: 51;
				}
			}

			&_boarding_two {
				top: -103px;
				left: -30px;
				width: 400px;
			}
		}
	}
}

.toggleExpandButton,
.saveButton,
.rejectButton {
	display: flex;
	align-items: center;
	gap: 5px;
	border-radius: 4px;
	padding: 6px 8px;
	background: transparent;
	color: var(--brand-neutral-grey-600-b, #4e5555);
	height: 36px;
}

.saveButton {
	border: 1px solid rgba(12, 88, 80, 1);
}

.rejectButton {
	border: 1px solid rgba(201, 21, 21, 1);
}

.locked {
	color: #dad8d7 !important;
	border: 1px solid #dad8d7 !important;

	&:hover {
		opacity: 1;
	}
}

.toggleExpandButton {
	border-radius: 4px;
	border: 1px solid var(--system-silver-chalice-200, #dad8d7);

	&.reverse {
		background: var(--brand-neutral-grey-50, #f7f8f8);

		svg {
			transform: rotate(180deg);
		}
	}
}

.refreshBlock {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	gap: 5px;
	cursor: pointer;
	min-width: max-content;

	border-radius: 4px;
	padding: 6px 8px;
	background: transparent;
	color: var(--brand-neutral-grey-600-b, #4e5555);
	border: 1px solid var(--system-silver-chalice-200, #dad8d7);
}

.loading {
	-webkit-animation: spin 2s linear infinite;
	-moz-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

/* rotate 360 key for refresh btn */
@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.resetFilterButton {
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 12px;
	font-weight: 500;
	gap: 5px;
	border-radius: 4px;
	padding: 6px 8px;
	background: transparent;
	color: var(--brand-neutral-grey-600-b, #4e5555);
	border: 1px solid var(--system-silver-chalice-200, #dad8d7);
}

.generateMoreResults {
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	gap: 5px;
	border-radius: 4px;
	padding: 6px 8px;
	color: var(--brand-neutral-grey-600-b, #edf2f2);
	border: 1px solid var(--system-silver-chalice-200, #0c5850);
	background: var(--brand-primary-600-b, #0c5850);

	&.generating {
		opacity: 0.6;
		pointer-events: none;
	}
}
