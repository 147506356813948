@import "./sanitize.module.scss";
@import "./font.scss";

:root {
	--toastify-toast-width: 340px;
	--rt-opacity: 1;
}

body,
html,
#root {
	margin: 0;
	height: 100%;
}

body {
	background: #f8f7f7;
	font-family: "Inter", sans-serif;
	font-size: 16px;
	font-weight: 400;
}

*::-webkit-scrollbar {
	width: 9px;
}

*::-webkit-scrollbar-track {
	background: #e1e1e1;
}

*::-webkit-scrollbar-thumb {
	background-color: #3c6662;
	border-radius: 20px;
	border: 2px solid #3c6662;
}

*::-webkit-scrollbar-thumb:hover {
	background: #0c5850;
}

textarea,
input,
button,
select {
	font-family: inherit;
	font-size: inherit;
}

main {
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
}

.Toastify__toast-body {
	padding: 0;
	margin: -8px;
}

.Toastify__toast-body > div {
	height: 100%;
}

.Toastify__toast-container--top-right {
	margin-top: 60px;

	@media (max-width: 870px) {
		margin-top: 110px;
	}
	@media (max-width: 480px) {
		margin-top: 0;
	}
}

.kZdgcM > span {
	opacity: 1 !important;
	color: #d94222 !important;
}

.ReactModal__Overlay {
	opacity: 0;
	transform: scale(0.5) translate(-10px, -10px);
	transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
	transform: scale(1) translate(0px, 0px);
}

.ReactModal__Overlay--before-close {
	opacity: 0;
	transform: scale(0.5) translate(-10px, -10px);
}

@media print {
	body {
		background: none;
		-ms-zoom: 1.665;
	}

	@page {
		size: landscape;
	}
}
