@import "styles/common.module.scss";

.card {
	display: flex;
	flex-direction: column;
	-moz-transition: height 0.5s;
	-ms-transition: height 0.5s;
	-o-transition: height 0.5s;
	-webkit-transition: height 0.5s;
	transition: height 0.5s;

	margin-bottom: 20px;
}

.top {
	display: flex;
	align-items: center;
	justify-content: space-between;

	> nav {
		display: flex;
		align-items: center;
	}

	.tab {
		user-select: none;
	}

	.tab:first-child {
		margin-right: 30px;
	}

	.tab > a {
		display: block;
	}

	.tab .line {
		visibility: hidden;
		width: 100%;
		background-color: #0c5850;
		height: 3px;
		border-radius: 4px;
		margin-top: 11px;
	}

	.tab.active .line {
		visibility: visible;
	}

	.tab .title {
		font-weight: 600;
		font-size: 18px;
		color: #808080;
	}

	.tab.active .title {
		color: #312200;
	}

	.tab .count {
		background: #f1f1f1;
		border-radius: 3px;
		padding: 5px 10px;
		display: inline-block;
		color: #404040;
		font-weight: 500;
		font-size: 14px;
		margin-left: 10px;
	}

	.tab.active .count {
		color: #312200;
	}
}

.bottom {
	//display: flex;
	//align-items: center;
	//justify-content: space-between;
	margin: 10px 0 13px 0;

	position: absolute;
	top: 25px;
	right: 0;
	display: inline-block;

	@media screen and (max-width: 1024px) {
		position: relative;
		top: 5px;
		right: 0;
	}

	> span {
		margin-left: auto;
		display: flex;
		flex-direction: column;
	}

	.result {
		font-weight: 500;
		font-size: 14px;
		color: #404040;
	}

	.reset {
		visibility: hidden;
		font-weight: 500;
		font-size: 12px;
		color: #0c5850;
		cursor: pointer;
		margin-left: auto;
	}

	.visible {
		visibility: visible;
	}

	.none {
		display: none;
	}

	.addAllButton {
		display: flex;
		align-items: center;
		cursor: pointer;
		font-weight: 600;
		font-size: 13px;
		margin: 10px 0;
		color: $generalButtonPassive;
		justify-content: flex-end;
	}
}

.hiddenCard {
	height: 0;
}
