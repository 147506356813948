.container {
	height: 100%;
	padding: 24px;
	min-height: 294px;
	border-radius: 4px;
	background-color: #fff;
	border: 1px solid #e1e1e1;

	&__title {
		margin-top: 0;
		font-size: 18px;
		font-weight: 500;
		color: #271b00;
		line-height: 22px;
		margin-bottom: 24px;
		font-family: "Inter", sans-serif;
	}

	&__wrapper {
		gap: 8px;
		height: 200px;
		display: flex;
		flex-wrap: wrap;
		padding-left: 12px;
		justify-content: space-between;

		@media screen and (max-width: 767px) {
			height: fit-content;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 20px;
		}

		&__chart {
			width: 160px;
			min-height: 150px;

			@media (min-width: 1440px) {
				width: 200px;
				max-height: 200px;
			}

			@media screen and (max-width: 767px) {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			&__cont,
			path,
			g {
				outline: none !important;
			}
		}

		&__list {
			gap: 8px;
			width: 100%;
			display: flex;
			max-width: 268px;
			overflow-y: auto;
			overflow-x: hidden;
			max-height: 185px;
			padding-right: 12px;
			flex-direction: column;

			&_specialisms {
				display: flex;
				flex-direction: column;

				&_item {
					border-bottom: 1px solid #eeedec;
					display: flex;
					justify-content: space-between;
					padding-bottom: 8px;

					&_content {
						display: flex;
						gap: 8px;
						align-items: center;
						cursor: pointer;
					}

					&_arrow {
						margin-right: 7px;
					}

					&_name {
						margin: 0;
						font-weight: 600;
						font-size: 14px;
						line-height: 20px;
						color: #4e5555;
					}

					&_percent {
						margin: 0;
						font-weight: 600;
						font-size: 14px;
						line-height: 20px;
						color: #4e5555;
					}
				}

				&_child {
					margin: 8px 0 0 42px;
					display: flex;
					flex-direction: column;
					gap: 8px;

					&Other {
						margin-left: 0;
					}

					&_item {
						display: flex;
						align-items: center;
						justify-content: space-between;

						&_name {
							margin: 0;
							font-size: 14px;
							color: #4e5555;
						}

						&_percent {
							margin: 0;
							font-weight: 600;
							font-size: 14px;
							line-height: 20px;
							color: #2c3030;
							min-width: 35px;
							display: flex;
							justify-content: flex-end;
						}
					}
				}
			}

			&_item {
				width: 100%;
				gap: 8px;
				display: flex;
				justify-content: space-between;

				&_scroll {
					margin-right: 10px;
				}

				&_left {
					width: 100%;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;

					&_content {
						gap: 8px;
						display: flex;
						cursor: pointer;
						align-items: center;

						&_default {
							cursor: default;
						}
					}

					&_dot {
						height: 12px;
						max-width: 12px;
						min-width: 12px;
						border-radius: 50%;
					}

					&_label {
						font-size: 14px;
						color: #404040;
						font-weight: 400;
						line-height: 20px;
						font-family: "Inter";
					}
				}

				&_right {
					font-size: 14px;
					font-weight: 600;
					color: #312200;
					line-height: 20px;
					white-space: nowrap;
					font-family: "Inter";
				}
			}
		}

		&__is_empty {
			text-align: center;
		}
	}
}

.percent {
	font-size: 10px;
	font-weight: 600;
	color: #ffffff;
	user-select: none;
	line-height: 18px;
	font-family: "Inter";
}
