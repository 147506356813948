.sectionWrapper {
	grid-column: 1 / 3;
	max-height: 800px;
	position: relative;
	width: 100%;
	//padding-bottom: 65%;
	overflow-y: hidden;
	overflow-x: auto;

	.childrenWrapper {
		padding: 33px 0 0 0;
		//transform: translate(50px, 50px);
		position: relative;
		top: 0;

		svg {
			outline: none;
		}
	}
}

.zoom {
	display: flex;
	align-items: center;
	color: #0c5850;
	position: absolute;
	left: 32px;
	cursor: pointer;

	> div {
		margin-right: 6px;
	}

	span {
		margin-right: 8px;
	}
}
