.container {
	display: flex;
	padding: 16px;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 24px;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0px 17px 33px -2px rgba(79, 75, 74, 0.05);
	user-select: none;
	margin-top: 32px;

	@media (max-width: 870px) {
		margin-top: 64px;
	}
}

.upgradeButton {
	height: 48px;
	box-shadow: 0px 8px 8px 0px #e5f3f1;
}

.plus {
	background-color: #edf2f2;
	padding: 0 2px;
	border-radius: 2px;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	color: #0c5850;
	height: 20px;
	margin-left: 6px;
}

.right {
	display: flex;
	align-items: center;
	white-space: nowrap;

	.plans {
		.title {
			color: #4e5555;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
		}

		.licenses {
			display: flex;
			align-items: center;
		}

		.license {
			display: flex;
			align-items: center;

			&:not(:last-child) {
				margin-right: 16px;
			}

			.licenseType {
				color: #2c3030;
				font-family: Inter;
				font-size: 20px;
				font-weight: 600;
				line-height: 28px;
			}

			.distribution {
				margin-left: 4px;
				border-radius: 4px;
				background: #e5e6e6;
				padding: 4px;
				color: #2c3030;
				text-align: center;
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
			}
		}
	}

	.verticalLine {
		height: 100%;
		min-height: 54px;
		width: 1px;
		border-right: 1px solid #eeedec;
		margin: 0 16px;
	}

	.payment {
		.title {
			color: #4e5555;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
		}

		.costs {
			display: flex;
			align-items: center;
		}

		.cost {
			display: flex;
			align-items: center;

			&:not(:last-child) {
				margin-right: 16px;
			}

			.amount {
				color: #2c3030;
				font-family: Inter;
				font-size: 20px;
				font-weight: 600;
				line-height: 28px;
			}

			.period {
				color: #868d8d;
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
			}
		}
	}

	.button {
		display: flex;
		align-items: center;
		padding: unset;
		padding: 0 12px;
		margin-left: 32px;

		.buttonIcon {
			display: flex;
			align-items: center;
			margin-right: 6px;
		}

		svg {
			flex-shrink: 0;

			path {
				fill: #fff;
			}
		}
	}
}

.left {
	.title {
		color: #2c3030;
		font-size: 24px;
		font-style: normal;
		font-style: normal;
		font-weight: 500;
		font-weight: 600;
		line-height: 32px;
		margin: 0 0 2px 0;
	}

	.subtitle {
		color: #4e5555;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
	}
}

.buttons {
	margin-left: 32px;
}
